export type HouseSpecialElemType = {
  id: string
  view: boolean
  justFunObject: boolean
}

export const HouseSpecialElems: HouseSpecialElemType[] = [
  { id: 'Bad', view: false, justFunObject: false },
  { id: 'Wohnzimmer', view: false, justFunObject: false },
  { id: 'Schlafzimmer', view: false, justFunObject: false },
  { id: 'Dachs', view: false, justFunObject: true },
  { id: 'Dino', view: false, justFunObject: true }
]
