import * as React from 'react'
import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { AppTheme as theme } from '../../../theme'

interface RadioButtonProps {
  selected?: boolean
  onClick?(): void
}

export const RadioButton: React.FunctionComponent<RadioButtonProps> = ({ selected, onClick }) => {
  const [checked, setChecked] = React.useState(false)

  const handleChangeState = () => {
    setChecked(!checked)
    if (onClick) {
      onClick()
    }
  }

  React.useEffect(() => {
    if (selected && selected === true) {
      setChecked(true)
    }
  }, [selected])

  return (
    <RadioContainer
      marginLeft={`-${theme.space.XS}px`}
      marginRight={theme.space.S}
      onClick={handleChangeState}>
      <StyledRadioCircle>
        <StyledRadio selected={checked} />
      </StyledRadioCircle>
    </RadioContainer>
  )
}

export default RadioButtonProps

const RadioContainer = styled.div<SpaceProps>`
  ${space}
  display: inline-block;
`

const StyledRadioCircle = styled.div`
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: relative;
  padding: 2px;
  cursor: pointer;
`
//theme.colors.primary
const StyledRadio = styled.div<RadioButtonProps>`
  width: 100%;
  height: 100%;
  background: ${(props) => (props.selected ? 'rgba(255, 224, 0, 1)' : 'rgba(255, 224, 0, 0.3)')};
  border-radius: 50%;
  transition: background 0.3s ease-out;
  ${(props) => props.selected === false && HoverEffect}
`

const HoverEffect = css`
  &:hover {
    background: rgba(255, 224, 0, 0.7);
  }
`
