/* eslint-disable react/display-name */
import * as React from 'react'
import ReactCardFlip from 'react-card-flip'
import styled from 'styled-components'
import { confirmAlert } from 'react-confirm-alert'
import { ProductCardContentMobile } from '../ProductCardContent_Mobile'
import { Alert, Card, MenuList, ProductCardHead } from '../../molecules'
import { IconButton, SVGIcon, IconType } from '../../atoms'
import { AppTheme, AppTheme as theme } from '../../../theme'
import { IEHContent, IProductForBtn } from '../../statics/interfaces'
import { useStore } from '../../../context'
import { ContactCard } from '..'
import Module from '../EchoContent/module'
import {
  FlexWrapperMobileHeadAndScroll,
  FlexScrollContainer,
  FlexScrollContainerBody,
  FlexScrollContainerBodyInner,
  MobileExpandBtnContainer
} from '../../../theme/globalStyledElements'
import { observer } from 'mobx-react-lite'

interface FlipCardProps {
  product?: IEHContent
}

export const FlipCard: React.FunctionComponent<FlipCardProps> = observer(
  ({ product }: FlipCardProps) => {
    const { uiStore, contentStore, appStore } = useStore()
    const [followingContent, setFollowingContent] = React.useState({} as any)

    const phone = appStore.CISettings?.settings_sw_text_sw_phone
    const email = appStore.CISettings?.settings_sw_text_sw_email

    const cardStyles = {
      front: { zIndex: uiStore.isFrontSide ? 2 : 0 },
      back: { zIndex: uiStore.isFrontSide ? 0 : 2 }
    }

    const handleSelectItemFromMenu = (element: IProductForBtn) => {
      console.log(element)
      contentStore.updateSelectedProductForBtn(element)
      const index = contentStore.Contents.findIndex((item) => item.id === element.id)
      console.log('_______________ :', index)
    }

    const handleCloseContactCard = () => {
      if (uiStore.contactCardTrigger === 'menu') {
        uiStore.handleFlipCard(false, 'menu')
      } else if (uiStore.contactCardTrigger === 'product') {
        uiStore.handleFlipCard(true)
      } else {
        null
      }
    }

    const handleOpenFollowingContentCard = () => {
      uiStore.handleFlipCard(false, 'followingContent')
      contentStore.loadFollowUpContent().then((data: any) => {
        console.log('____ following data: ', data)
        setFollowingContent(data)
      })
    }

    const renderFrontSide = () => {
      return (
        <ProductCardContentMobile
          onFlipToMenu={() => uiStore.handleFlipCard(false, 'menu')}
          onFlipToFollowingContent={handleOpenFollowingContentCard}
          isFlipped={false}
          product={product}
          showAll={uiStore.mobileContentExpanded}
        />
      )
    }

    const handleOpenEmailFeedback = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          const title =
            uiStore.emailState === ''
              ? 'Ihre Nachricht wird gesendet.'
              : uiStore.emailState === 'succeeded'
              ? 'Ihre Nachricht wurde erfolgreich versendet!'
              : `Oops, etwas ist schief gelaufen. \nBitte versuchen Sie es nochmal oder kontaktieren Sie uns unter ${phone} oder ${email}. \nVielen Dank!`

          return <Alert onClose={onClose} title={title} />
        }
      })
    }

    const renderBackSide = (contentType: 'menu' | 'contact' | 'followingContent') => {
      switch (contentType) {
        case 'menu':
          return (
            <>
              <div
                style={{
                  position: 'absolute',
                  left: `${AppTheme.space.S}px`,
                  top: `${AppTheme.space.S}px`
                }}>
                <IconButton
                  withBackground
                  bgSecondary
                  color='white'
                  onClick={() => uiStore.handleFlipCard(true)}>
                  <SVGIcon
                    iconName={IconType.IconClose}
                    viewBox='0 0 42 42'
                    color={theme.colors.white}
                    width='42px'
                    height='42px'
                    margin='10px 0px 0px 10px'
                  />
                </IconButton>
              </div>
              <FlexWrapperMobileHeadAndScroll
                showAll={uiStore.mobileContentExpanded}
                pr={AppTheme.space.XXL}
                pl={AppTheme.space.XXXL}>
                <FlexScrollContainer>
                  <div>
                    <ProductCardHead isMobile isMenu title='Übersicht' noToggle='y' />

                    <MobileExpandBtnContainer data-ref='MobileExpandBtnContainer'>
                      <IconButton
                        onClick={() =>
                          uiStore.updateMobileContentExpanded(!uiStore.mobileContentExpanded)
                        }
                        isClicked={uiStore.mobileContentExpanded}>
                        <SVGIcon
                          iconName={IconType.IconUp}
                          viewBox='0 0 28 28'
                          color={theme.colors.darkgray}
                          width='28px'
                          height='28px'
                          margin='0px 0px 0px 0px '
                        />
                      </IconButton>
                    </MobileExpandBtnContainer>
                  </div>

                  <FlexScrollContainerBody data-ref='FlexScrollContainerBody'>
                    <FlexScrollContainerBodyInner>
                      <MenuList
                        onSelectItem={handleSelectItemFromMenu}
                        onFlipCard={() => uiStore.handleFlipCard(true)}
                        items={contentStore.ProductsForBtnAndMenu?.sort(
                          (a: IProductForBtn, b: IProductForBtn) => a.title.localeCompare(b.title)
                        )}
                      />
                    </FlexScrollContainerBodyInner>
                  </FlexScrollContainerBody>
                </FlexScrollContainer>
              </FlexWrapperMobileHeadAndScroll>
            </>
          )
        case 'followingContent':
          return (
            <>
              <div
                style={{
                  position: 'absolute',
                  left: `${AppTheme.space.S}px`,
                  top: `${AppTheme.space.M}px`
                }}>
                <IconButton color='white' onClick={() => uiStore.handleFlipCard(true)}>
                  <SVGIcon
                    iconName={IconType.IconBack}
                    viewBox='0 0 42 42'
                    color={theme.colors.primary}
                    width='42px'
                    height='42px'
                    margin='10px 0px 0px 10px'
                  />
                </IconButton>
              </div>
              <FlexWrapperMobileHeadAndScroll
                showAll={uiStore.mobileContentExpanded}
                pr={AppTheme.space.XXL}
                pl={AppTheme.space.XXXL}>
                <FlexScrollContainer data-ref='FlexScrollContainer'>
                  <div>
                    <ProductCardHead
                      isMobile={false}
                      title={followingContent.meta.nameIntern}
                      noToggle='y'
                    />
                    <MobileExpandBtnContainer data-ref='MobileExpandBtnContainer'>
                      <IconButton
                        onClick={() =>
                          uiStore.updateMobileContentExpanded(!uiStore.mobileContentExpanded)
                        }
                        isClicked={uiStore.mobileContentExpanded}>
                        <SVGIcon
                          iconName={IconType.IconUp}
                          viewBox='0 0 28 28'
                          color={theme.colors.darkgray}
                          width='28px'
                          height='28px'
                          margin='0px 0px 0px 0px '
                        />
                      </IconButton>
                    </MobileExpandBtnContainer>
                  </div>
                  <FlexScrollContainerBody data-ref='FlexScrollContainerBody'>
                    <FlexScrollContainerBodyInner>
                      <Module kind='followUpContent' modules={followingContent.modules} />
                    </FlexScrollContainerBodyInner>
                  </FlexScrollContainerBody>
                </FlexScrollContainer>
              </FlexWrapperMobileHeadAndScroll>
            </>
          )
        case 'contact':
          return (
            <FlexWrapperMobileHeadAndScroll
              showAll={uiStore.mobileContentExpanded}
              pr={AppTheme.space.XXL}
              pl={AppTheme.space.XXXL}>
              <ContactCard
                onCloseContact={handleCloseContactCard}
                emailSent={handleOpenEmailFeedback}
              />
            </FlexWrapperMobileHeadAndScroll>
          )
        case 'followingContent':
        default:
          return <></>
      }
    }

    return (
      <StyledFlipCardParent className='flip-card' expanded={uiStore.mobileContentExpanded}>
        <StyledFlipCardInner>
          <ReactCardFlip
            isFlipped={!uiStore.isFrontSide}
            flipDirection='horizontal'
            cardStyles={cardStyles}>
            <StyledFlipSide isFrontSide={uiStore.isFrontSide}>
              <Card
                showContent
                isMoblie
                padding={`${theme.space.M}px 0px ${theme.space.M}px ${theme.space.S}px`}>
                <>{renderFrontSide()}</>
              </Card>
            </StyledFlipSide>
            <StyledFlipSide isFrontSide={!uiStore.isFrontSide}>
              <Card
                showContent
                isMoblie
                padding={`${theme.space.M}px 0px ${theme.space.M}px ${theme.space.S}px`}>
                <>{uiStore.contentTypeOnBackSide === 'menu' && renderBackSide('menu')}</>
                <>{uiStore.contentTypeOnBackSide === 'contact' && renderBackSide('contact')}</>
                <>
                  {uiStore.contentTypeOnBackSide === 'followingContent' &&
                    !!followingContent?.meta &&
                    renderBackSide('followingContent')}
                </>
              </Card>
            </StyledFlipSide>
          </ReactCardFlip>
        </StyledFlipCardInner>
      </StyledFlipCardParent>
    )
  }
)

export default FlipCard

const StyledFlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  /* transition: transform 0.8s;
  transform-style: preserve-3d; */
`

const StyledFlipCardParent = styled.div<{ expanded: boolean }>`
  background-color: transparent;
  width: 100%;
  height: ${(props) =>
    props.expanded
      ? `calc(${props.theme.WindowInnerHeight}px - 50px )`
      : `calc(${props.theme.WindowInnerHeight}px - 50px - 30vh)`};
  margin-top: ${(props) => (props.expanded ? '50px' : '50vh')};
  transition: all 0.6s ease;
  /* perspective: 1000px; */
  /* Remove this if you don't want the 3D effect */
`

const StyledFlipSide = styled.div<{ isFrontSide: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  text-align: left;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  z-index: ${(props) => (props.isFrontSide ? 100000 : -1)};

  /* https://stackoverflow.com/questions/7455502/webkit-backface-visibility-not-working */
  /* opacity: 0.99; */
  -webkit-transform: rotateY(
    0deg
  ); /* << this prvents head of cards to be visible after flip from other side  */
  /* transform-style: initial; */
`
