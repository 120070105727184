import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Typography, TypographyType } from '../../atoms'

interface WelcomeCardProps {
  title: string
  content: string
  showTitle?: boolean
  showContent?: boolean
  onClose(): void
}

export const WelcomeCard: React.VFC<WelcomeCardProps> = ({
  title,
  content,
  showContent,
  showTitle,
  onClose
}) => {
  const welcomeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside)

    return () => {
      document.removeEventListener('mousedown', clickOutside)
    }
  }, [welcomeRef])

  const clickOutside = (event: MouseEvent) => {
    if (welcomeRef.current && !welcomeRef.current.contains(event.target as Node)) {
      onClose()
    }
  }

  return (
    <WelcomeCardContainer className='welcome-card' ref={welcomeRef}>
      <div className='welcome-card__close' onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {showTitle && <Typography type={TypographyType.PRODUCT_HEAD}>{title}</Typography>}
      {showContent && <Typography type={TypographyType.TEXT}>{content}</Typography>}
    </WelcomeCardContainer>
  )
}

export default WelcomeCard

const WelcomeCardContainer = styled.div`
  position: absolute;
  left: 4rem;
  top: 2rem;
  width: 40rem;

  background: white;
  z-index: 100;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 25px;
  border: 2px solid ${(props) => props.theme.colors.gray};

  .welcome-card__close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    cursor: pointer;
    font-size: 2rem;
    color: ${(props) => props.theme.colors.primary};
  }
`
