import React from 'react'
import ReactDOM from 'react-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled, { ThemeProvider } from 'styled-components'
import { LoadingPage, ContentPageM, ContentPageTablet } from './components/pages'
import { AppTheme as theme, GlobalStyles } from './theme'
import { DeviceProvider, useDevice, StoreProvider, useStore } from './context'
import { observer } from 'mobx-react-lite'
import '../src/theme/App.css'

export const App: React.FC = observer(() => {
  const { isMobile } = useDevice()
  const { contentStore, appStore } = useStore()
  const [loading, setLoading] = React.useState(true)
  // const [isWindowLoaded, setIsWindowLoaded] = React.useState(false)

  React.useEffect(() => {
    contentStore.getAllContents().then(() => {
      appStore.getCiSettingsAndMapToTheme(theme).then(() => {
        setLoading(false)
      })
    })
  }, [])

  return (
    <div className='energyhouseApp'>
      <ThemeProvider theme={theme}>
        <>
          {!loading ? (
            <>
              <Box>{isMobile ? <ContentPageM /> : <ContentPageTablet />}</Box>
              <GlobalStyles />
            </>
          ) : (
            <LoadingPage />
          )}
          <GlobalStyles />
        </>
      </ThemeProvider>
    </div>
  )
})

const Box = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;

  .appIsInModal & {
    width: 100%;
    height: 100%;
    position: relative;
  }
`

function renderApp(rootEl: HTMLElement) {
  ReactDOM.render(
    <DeviceProvider>
      <StoreProvider>
        <App />
      </StoreProvider>
    </DeviceProvider>,
    rootEl
  )
}

function renderStandaloneApp(rootEl: HTMLElement) {
  ReactDOM.render(
    <DeviceProvider>
      <StoreProvider>
        <App />
      </StoreProvider>
    </DeviceProvider>,
    rootEl
  )
}

const methodName = `render_app_eh`
//this one we need for integrated app start
;(window as any)[methodName] = renderApp
//this one we need for standalone app start
;(window as any).renderEHApp = renderStandaloneApp
