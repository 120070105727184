import { DefaultTheme } from 'styled-components'

const spacingSize: DefaultTheme['space'] = {
  NONE: 0,
  XXS: 2,
  XS: 4,
  S: 8,
  M: 12,
  Lneg: -16,
  L: 16,
  LminusOne: 15,
  XL: 24,
  XLneg: -24,
  XXL: 32,
  XXLneg: -32,
  XXXL: 44,
  XXXXL: 52
}

export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m: string, r: string, g: string, b: string) => {
    return r + r + g + g + b + b
  })
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16)
    : '0,0,0'
}

const colors: DefaultTheme['colors'] = {
  primary: '#FFE000',
  secondary: '#A0C4C9',
  green: '#51C70F',
  white: '#FFF',
  black: '#000',
  lightgray: '#EEE',
  gray: '#B9B9B9',
  darkgray: '#656565'
}

const fontweight: DefaultTheme['fontweight'] = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
}

const AppCanvasSet: DefaultTheme['canvas'] = {
  left: '20px',
  right: '20px',
  top: '40px',
  bottom: '20px',
  horizontal: '40px',
  vertical: '60px',
  temp1: '20vh',
  temp2: '50vh'
}

const WindowInnerHeight: number = window.innerHeight
const WindowInnerWidth: number = window.innerWidth
const fontFamily: string = "'Roboto',sans-serif"
const customFont: string = fontFamily
const fontURL: string = ''

export const AppTheme: DefaultTheme = {
  colors,
  space: spacingSize,
  fontweight,
  canvas: AppCanvasSet,
  WindowInnerHeight,
  WindowInnerWidth,
  fontFamily,
  customFont,
  fontURL
}
