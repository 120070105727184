import React from 'react'
import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { observer } from 'mobx-react-lite'
import { AppTheme } from '../../../theme'
import { Card, HouseGraphicParentMobile, LinkFooter } from '../../molecules'
import { Divider, Space, Typography, TypographyType } from '../../atoms'
import { FlipCard } from '../../templates'
import { useStore } from '../../../context'
import { SVGPath } from '../../../utils'

export const ContentPageM: React.FC = observer(() => {
  const { contentStore, appStore } = useStore()

  return (
    <>
      <AppTitel>
        <Typography type={TypographyType.TEXT}>
          {appStore.CISettings.settings_sw_text_name}
        </Typography>
      </AppTitel>

      <StyledHouseContainer data-ref='StyledHouseContainer'>
        <HouseGraphicParentMobile
          svgPath={SVGPath}
          contentsData={contentStore.Contents}
          showInGraphic={contentStore.ContentMeta?.showInGraphic}
          selectedProduct={contentStore.actualProductInfo}
        />
      </StyledHouseContainer>
      <StyledCoverHouseBottom className='house_canvas_bottom_layer' />

      <>
        {!!contentStore.actualProductInfo ? (
          <FlipCard product={contentStore.actualProductInfo} />
        ) : (
          <LandingCardContainer className='landing-card-container'>
            <Card>
              <Space pt={AppTheme.space.M} px={AppTheme.space.XXL} pb={AppTheme.space.L}>
                <Typography type={TypographyType.PRODUCT_HEAD}>
                  {appStore.welcomeSettings?.settings_ehwelcome_text_title_text?.question}
                </Typography>
                <Divider color={AppTheme.colors.primary} />
                <Typography type={TypographyType.TEXT}>
                  {appStore.welcomeSettings?.settings_ehwelcome_text_content_text?.question}
                </Typography>
              </Space>
            </Card>
          </LandingCardContainer>
        )}
      </>

      <FooterContainer>
        <LinkFooter />
      </FooterContainer>
    </>
  )
})

export default ContentPageM

const AppTitel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.darkgray};
  z-index: 1000;
`

const StyledHouseContainer = styled.div`
  width: 200vw;
  height: 45vh;
  position: absolute;
  margin-top: 0px;
  margin-left: -50vw;
  .appIsInModal &  {
    margin-left: calc(-50vw + 20px);
    width: calc(200vw - 40px);
  }
`
const StyledCoverHouseBottom = styled.div`
  width: 100vw;
  height: 100vh;

  .appIsInModal &  {
    width: calc(100vw - 40px);
    height: calc(100vh - 60px);
  }

  position: absolute;
  margin-top: 0px;
  margin-left: 0px;
  z-index: -1;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,a29a8d+39&0+33,1+39,1+39,1+45 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(137, 130, 119, 0) 33%,
    rgba(162, 154, 141, 1) 39%,
    rgba(162, 154, 141, 1) 45%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(137, 130, 119, 0) 33%,
    rgba(162, 154, 141, 1) 39%,
    rgba(162, 154, 141, 1) 45%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(137, 130, 119, 0) 33%,
    rgba(162, 154, 141, 1) 39%,
    rgba(162, 154, 141, 1) 45%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a29a8d',GradientType=0 ); /* IE6-9 */
`

const LandingCardContainer = styled.div<SpaceProps & ColorProps>`
  position: relative;
  width: 100%;
  height: ${(props) =>
    props.theme.WindowInnerHeight && `calc(${props.theme.WindowInnerHeight}px - 42px)`};
  z-index: 2;
  ${space}
  ${color}
  transition: margin-top 0.6s ease;
  margin-top: 50vh;
`

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  z-index: 100000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 16px;
  border-top: 1px dashed #e1e1e1;
  background-color: white;
`
