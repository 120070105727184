import React from 'react'
import styled from 'styled-components'

interface ImageProps extends ImageContainerProps, StyledImageProps {
  url: string
  onLoad?(): void
}

interface StyledImageProps {
  maxHeight?: string
  width?: string
}

export const Image: React.FC<ImageProps> = ({
  url,
  margin,
  marginBottom,
  maxHeight,
  width,
  onLoad
}) => {
  return (
    <ImageContainer marginBottom={marginBottom} margin={margin}>
      <StyledImage src={url} maxHeight={maxHeight} width={width} onLoad={onLoad} />
    </ImageContainer>
  )
}

interface ImageContainerProps {
  margin?: string
  marginBottom?: string
}

interface StyledImageProps {
  maxHeight?: string
  width?: string
}

const ImageContainer = styled.div<ImageContainerProps>`
  display: block;
  margin-bottom: ${(props) => props.marginBottom};
  margin: ${(props) => props.margin};
`

const StyledImage = styled.img<StyledImageProps>`
  width: ${(props) => (!!props.maxHeight ? 'auto' : '100%')};
  max-height: ${(props) => props.maxHeight};
  max-width: 100%;
`

export default Image
