import React, { createContext, ReactElement, ReactNode, useState } from 'react'
import { useContext } from 'react'

export enum DeviceType {
  MOBILE = 'MOBILE',
  LAPTOP = 'LAPTOP'
}

export type deviceTypes = DeviceType.MOBILE | DeviceType.LAPTOP

interface IDeviceProviderProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

type DeviceContextType = {
  device: deviceTypes
}

const defaultValue: DeviceContextType = {
  device: window.innerWidth < 968 ? DeviceType.MOBILE : DeviceType.LAPTOP
}

export const DeviceContext = createContext<DeviceContextType>(defaultValue)

export const DeviceProvider: React.FC<IDeviceProviderProps> = ({ children }) => {
  const checkSize = () => {
    if (window.innerWidth < 968) {
      return DeviceType.MOBILE
    } else {
      return DeviceType.LAPTOP
    }
  }

  const handleResize = () => {
    const dev = checkSize()
    setDevice(dev)
    console.log('device: ', dev, device)
  }

  const [device, setDevice] = useState(checkSize())

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  return <DeviceContext.Provider value={{ device }}>{children}</DeviceContext.Provider>
}

export const useDevice = () => {
  const { device } = useContext(DeviceContext)

  if (!device) {
    throw new Error('useDevice must be used within a DeviceProvider.')
  }

  const isMobile = device === DeviceType.MOBILE

  return { isMobile }
}
