import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fontFamily};
  }

  @font-face {
    font-family: ${(props) => props.theme.customFont};
    src: url("${(props) => props.theme.fontURL}");
  }

  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.7)!important;
    z-index:10000!important;
  }
  .react-confirm-alert {
    width:100%;
    height:100%;
  }
  #housegraphicCanvasOuterDiv {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  input[type=text], textarea {
    /* needed for safari: */
    -webkit-user-select: text!important;
    -khtml-user-select: text!important;
    -moz-user-select: text!important;
    -ms-user-select: text!important;
    user-select: text!important;
    /* more styling ... */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-radius: 0px;
}
  .contentSizeContainer {
    width: calc(100% - 30px);
    box-sizing: border-box;
    padding-right: 30px;
    padding-left: 60px;
    display: block;
  }
  .contentSizeContainerSmallRightBorder {
    width: 100%;
    box-sizing: border-box;
    padding-right: 30px;
    padding-left: 60px;
    display: block;
  }
  /*scrollbar in general */
  .energyhouseApp ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }
  .energyhouseApp ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
  }
  .energyhouseApp ::-webkit-scrollbar-thumb {
      background: #ccc;
      border: 0px none #ffffff;
      border-radius: 55px;
  }
  .energyhouseApp ::-webkit-scrollbar-thumb:hover {
      background: #b9b9b9;
  }
  .energyhouseApp ::-webkit-scrollbar-thumb:active {
      background: #b9b9b9;
  }
  .energyhouseApp ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .energyhouseApp ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  .energyhouseApp ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  .energyhouseApp ::-webkit-scrollbar-corner {
      background: transparent;
  }
  video {
    outline :none;
  }
  video:focus {
    outline :none;
  }
  div.react-card-flip {
    height: 100%;
  }
`
