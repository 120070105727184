import React from 'react'
import styled from 'styled-components'
import {
  AppTheme as theme,
  FlexScrollContainer,
  FlexScrollContainerBody,
  FlexScrollContainerBodyInner,
  FlexWrapperMobileHeadAndScroll,
  MobileExpandBtnContainer
} from '../../../theme'
import { IconButton, IconType, Snackbar, SVGIcon } from '../../atoms'
import { IEHContent } from '../../statics/interfaces'
import { observer } from 'mobx-react-lite'
import { ProductCardHead } from '../../molecules'
import Module from '../EchoContent/module'
import { useStore } from '../../../context'

interface ProductCardMProps {
  product?: IEHContent
  onFlipToMenu?(): void
  onFlipToFollowingContent?(): void
  showAll?: boolean
  isFlipped: boolean
}

export const ProductCardContentMobile: React.FunctionComponent<ProductCardMProps> = observer(
  ({ onFlipToMenu, showAll, product, onFlipToFollowingContent, isFlipped }: ProductCardMProps) => {
    const { contentStore, uiStore } = useStore()
    const [isSnackbarVisible, setIsSnackbarVisible] = React.useState(false)

    const flextRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
      setIsSnackbarVisible(false)

      if (!!flextRef.current) {
        // flextRef.current.scrollTo(0, 0)alc
        // console.log('scrollTop: ', flextRef.current.scrollTop)
        // flextRef.current.addEventListener('scroll', () => {
        //   console.log(flextRef.current.scrollTop)
        // })
      }
    }, [isFlipped, product, flextRef]) //idFlipped watch > if lfip, do not show snackbar anymore

    const handleShowFollowUpContent = () => {
      if (onFlipToFollowingContent) {
        onFlipToFollowingContent()
      }
    }

    if (!!flextRef.current) {
      flextRef.current.scrollTo(0, 0)
    }

    return (
      <Container data-ref='ProductCardContent_Mobile'>
        <div
          style={{ position: 'absolute', left: `${theme.space.S}px`, top: `${theme.space.M}px` }}>
          <IconButton
            withBackground={false}
            color='white'
            onClick={onFlipToMenu}
            inline
            margin={`0px 0px 0px 0px`}
            zIndex={10}>
            <SVGIcon
              iconName={IconType.IconBurger}
              viewBox='0 0 45 45'
              color={theme.colors.secondary}
              width='42px'
              height='42px'
              margin='0px'
            />
          </IconButton>
        </div>
        <>
          <MobileExpandBtnContainer data-ref='MobileExpandBtnContainer'>
            <IconButton
              onClick={() => uiStore.updateMobileContentExpanded(!uiStore.mobileContentExpanded)}
              isClicked={showAll}>
              <SVGIcon
                iconName={IconType.IconUp}
                viewBox='0 0 28 28'
                color={theme.colors.darkgray}
                width='28px'
                height='28px'
                margin='0px 0px 0px 0px '
              />
            </IconButton>
          </MobileExpandBtnContainer>

          <Snackbar isMobile visible={isSnackbarVisible} bottom={showAll ? '45vh' : '45vh'}>
            {'Produkt wurde der Merkliste im Kontaktformular hinzugefügt'}
          </Snackbar>
        </>
        {/* we can change the padding bottom hier for ios */}

        <FlexWrapperMobileHeadAndScroll
          pr={theme.space.XXL}
          pl={theme.space.XXXL}
          showAll={showAll}>
          <FlexScrollContainer data-ref='FlexScrollContainerHead11111'>
            <ProductCardHead isMobile product={product} />

            <FlexScrollContainerBody data-ref='FlexScrollContainerBody' ref={flextRef}>
              <FlexScrollContainerBodyInner>
                <Module
                  modules={contentStore.actualProductModules}
                  handleShowContent={handleShowFollowUpContent}
                />
              </FlexScrollContainerBodyInner>
            </FlexScrollContainerBody>
          </FlexScrollContainer>
        </FlexWrapperMobileHeadAndScroll>
      </Container>
    )
  }
)

export default ProductCardMProps

const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
`
