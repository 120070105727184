import DOMPurify from 'dompurify'
import React from 'react'
import styled from 'styled-components'

export const SanitizedContent: React.VFC<{
  content: string
  className?: string
  options?: { [key: string]: string[] }
  hasSpace?: boolean
}> = ({ content, options, className, hasSpace = false }) => {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(content, options)
  })

  return (
    <SanitizedContentContainer
      dangerouslySetInnerHTML={sanitizedData()}
      className={className}
      hasSpace={hasSpace}
    />
  )
}

const SanitizedContentContainer = styled.div<{ hasSpace?: boolean }>`
  margin-top: ${(props) => (props.hasSpace ? '18px' : '0px')};

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0px 40px;
  }
`
