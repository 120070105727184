import * as React from 'react'
import { readableColor } from 'polished'
import { TooltipButton } from '../../molecules'
import { SVGIcon, DefaultIcon, IconType } from '../../atoms'
import { AppTheme as theme } from '../../../theme'
import { useDevice, useStore } from '../../../context'
import { observer } from 'mobx-react-lite'

interface HouseGraphicButtonProps {
  product: any
  isSelected?: boolean
  title?: string
  onClick?(): void
}

export const HouseGraphicButton: React.FunctionComponent<HouseGraphicButtonProps> = observer(
  ({ product, isSelected, title, onClick }: HouseGraphicButtonProps) => {
    const { isMobile } = useDevice()
    const { uiStore } = useStore()

    const iconColor =
      theme.colors.primary === '#008855'
        ? theme.colors.white
        : readableColor(theme.colors.primary, theme.colors.black, theme.colors.white)

    let isPromoted = false

    if (product.specialProp.iconOptic && product.specialProp.iconOptic === 'promotion') {
      isPromoted = true
    }

    const handleSelectProduct = (id: string) => {
      if (onClick) {
        onClick()
      }
      if (isMobile) {
        uiStore.handleFlipCard(true)
      }
      // callbackToHouseController.chooseProduct(id)
      console.log('TODO: ', id)
    }

    const shoudRenderDefaultIcon = (name: string | IconType) => {
      return (
        name === IconType.Default1 ||
        name === IconType.Default2 ||
        name === IconType.Default3 ||
        name === IconType.Default4 ||
        name === IconType.Default5 ||
        name === IconType.Default6
      )
    }

    return (
      <>
        {isMobile ? (
          <>
            <TooltipButton
              tooltip={product.title}
              expanded={isSelected}
              onClick={() => handleSelectProduct(product.id)}
              isPromoted={isPromoted}
              id={product.id}
              className='tooltip-btn'>
              {shoudRenderDefaultIcon(product.icon) ? (
                <DefaultIcon iconName={product.icon} width='30px' height='30px' color={iconColor} />
              ) : (
                <SVGIcon
                  iconName={product.icon}
                  viewBox='0 0 42 42'
                  color={iconColor}
                  width='42px'
                  height='42px'
                  margin='0px 0px 0px 0px '
                />
              )}
            </TooltipButton>
            {/* )} */}
          </>
        ) : (
          <TooltipButton
            tooltip={product.title}
            noneExpanded={!title}
            expanded={isSelected}
            onClick={() => handleSelectProduct(product.id)}
            isPromoted={isPromoted}
            id={product.id}
            className='tooltip-btn'>
            {shoudRenderDefaultIcon(product.icon) ? (
              <DefaultIcon iconName={product.icon} width='30px' height='30px' color={iconColor} />
            ) : (
              <SVGIcon
                iconName={product.icon}
                viewBox='0 0 42 42'
                color={iconColor}
                width='42px'
                height='42px'
                margin='0px 0px 0px 0px '
              />
            )}
          </TooltipButton>
        )}
      </>
    )
  }
)

export default HouseGraphicButton
