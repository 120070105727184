import * as React from 'react'
import styled from 'styled-components'
import { readableColor } from 'polished'
import { color, ColorProps } from 'styled-system'
import { Typography, TeaserButton } from '../../atoms'
import { AppTheme as theme } from '../../../theme'
import { SVGContainerProps, TeaserContainerProps } from './static'

interface TeaserMobileProps {
  productName: string
  title: string
  intro?: string
  primColor: string
  secColor: string
  onStart?(): void
  size: any
}

export const Teaser_Mobile: React.FunctionComponent<TeaserMobileProps> = ({
  productName,
  title,
  intro,
  primColor,
  secColor,
  size,
  onStart
}) => {
  return (
    <TeaserContainer bg={secColor} size={size} className='teaser_mobile'>
      <TeaserBackground svgPath={`${process.env.HOST_URL}/public/background.svg`}>
        {size === 'L' && (
          <>
            <TextContent>
              <Typography
                fontSize='16px'
                color={readableColor(secColor, '#000', '#FFF')}
                fontWeight={theme.fontweight.regular}>
                {productName}
              </Typography>

              <Typography
                color={readableColor(secColor, '#000', '#FFF')}
                fontSize='25px'
                fontWeight={theme.fontweight.medium}
                lineHeight='2rem'>
                {title}
              </Typography>
              <Typography
                color={readableColor(secColor, '#000', '#FFF')}
                fontSize='15px'
                fontWeight={theme.fontweight.semiBold}
                lineHeight='1.4rem'>
                {intro}
              </Typography>
            </TextContent>
            <SVGContainer
              className='svg_container'
              svgPath={`${process.env.HOST_URL}/public/teaser_haus.svg`}
            />
            <TeaserFooter className='teaser_footer' bg={primColor}>
              <TeaserButton bgColor={primColor} onClick={onStart}>
                <Typography
                  fontWeight={theme.fontweight.medium}
                  fontSize='15px'
                  color={readableColor(primColor, '#000', '#FFF')}>
                  STARTEN
                </Typography>
              </TeaserButton>
            </TeaserFooter>
          </>
        )}
        {size === 'M' && (
          <ContentContainer_M>
            <Typography
              fontSize='16px'
              color={readableColor(secColor, '#000', '#FFF')}
              fontWeight={theme.fontweight.regular}>
              {productName}
            </Typography>

            <Typography
              color={readableColor(secColor, '#000', '#FFF')}
              fontSize='25px'
              fontWeight={theme.fontweight.medium}
              lineHeight='2rem'>
              {title}
            </Typography>
            <ButtonContainer>
              <TeaserButton bgColor={primColor} onClick={onStart}>
                <Typography
                  fontWeight={theme.fontweight.medium}
                  fontSize='15px'
                  color={readableColor(primColor, '#000', '#FFF')}>
                  STARTEN
                </Typography>
              </TeaserButton>
            </ButtonContainer>
          </ContentContainer_M>
        )}
      </TeaserBackground>
    </TeaserContainer>
  )
}

export default Teaser_Mobile

const TeaserContainer = styled.div<ColorProps & TeaserContainerProps>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.size === 'L' ? '508px' : '350px')};
  ${color}/* background: linear-gradient(146deg, rgba(160, 196, 201, 1) 0%, rgba(124, 175, 183, 1) 100%); */
`
const TeaserBackground = styled.div<SVGContainerProps>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: cover;
`

const TextContent = styled.div`
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 45%;
  z-index: 10;
  user-select: none;
`

const TeaserFooter = styled.div<ColorProps>`
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 5;

  ${color}
`

const SVGContainer = styled.div<SVGContainerProps>`
  display: block;
  position: absolute;
  bottom: 113px;
  height: 100%;
  width: 90%;
  margin: auto 5%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: bottom;
`

const ContentContainer_M = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 15px;
  user-select: none;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
`
