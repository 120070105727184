import * as React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { ColorProps, color, layout, LayoutProps } from 'styled-system'
import { Card } from '../../molecules'
import { Modal } from '../../organisms'
import { HouseGraphicParent } from '../../molecules/housegraphic/HouseGraphicParent'
import { useStore } from '../../../context'
import { IProductForBtn } from '../../statics/interfaces'
import { SVGPath } from '../../../utils'
import WelcomeCard from './welcome-card'
import Menu from './menu'
import Footer from './mini-footer'

interface LandingCardProps extends StyledContainerProps {
  isExpanded: boolean
  title?: string
  isProductFromMenuClear?: boolean
}

export const LandingCard: React.FC<LandingCardProps> = observer(
  ({ isExpanded, isProductFromMenuClear, hidden }: LandingCardProps) => {
    React.useEffect(() => {
      if (isProductFromMenuClear) {
        setSelectedProductFromMenu('')
      }
    })

    const { contentStore, appStore } = useStore()

    const [isModalOn, setIsModalOn] = React.useState(false)
    const [selectedProductFromMenu, setSelectedProductFromMenu] = React.useState('')
    const [showWelcome, setShowWelcome] = React.useState(true)

    React.useEffect(() => {
      setShowWelcome(
        appStore.welcomeSettings?.settings_ehwelcome_text_title_display ||
          appStore.welcomeSettings?.settings_ehwelcome_text_content_display
      )
    }, [appStore.welcomeSettings])

    React.useEffect(() => {
      if (isModalOn) {
        setShowWelcome(false)
      }
    }, [isModalOn])

    const modelToggle = () => {
      setIsModalOn(!isModalOn)
    }

    const handleSelectItemFromMenu = (element: IProductForBtn) => {
      setIsModalOn(false)
      setSelectedProductFromMenu(element.id)
      contentStore.updateSelectedProductForBtn(element)
    }

    return (
      <StyledContainer
        bg='primary'
        className='landing-card'
        isExpanded={isExpanded}
        hidden={hidden}>
        <HausCardContainer data-ref='HausCardContainer'>
          <Card padding='0px' data-ref='Card'>
            <HouseGraphicParent
              modelToggle={modelToggle}
              svgPath={SVGPath}
              contentsData={contentStore.Contents}
              showInGraphic={contentStore.ContentMeta?.showInGraphic}
              selectedProductId={selectedProductFromMenu}
            />
          </Card>
          {showWelcome && (
            <WelcomeCard
              title={appStore.welcomeSettings?.settings_ehwelcome_text_title_text?.question}
              content={appStore.welcomeSettings?.settings_ehwelcome_text_content_text?.question}
              showTitle={appStore.welcomeSettings?.settings_ehwelcome_text_title_display}
              showContent={appStore.welcomeSettings?.settings_ehwelcome_text_content_display}
              onClose={() => setShowWelcome(false)}
            />
          )}

          <Modal isOn={isModalOn} onClose={() => setIsModalOn(!isModalOn)}>
            <Menu
              onToggleMenu={() => setIsModalOn(!isModalOn)}
              items={contentStore.ProductsForBtnAndMenu}
              onSelectItem={handleSelectItemFromMenu}
            />
          </Modal>
          <Footer
            imprintURL={appStore.EHSettings?.settings_sw_text_form_data_imprint_url?.question}
            dataProtectionURL={
              appStore.EHSettings?.settings_sw_text_form_data_protection_url?.question
            }
          />
        </HausCardContainer>
      </StyledContainer>
    )
  }
)

export default LandingCardProps

LandingCard.defaultProps = {
  isExpanded: true,
  hidden: false // slide to left, invisible
}

interface StyledContainerProps {
  hidden: boolean
  isExpanded: boolean
}

const StyledContainer = styled.div<ColorProps & StyledContainerProps & LayoutProps>`
  position: relative;
  display: flex;
  flex-flow: column;
  height: ${(props) => props.theme.WindowInnerHeight}px;
  transform: translateX(${(props) => (props.hidden ? '-70vw' : '0')});
  width: ${(props) => (props.isExpanded ? '100vw' : '70vw')};

  .appIsInModal && {
    width: ${(props) => (props.isExpanded ? 'calc(100vw - 40px)' : '70vw')};
    transform: translateX(${(props) => (props.hidden ? '-70w' : '0')});
  }

  transition: width 0.5s ease, transform 0.6s ease;
  ${color}
  ${layout}
`

const HausCardContainer = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;
  height: calc(100vh - 40px);
  position: relative;
`
