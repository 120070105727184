import * as React from 'react'
import styled, { css } from 'styled-components'
import { ColorProps, color } from 'styled-system'
import {
  IconButton,
  Typography,
  TypographyType,
  SVGIcon,
  IconType,
  SanitizedContent
} from '../../atoms'

interface CollapsibleParagraphProps {
  children: string
}

export const CollapsibleParagraph: React.FunctionComponent<CollapsibleParagraphProps> = ({
  children
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const [childContent, setChildContent] = React.useState(children)

  const shortChildContent = children.slice(0, 300) + '...'

  const dompurifyOptions = {
    ADD_ATTR: ['target']
  }

  React.useEffect(() => {
    if (children.length > 300) {
      setChildContent(shortChildContent)
    }
  }, [])

  const handleCollapseContent = () => {
    setExpanded(!expanded)
    setTimeout(() => {
      setChildContent(expanded ? shortChildContent : children)
    }, 50)
  }

  return (
    <ParagraphContainer>
      <ParagraphContainerInner expanded={expanded} shouldBeCollapsible={children.length > 300}>
        <Typography type={TypographyType.TEXT}>
          <SanitizedContent content={childContent} options={dompurifyOptions} />
        </Typography>
      </ParagraphContainerInner>
      {children.length > 300 && (
        <CollapseButton
          backgroundColor={expanded ? 'gray' : 'primary'}
          onClick={handleCollapseContent}>
          <IconButton
            hasLeftPadding={false}
            color='white'
            width='25px'
            height='25px'
            onClick={handleCollapseContent}>
            {expanded ? (
              <SVGIcon
                iconName={IconType.IconMinus}
                viewBox='0 0 16 16'
                color='white'
                width='25px'
                height='25px'
                margin='0px 0px 0px 0px '
              />
            ) : (
              <SVGIcon
                iconName={IconType.IconPlus}
                viewBox='0 0 16 16'
                color='white'
                width='25px'
                height='25px'
                margin='0px 0px 0px 0px '
              />
            )}
          </IconButton>
        </CollapseButton>
      )}
    </ParagraphContainer>
  )
}

export default CollapsibleParagraph

const ParagraphContainer = styled.div``

interface ParagraphContainerInnerProps {
  shouldBeCollapsible?: boolean
  expanded?: boolean
}
const ParagraphContainerInner = styled.div<ParagraphContainerInnerProps>`
  display: block;
  position: relative;
  width: calc(100% - 15px);
  overflow: hidden;
  transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
  height: auto;

  ${(props) => props.expanded && expandedStyle}
  ${(props) => !props.expanded && !!props.shouldBeCollapsible && afterStyle}
`
const expandedStyle = css`
  max-height: 99999px;
  transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
`
const afterStyle = css`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 1)
    );
  }
`

const CollapseButton = styled.div<ColorProps>`
  ${color}
  /* display: inline-block; */
  font-size: 10px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  transition: all 0.5s ease;
  float: right;
  margin-top: -30px;
  margin-right: -15px;
`
