import * as React from 'react'
import styled from 'styled-components'
import { SpaceProps, space } from 'styled-system'

import { AppTheme as theme } from '../../../theme'
import { TypographyType, Typography, Snackbar, Divider, SVGIcon, IconType } from '../../atoms'
import { IEHContent } from '../../statics/interfaces'

interface ProductCardHeadProps {
  isMobile?: boolean
  product?: IEHContent
  noToggle?: string
  lineColor?: string
  isMenu?: boolean
  title?: string
}

export const ProductCardHead: React.FunctionComponent<ProductCardHeadProps> = ({
  isMobile,
  product,
  noToggle,
  lineColor,
  isMenu,
  title
}: ProductCardHeadProps) => {
  const [isSnackbarVisible, setIsSnackbarVisible] = React.useState(false)
  const [isSelected, setIsSelected] = React.useState(false)
  const [show, setShow] = React.useState(true)

  React.useEffect(() => {
    // if (appStore && contentStore.checkIfIdIsOnWatchList(contentStore.getActualContentId())) {
    //   setIsSelected(true)
    // } else {
    //   setIsSelected(false)
    // }
    setShow(false)
    setTimeout(() => {
      setIsSnackbarVisible(false)
      setShow(true)
    })
  }, [product])

  const radioToggle = () => {
    setIsSnackbarVisible(!isSnackbarVisible)
    setIsSelected(!isSelected)
    // if (appStore) {
    //   contentStore.toggleIdOnWatchlist(contentStore.getActualContentId())
    // }
  }

  let marginLeft = '0px'
  let marginLeftPos = '0px'
  let width = '100%'
  if (!noToggle) {
    marginLeft = '-20px'
    marginLeftPos = '20px'
    width = 'calc (100% + 20px)'
  }

  return isMobile ? (
    <MobileTitleContainer pt={theme.space.S} pb={isMenu ? theme.space.NONE : theme.space.L}>
      {show === true && (
        <div style={{ width: '100%' }}>
          <Typography type={TypographyType.PRODUCT_HEAD} inline>
            {!!product ? product.title : !!title ? title : ''}
          </Typography>
          <Divider color='secondary' />
        </div>
      )}
    </MobileTitleContainer>
  ) : (
    <>
      <TitleContainer pt={theme.space.S} data-ref='TitleContainer' style={{ marginLeft }}>
        {show === true && (
          <div style={{ width }}>
            {!noToggle && (
              <div
                onClick={() => radioToggle()}
                style={{
                  display: 'inline-block',
                  marginRight: '7px',
                  marginLeft: '-2px',
                  paddingTop: '5px',
                  float: 'left'
                }}>
                <SVGIcon
                  iconName={isSelected ? IconType.IconRememberOn : IconType.IconRememberOff}
                  viewBox='0 0 16 16'
                  color={theme.colors.primary}
                  width='16px'
                  height='16px'
                  margin='0px 0px 0px 0px '
                />
              </div>
            )}

            <Typography type={TypographyType.PRODUCT_HEAD} inline>
              {!!product ? product.title : !!title ? title : ''}
            </Typography>
            <Snackbar isMobile={false} visible={isSnackbarVisible === true}>
              {'Produkt wurde der Merkliste im Kontaktformular hinzugefügt'}
            </Snackbar>
            <Divider
              marginLeft={marginLeftPos}
              width='100%'
              color={lineColor ? lineColor : 'secondary'}
            />
          </div>
        )}
      </TitleContainer>
    </>
  )
}

export default ProductCardHead

ProductCardHead.defaultProps = {
  isMobile: false
}

const TitleContainer = styled.div<SpaceProps>`
  ${space}
  display: inline-block;
  width: 100%;
`
const MobileTitleContainer = styled.div<SpaceProps>`
  ${space}
  display: block;
  /* display: inline-flex;
  justify-content: space-evenly; */
  width: 100%;
`
