import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { TypographyType, Typography } from '../../atoms'
import { AppTheme as theme } from '../../../theme'

export const LoadingPage: React.FC = () => {
  return (
    <StyledLoadingPage>
      <>
        <motion.div
          style={loadingContainer}
          variants={loadingContainerVariants}
          initial='start'
          animate='end'>
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
        </motion.div>
        <Typography type={TypographyType.TEXT} color={theme.colors.darkgray} fontSize='1.8rem'>
          Loading
        </Typography>
      </>
    </StyledLoadingPage>
  )
}

export default LoadingPage

const StyledLoadingPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 20000;
  background-color: #fff;
`

const loadingContainer = {
  width: '5rem',
  height: '5rem',
  display: 'flex',
  justifyContent: 'space-around'
}

const loadingCircle = {
  display: 'block',
  width: '1rem',
  height: '1rem',
  backgroundColor: theme.colors.primary,
  borderRadius: '0.25rem'
}

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2
    }
  },
  end: {
    transition: {
      staggerChildren: 0.2
    }
  }
}

const loadingCircleVariants = {
  start: {
    y: '50%'
  },
  end: {
    y: '150%'
  }
}

const loadingCircleTransition = {
  duration: 0.5,
  reverse: Infinity,
  ease: 'easeInOut'
}
