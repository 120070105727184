import * as React from 'react'
import styled from 'styled-components'
import { space, SpaceProps, PositionProps, position } from 'styled-system'
import {
  IconButton,
  Typography,
  TypographyType,
  TextField,
  Checkbox,
  Link,
  SVGIcon,
  IconType,
  TextArea,
  FlexContainer,
  Space,
  SubmitButton,
  Image
} from '../../atoms'
import { SelectPanel, ProductCardHead } from '../../molecules'
import { AppTheme, AppTheme as theme } from '../../../theme'
import {
  FlexScrollContainer,
  FlexScrollContainerHead,
  FlexScrollContainerBody,
  FlexScrollContainerBodyInner,
  MobileExpandBtnContainer
} from '../../../theme/globalStyledElements'
import { useDevice, useStore } from '../../../context'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { ContactFormFields, IFormValues } from './static'
import { toJS } from 'mobx'

interface ContactCardProps {
  showContent?: boolean
  emailSent?(): void
  onCloseContact?(): void
}

export const ContactCard: React.FunctionComponent<ContactCardProps> = observer(
  ({ showContent, onCloseContact, emailSent }: ContactCardProps) => {
    const { contentStore, appStore, uiStore } = useStore()
    const {
      register,
      formState: { errors },
      handleSubmit
    } = useForm<IFormValues>()

    const { isMobile } = useDevice()

    const [selectedItems, setSelectedItems] = React.useState(
      contentStore.getWatchListAsArrayOfObj()
    )
    const [policyAgreed, setPolicyAgreed] = React.useState(false)
    const [isPolicyError, setIsPolicyError] = React.useState(false)

    const DataProtection =
      appStore.EHSettings.settings_sw_text_form_accept_data_protection_checkbox_text.question
    const DataProtectionURL = appStore.EHSettings.settings_sw_text_form_data_protection_url.question

    const handleClose = () => {
      !isMobile && contentStore.emptyWatchList()

      if (!!onCloseContact) {
        onCloseContact()
      }

      setSelectedItems(contentStore.watchListContentIdArr)
      console.log('TODO: handle close ')
    }

    const toggleSelectedItem = (itemToggledNow: any) => {
      contentStore.toggleIdOnWatchlist(itemToggledNow)
      setSelectedItems(contentStore.watchListContentIdArr)
    }

    const InputScrollArea = React.createRef<HTMLDivElement>()

    const onSubmit = (formData: any) => {
      const useData = formData
      formData.selectedProducts = toJS(selectedItems)
      emailSent()
      appStore.sendEmail(useData)
    }

    const handleCheckPolicyAgreement = () => {
      setPolicyAgreed(!policyAgreed)
      setIsPolicyError(!!policyAgreed)
    }

    return (
      <>
        <FlexScrollContainer data-ref='FlexScrollContainerHead__contact'>
          {appStore.logo && (
            <Space pt={AppTheme.space.XXL} pb={AppTheme.space.L} height='auto'>
              <Image url={appStore.logo} />
            </Space>
          )}
          <FlexScrollContainerHead data-ref='FlexScrollContainerHead'>
            <ProductCardHead title='Kontakt' noToggle='y' isMobile={isMobile} />

            {isMobile ? (
              <>
                <div
                  style={{
                    position: 'absolute',
                    left: `${AppTheme.space.S}px`,
                    top: `${AppTheme.space.S}px`
                  }}>
                  <IconButton
                    withBackground
                    bgSecondary
                    color='white'
                    onClick={() => uiStore.handleFlipCard(true)}>
                    <SVGIcon
                      iconName={IconType.IconClose}
                      viewBox='0 0 42 42'
                      color={theme.colors.white}
                      width='42px'
                      height='42px'
                      margin='10px 0px 0px 10px'
                    />
                  </IconButton>
                </div>
                <MobileExpandBtnContainer data-ref='MobileExpandBtnContainer'>
                  <IconButton
                    onClick={() =>
                      uiStore.updateMobileContentExpanded(!uiStore.mobileContentExpanded)
                    }
                    isClicked={uiStore.mobileContentExpanded}>
                    <SVGIcon
                      iconName={IconType.IconUp}
                      viewBox='0 0 28 28'
                      color={theme.colors.darkgray}
                      width='28px'
                      height='28px'
                      margin='0px 0px 0px 0px '
                    />
                  </IconButton>
                </MobileExpandBtnContainer>
              </>
            ) : (
              <>
                {!showContent && (
                  <TimesButtonContainer
                    position='absolute'
                    right={theme.space.S}
                    top={isMobile ? '5px' : `calc(40px + ${theme.space.S}px)`}>
                    <IconButton onClick={handleClose} color={theme.colors.secondary}>
                      <SVGIcon
                        iconName={IconType.IconClose}
                        viewBox='0 0 42 42'
                        color={theme.colors.secondary}
                        width='42px'
                        height='42px'
                        margin='10px 0px 0px 10px'
                      />
                    </IconButton>
                  </TimesButtonContainer>
                )}
              </>
            )}
          </FlexScrollContainerHead>

          <FlexScrollContainerBody ref={InputScrollArea} data-ref='FlexScrollContainerBody'>
            <FlexScrollContainerBodyInner>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FlexContainer
                  flexDirection='column'
                  gap='12px'
                  style={{ marginBottom: `${AppTheme.space.L}px` }}>
                  {ContactFormFields.map((field) => (
                    <TextField
                      key={field.fieldName}
                      label={field.fieldName}
                      placeholder={field.placeholder}
                      register={register}
                      required={field.required}
                      error={errors[field.fieldName]}
                    />
                  ))}

                  <FlexContainer flexDirection='row' gap='12px'>
                    <div style={{ flex: '1' }}>
                      <TextField
                        label='zip'
                        register={register}
                        required
                        placeholder='PLZ'
                        error={errors.zip}
                      />
                    </div>
                    <div style={{ flex: '3' }}>
                      <TextField
                        label='city'
                        register={register}
                        required
                        placeholder='Ort'
                        error={errors.city}
                      />
                    </div>
                  </FlexContainer>
                </FlexContainer>

                <PartContainer marginY={theme.space.M}>
                  <Typography type={TypographyType.TEXT}>Ich interessiere mich für:</Typography>
                  <SelectPanel
                    nameProp='title'
                    valueProp='id'
                    items={contentStore.Contents}
                    onSelectItem={toggleSelectedItem}
                    selectedItems={selectedItems}
                  />
                </PartContainer>

                <PartContainer marginY={theme.space.M}>
                  <Space pb={theme.space.M}>
                    <Typography type={TypographyType.TEXT}>Kommentar:</Typography>
                  </Space>
                  <TextArea
                    register={register}
                    label='comment'
                    placeholder='Kommentar hier eingeben'
                  />
                </PartContainer>

                <>
                  <Checkbox
                    name='policy'
                    hideBox={false}
                    onChangeCheckState={handleCheckPolicyAgreement}>
                    <Typography inline color={isPolicyError ? '#f44336' : '#000'}>
                      {DataProtection}*
                    </Typography>
                  </Checkbox>
                  <LinkContainer>
                    <Link href={DataProtectionURL} target='_blank'>
                      Datenschutzerklärung öffnen
                    </Link>
                  </LinkContainer>
                </>

                <PartContainerFlex marginY={theme.space.XL}>
                  <Typography type={TypographyType.TEXT} color='gray'>
                    {'*Pflichtangaben'}
                  </Typography>
                  <SubmitButton isDisabled={!policyAgreed}>{'Absenden'}</SubmitButton>
                </PartContainerFlex>
              </form>
            </FlexScrollContainerBodyInner>
          </FlexScrollContainerBody>
        </FlexScrollContainer>
      </>
    )
  }
)

export default ContactCard

const PartContainer = styled.div<SpaceProps>`
  ${space}
  display: inline-block;
  width: 100%;
  height: auto;
`
const PartContainerFlex = styled.div<SpaceProps>`
  ${space}
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 0;
`

const TimesButtonContainer = styled.div<SpaceProps & PositionProps>`
  ${position}
  ${space}
`
const LinkContainer = styled.div`
  padding-left: 25px;
`
