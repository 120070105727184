import Axios from 'axios'
import { getBffURL, SVGPath } from '../utils'

class API {
  private CMS_URL: string = process.env.CMS_URL

  private API_URL = getBffURL

  getOneContent = async (id: string) => {
    try {
      const result = await Axios.get(this.CMS_URL + '/open-api/pages?ids=' + id)
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getMultiCMSContent = async (id: string) => {
    try {
      const result = await Axios.get(this.CMS_URL + '/open-api/pages?ids=' + id)
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getAllContents = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/api/contents')
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getCiSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/api/cisettings')
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getEHSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/api/ehsettings')
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getWHWelcomeSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/api/ehwelcomesettings')
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getEmailSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/api/ehemailsettings')
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  getNotifierSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/api/notifiersettings')
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  sendContactInformation = async (userData: any) => {
    try {
      const result = await Axios.post(this.API_URL + 'api/saveeh', userData)
      console.log('_____ send emial request from api: ', this.API_URL + 'api/savepv', result)
      return result
    } catch (err) {
      console.error(err.message || err)
      throw err
    }
  }

  public getHouseSVG = async () => {
    try {
      const svg = await Axios.get(SVGPath)
      return svg.data
    } catch (err) {
      console.error(err.message || err)
      throw err
    }
  }
}

export default API
