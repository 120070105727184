import * as React from 'react'
import styled from 'styled-components'
import posed, { PoseGroup } from 'react-pose'
import { ColorProps, color } from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Checkbox, IconButton } from '../../atoms'

interface SelectPanelProps {
  nameProp: string
  valueProp: string
  items: any[]
  selectedItems: any[]
  onSelectItem?(itemToggledNow: string, itemInTotal: any): void
}

interface CheckboxItem {
  value: string
  name: string
  checked: boolean
}

export const SelectPanel: React.FunctionComponent<SelectPanelProps> = ({
  items,
  selectedItems,
  onSelectItem,
  nameProp,
  valueProp
}) => {
  // convert string-Array to object-Array with checked state
  const convertToArrayWithChecked = (items: any) => {
    const newArr: CheckboxItem[] = [] as CheckboxItem[]

    items.forEach((item: any) => {
      // tslint:disable-next-line: no-object-literal-type-assertion
      const obj: CheckboxItem = {} as CheckboxItem
      obj.name = item[nameProp]
      obj.value = item[valueProp]
      obj.checked = false
      if (selectedItems) {
        for (let index = 0; index < selectedItems.length; index++) {
          if (
            selectedItems[index][valueProp] === obj.value &&
            selectedItems[index].checked === true
          ) {
            obj.checked = true
          }
        }
      }
      newArr.push(obj)
    })

    const newArrOrdered: CheckboxItem[] = [] as CheckboxItem[]

    newArr.map((item) => {
      if (item.checked === true) {
        newArrOrdered.push(item)
      }
    })
    newArr.map((item) => {
      if (item.checked === false) {
        newArrOrdered.push(item)
      }
    })
    return newArrOrdered
  }

  const [showAllItems, setShowAllItems] = React.useState(false)
  const [allItemsWithCheckState, setAllItemsWithCheckState] = React.useState(
    convertToArrayWithChecked(items)
  )

  // reorder items and get checked items
  const handleChangeCheckState = (itemValue: string, allItems: CheckboxItem[]) => {
    const checkedItems: string[] = []
    const reorderedItems: CheckboxItem[] = [] as CheckboxItem[]

    const itemCheckState = allItems.find((item) => item.value === itemValue)
    if (!!itemCheckState) {
      itemCheckState.checked ? (itemCheckState.checked = false) : (itemCheckState.checked = true)
    }

    allItems.map((item) =>
      item.checked
        ? (reorderedItems.unshift(item), checkedItems.push(item.value))
        : reorderedItems.push(item)
    )

    if (onSelectItem) {
      onSelectItem(itemValue, checkedItems)
    }
    setAllItemsWithCheckState(reorderedItems)
  }

  return (
    <PanelContainer>
      <CollapseButton
        backgroundColor={showAllItems ? 'gray' : 'primary'}
        onClick={() => setShowAllItems(!showAllItems)}>
        <IconButton
          hasLeftPadding={false}
          color='white'
          width='25px'
          height='25px'
          onClick={() => setShowAllItems(!showAllItems)}>
          {showAllItems ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
        </IconButton>
      </CollapseButton>

      <ItemsContainer expanded={showAllItems}>
        <PoseGroup>
          {!!allItemsWithCheckState &&
            allItemsWithCheckState.map((item: CheckboxItem, index: number) => (
              <ItemContainer key={index}>
                <Checkbox
                  key={index}
                  checked={item.checked}
                  name={item.name}
                  value={item.value}
                  hideBox
                  onChangeCheckState={() =>
                    handleChangeCheckState(item.value, allItemsWithCheckState)
                  }
                />
              </ItemContainer>
            ))}
        </PoseGroup>
      </ItemsContainer>
    </PanelContainer>
  )
}

export default SelectPanel

const PanelContainer = styled.div`
  width: inherit;
  height: auto;
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  margin: 8px auto auto -4px;
`

interface ItemsContainer {
  expanded?: boolean
}

const ItemsContainer = styled.div<ItemsContainer>`
  width: inherit;
  height: auto;
  max-height: ${(props) => (props.expanded ? '1000px' : '108px')};
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  transition: max-height 0.6s ease;
  justify-content: flex-start;
`

const CollapseButton = styled.div<ColorProps>`
  ${color}
  display: inline-block;
  font-size: 10px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s ease;
`

const ItemContainer = posed.div({
  flip: {
    transition: {
      duration: 300,
      ease: 'easeInOut'
    }
  }
})
