import * as React from 'react'
import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'

interface DividerProps {
  color?: string
  width?: string
  marginLeft?: string
  marginTop?: string | number
  marginBottom?: string | number
}

export const Divider: React.FunctionComponent<DividerProps> = ({
  color,
  width,
  marginTop,
  marginBottom,
  marginLeft
}) => {
  return (
    <StyledDivider
      className='divider'
      backgroundColor={color}
      marginTop={marginTop}
      marginBottom={marginBottom}
      width={width}
      marginLeft={marginLeft}
    />
  )
}

export default Divider

const StyledDivider = styled.hr<ColorProps & DividerProps & SpaceProps>`
  ${color}
  ${space}
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  height: 1px;
  border: none;
`
