import React, { ReactElement, ReactNode, createContext, useContext } from 'react'
import RootStore from '../store/RootStore'

interface IStoreProviderProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

export const rootStore = new RootStore()
const StoreContext = createContext<RootStore>(rootStore)

export const StoreProvider: React.FC<IStoreProviderProps> = ({ children }: IStoreProviderProps) => (
  <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
)

export const useStore = () => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}
