import * as React from 'react'
import styled from 'styled-components'
import { readableColor } from 'polished'
import { color, ColorProps } from 'styled-system'
import { Typography, TeaserButton } from '../../atoms'
import { AppTheme as theme } from '../../../theme'
import { SVGContainerProps, TeaserContainerProps } from './static'

interface TeaserDesktopProps extends TeaserContainerProps {
  productName: string
  title: string
  intro?: string
  primColor: string
  secColor: string
  onStart?(): void
}

export const Teaser_Desktop: React.FunctionComponent<TeaserDesktopProps> = ({
  productName,
  title,
  intro,
  primColor,
  secColor,
  size
}) => {
  return (
    <TeaserContainer bg={secColor} size={size} className='teaser_desktop'>
      <TeaserBackground svgPath={`${process.env.HOST_URL}/public/background.svg`}>
        {size === 'L' && (
          <>
            <TeaserHeader className='teaser_header'>
              <Typography
                fontSize='20px'
                color={readableColor(secColor, '#000', '#FFF')}
                fontWeight={theme.fontweight.regular}>
                {productName}
              </Typography>
            </TeaserHeader>

            <ContentContainer>
              <TextContent>
                <Typography
                  color={readableColor(secColor, '#000', '#FFF')}
                  fontSize='40px'
                  fontWeight={theme.fontweight.medium}
                  lineHeight='3.2rem'>
                  {title}
                </Typography>
                <Typography
                  color={readableColor(secColor, '#000', '#FFF')}
                  fontSize='18px'
                  fontWeight={theme.fontweight.semiBold}
                  lineHeight='1.8rem'>
                  {intro}
                </Typography>
                <ButtonContainer>
                  <TeaserButton bgColor={primColor}>
                    <Typography
                      fontWeight={theme.fontweight.medium}
                      fontSize='18px'
                      // onClick={onStart}
                      color={readableColor(primColor, '#000', '#FFF')}>
                      STARTEN
                    </Typography>
                  </TeaserButton>
                </ButtonContainer>
              </TextContent>
              <SVGContainer
                className='svg_container'
                svgPath={`${process.env.HOST_URL}/public/teaser_haus.svg`}
              />
            </ContentContainer>
            <TeaserFooter className='teaser_footer' bg={primColor} />
          </>
        )}

        {size === 'M' && (
          <ContentContainer_M>
            <TextContent_M>
              <Typography
                fontSize='20px'
                color={readableColor(secColor, '#000', '#FFF')}
                fontWeight={theme.fontweight.regular}>
                {productName}
              </Typography>
              <TitleContainer>
                <Typography
                  color={readableColor(secColor, '#000', '#FFF')}
                  fontSize='42px'
                  fontWeight={theme.fontweight.medium}
                  lineHeight='3.4rem'>
                  {title}
                </Typography>
              </TitleContainer>
            </TextContent_M>
            <ButtonContainer_M>
              <TeaserButton bgColor={primColor} height='60px'>
                <Typography
                  fontWeight={theme.fontweight.medium}
                  fontSize='18px'
                  // onClick={onStart}
                  color={readableColor(primColor, '#000', '#FFF')}>
                  STARTEN
                </Typography>
              </TeaserButton>
            </ButtonContainer_M>
          </ContentContainer_M>
        )}
        {size === 'S' && (
          <>
            <SVGContainer_S
              className='svg_container'
              svgPath={`${process.env.HOST_URL}/public/teaser_haus.svg`}
            />
            <TeaserFooter className='teaser_footer' bg={primColor} />
          </>
        )}
      </TeaserBackground>
    </TeaserContainer>
  )
}

export default Teaser_Desktop

const TeaserContainer = styled.div<ColorProps & TeaserContainerProps>`
  position: relative;
  width: ${(props) => (props.size === 'M' || props.size === 'L' ? '950px' : '300px')};
  height: ${(props) => (props.size === 'L' ? '550px' : props.size === 'M' ? '300px' : '250px')};
  ${color}/* background: linear-gradient(146deg, rgba(160, 196, 201, 1) 0%, rgba(124, 175, 183, 1) 100%); */
`
const TeaserBackground = styled.div<SVGContainerProps>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: cover;
`

/** ========= Teaser-L ========== */

const TeaserHeader = styled.div`
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  padding: 25px 40px;
`

const TeaserFooter = styled.div<ColorProps>`
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 5;

  ${color}
`

const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const TextContent = styled.div`
  display: flex;
  padding: 0px 10px 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  height: 100%;
  z-index: 10;
  user-select: none;
  max-width: 700px;
`

const ButtonContainer = styled.div`
  padding: 25px 0px;
`

const SVGContainer = styled.div<SVGContainerProps>`
  display: block;
  position: absolute;
  bottom: 39px;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;
`

/** ========= Teaser-M ========== */

const ContentContainer_M = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  display: flex;
  flex-direction: row;
`
const TextContent_M = styled.div`
  display: inline-block;
  position: relative;
  width: 75%;
  height: 100%;
  user-select: none;
`

const TitleContainer = styled.div`
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  max-width: 480;
`

const ButtonContainer_M = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
  margin: 0 auto;
`

const SVGContainer_S = styled.div<SVGContainerProps>`
  display: block;
  position: absolute;
  bottom: 39px;
  height: 100%;
  width: 90%;
  margin: auto 5%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: bottom;
`
