export interface IFormValues {
  customerNumber: string
  firstName: string
  lastName: string
  email: string
  tel: string
  street: string
  zip: string
  city: string
  comment: string
}

type ContactFormFieldType = {
  fieldName:
    | 'customerNumber'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'tel'
    | 'street'
    | 'zip'
    | 'city'
    | 'comment'
  placeholder: string
  required: boolean
}

export const ContactFormFields: ContactFormFieldType[] = [
  {
    fieldName: 'customerNumber',
    placeholder: 'Kundennummer',
    required: false
  },
  {
    fieldName: 'firstName',
    placeholder: 'Vorname',
    required: true
  },
  {
    fieldName: 'lastName',
    placeholder: 'Nachname',
    required: true
  },
  {
    fieldName: 'email',
    placeholder: 'Email',
    required: true
  },
  {
    fieldName: 'tel',
    placeholder: 'Telefon',
    required: false
  },
  {
    fieldName: 'street',
    placeholder: 'Strasse und Hausnummer',
    required: true
  }
]

export type ContactUserDataType = {
  customer: {
    [key: string]: string | boolean | { [key: string]: string }
    address: { [key: string]: string }
  }
  appointment: { [key: string]: string[] }
  interested: string[]
}
