import { makeAutoObservable, runInAction, toJS } from 'mobx'
import DOMPurify from 'dompurify'
import API from '../api/api'
import { IEHContent } from '../components/statics/interfaces'
import { ICMSPage } from '../components/templates/EchoContent/interfaces'
import RootStore from './RootStore'

interface ContactFormResult {
  customerNumber?: string
  firstName: string
  lastName: string
  email: string
  tel?: string
  street: string
  zip: string
  city: string
  selectedProducts: string[]
  comment?: string
}
class ApplicationStore {
  private rootStore: RootStore

  public apiUrl: string
  public theme: any
  // public configPopupWelcomeScreen: ConfigPopupWelcomeScreen
  public userData: ContactFormResult
  public EHSettings: any
  public customFont: string

  public api: API
  // when an input text field or textarea is focues, timestamp of that focus is set here
  // some milliseconds after a resize is not a resize, it is because of keyboard focus
  // tha tis important for not to trigger a normal resize event when only virtual keyboard did shake the screen
  // if normal resize occurs, focus is lost
  public lastKeyboardInputFocus: number = Date.now()
  public CISettings: any
  private NotifierSettings: any
  private EmailSettings: any
  public logo: string
  public welcomeSettings: any

  public constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.api = new API()
    // this.configPopupWelcomeScreen = { head: '', text: '' }
    // this.getNotifierSettings()
    this.getEHSettings()
    this.getEmailSettings()
  }

  public makeFullUrl(folderAndFileName: string) {
    return process.env.APP_UPLOADS_BASE_URL + folderAndFileName
  }

  async getCiSettingsAndMapToTheme(theme: any) {
    const data = await this.api.getCiSettings()
    runInAction(() => {
      this.CISettings = data
      this.mapCiSettingsToTheme(data, theme)
      this.logo = data.settings_sw_file_logo
        ? `${process.env.APP_UPLOADS_BASE_URL}${data.settings_sw_file_logo}`
        : undefined
    })

    return this.CISettings
  }

  async getEHWelcomeSettingsAndMapToTheme(theme: any) {
    const data = await this.api.getWHWelcomeSettings()
    this.mapEHWelcomeSettingsToTheme(theme)
    return data.settings
  }

  async getEHSettings() {
    const data: any = await this.api.getEHSettings()
    this.EHSettings = toJS(data.settings)
    return toJS(this.EHSettings)
  }

  async getNotifierSettings() {
    const data: any = await this.api.getNotifierSettings()
    this.NotifierSettings = toJS(data.settings)
    return toJS(this.NotifierSettings)
  }

  async getEmailSettings() {
    const data: any = await this.api.getEmailSettings()
    this.EmailSettings = data.settings
    return toJS(this.EmailSettings)
  }

  public mapEHWelcomeSettingsToTheme(theme: any) {
    // this.configPopupWelcomeScreen.head = ehw.settings?.settings_ehwelcome_text_title_text?.question
    // this.configPopupWelcomeScreen.text =
    //   ehw.settings?.settings_ehwelcome_text_content_text?.question

    this.theme = theme
  }

  public async sendEmail(userData: ContactFormResult) {
    this.userData = userData
    const data: any = {}
    const interestedProducts: string[] = []
    const selectedProdCmsPageId: string[] = []
    const selectedProdCmsMeta: any[] = []

    userData.selectedProducts.forEach((product: string) => {
      const selectedProd = this.rootStore.contentStore.Contents.filter(
        (content: IEHContent) => content.id === product
      )[0]
      selectedProdCmsPageId.push(selectedProd.cmsPage._id)
      interestedProducts.push(selectedProd.title)
    })

    if (selectedProdCmsPageId.length > 0) {
      await this.api.getMultiCMSContent(selectedProdCmsPageId.join('-')).then((data: any[]) =>
        data.forEach((content: ICMSPage) =>
          selectedProdCmsMeta.push({
            title: content.meta.nameIntern,
            contact: content.meta.email
          })
        )
      )
    }

    data.customer = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      tel: userData.tel ?? '',
      customerNumber: userData.customerNumber ?? '',
      address: {
        street: userData.street,
        zip: userData.zip,
        city: userData.city
      }
    }

    data.data = selectedProdCmsMeta

    data.interested = interestedProducts
    data.isNewVersion = true
    data.gdprAccepted = true
    data.comment = userData.comment

    const dompurifyOptions = {
      ADD_ATTR: ['target']
    }

    const htmlMessage = DOMPurify.sanitize(
      this.EmailSettings?.settings_ehemail_text_message_text?.question ?? '',
      dompurifyOptions
    )
    const htmlSignature = DOMPurify.sanitize(
      this.EmailSettings?.settings_ehemail_text_signature_text?.question ?? '',
      dompurifyOptions
    )

    data.meta = {
      sw: {
        company: this.CISettings.settings_sw_text_name,
        email: this.CISettings.settings_sw_text_sw_email,
        phone: this.CISettings.settings_sw_text_sw_phone,
        address: {
          street: this.CISettings.settings_sw_text_sw_street,
          city: this.CISettings.settings_sw_text_sw_city,
          zip: this.CISettings.settings_sw_text_sw_zip
        },
        template: {
          subject: this.EmailSettings?.settings_ehemail_text_subject_text?.question,
          greeting: this.EmailSettings?.settings_ehemail_text_greeting_text?.question,
          message: htmlMessage,
          signature: htmlSignature
        },
        ci: {
          logo: `${process.env.APP_UPLOADS_BASE_URL}${this.CISettings.settings_sw_file_logo}`,
          primary: this.CISettings.settings_sw_color_color1,
          secondary: this.CISettings.settings_sw_color_color2
        }
      },
      product: {
        name: 'Energiehaus',
        description_intro:
          this.NotifierSettings?.settings?.settings_notifier_text_intro_text?.question ?? '',
        description_middle:
          this.NotifierSettings?.settings?.settings_notifier_text_middle_text?.question ?? '',
        description_outro:
          this.NotifierSettings?.settings?.settings_notifier_text_outro_text?.question ?? ''
      },
      contact: ''
    }

    console.log('whole user Data ', data)

    this.api.sendContactInformation(data).then((result) => {
      console.log(result?.status, result?.statusText)
      if (result?.status === 200 || result?.statusText === 'OK') {
        runInAction(() => {
          this.rootStore.uiStore.emailState = 'succeeded'
        })
      } else {
        runInAction(() => {
          this.rootStore.uiStore.emailState = 'failed'
        })
      }
    })
    setTimeout(() => {
      if (this.rootStore.uiStore.emailState !== 'succeeded')
        runInAction(() => {
          this.rootStore.uiStore.emailState = 'failed'
        })
    }, 20000)
  }

  private mapCiSettingsToTheme(ci: any, theme: any) {
    console.log('mapCiSettingsToTheme', ci)
    if (Boolean(ci.settings_sw_color_color1)) {
      theme.colors.primary = ci.settings_sw_color_color1
    }
    if (Boolean(ci.settings_sw_color_color2)) {
      theme.colors.secondary = ci.settings_sw_color_color2
    }

    if (!!ci.settings_sw_text_font && ci.settings_sw_text_font.trim().length > 0) {
      // theme.fontFamily.medium = ci.settings_sw_text_font
      // theme.fontFamily.regular = ci.settings_sw_text_font

      // theme.fontFamily = ci.settings_sw_text_font + Roboto
      theme.customFont = ci.settings_sw_text_font
      this.customFont = theme.fontFamily
    }

    if (Boolean(ci.settings_sw_file_upload_font)) {
      theme.fontURL = process.env.APP_UPLOADS_BASE_URL + ci.settings_sw_file_upload_font
    }

    // toDo: map to settings not dummy texts
    this.getEHWelcomeSettingsAndMapToTheme(theme).then((result) => {
      this.welcomeSettings = result
    })

    this.theme = theme
  }
}

export default ApplicationStore
