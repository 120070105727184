import { observer } from 'mobx-react-lite'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Typography, TypographyType, IconButton, SVGIcon, IconType } from '../../atoms'
import { useStore } from '../../../context'
import { AppTheme } from '../../../theme'

interface WelcomeScreenProps {
  onClose?(): void
}

export const WelcomeScreen: React.FunctionComponent<WelcomeScreenProps> = observer(
  ({ onClose }: WelcomeScreenProps) => {
    const { appStore } = useStore()

    return (
      <ThemeProvider theme={AppTheme}>
        <StyledPopupContainer>
          <div style={{ float: 'right', marginRight: '-10px' }}>
            <IconButton color='white' onClick={onClose} margin={`0px 0px 0px 0px`}>
              <SVGIcon
                iconName={IconType.IconClose}
                viewBox='0 0 42 42'
                color={AppTheme.colors.primary}
                width='42px'
                height='42px'
                margin='0px 0px 0px 0px '
              />
            </IconButton>
          </div>
          <StyledBox>
            <StyledPopupHead>
              <Typography type={TypographyType.HEAD} color='black'>
                {appStore.configPopupWelcomeScreen.head}
              </Typography>
            </StyledPopupHead>

            <StyledPopupText>
              <Typography type={TypographyType.TEXT} color='black'>
                {appStore.configPopupWelcomeScreen.text}
              </Typography>
            </StyledPopupText>
          </StyledBox>
        </StyledPopupContainer>
      </ThemeProvider>
    )
  }
)
export default WelcomeScreen

const StyledPopupContainer = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  display: block;
  height: ${(props) => props.theme.WindowInnerHeight - 40}px;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: ${(props) => props.theme.WindowInnerWidth - 40}px;
`

const StyledBox = styled.div`
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: block;
`

const StyledPopupHead = styled.div`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
`
const StyledPopupText = styled.div`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 40px;
`
