export type TypoTypes =
  | 'productHead'
  | 'head'
  | 'subHead'
  | 'text'
  | 'textS'
  | 'category'
  | 'footer'

export enum TypographyType {
  PRODUCT_HEAD = 'productHead',
  HEAD = 'head',
  SUBHEAD = 'subHead',
  TEXT = 'text',
  TEXT_SMALL = 'textS',
  CATEGORY = 'category',
  FOOTER = 'footer'
}

export enum TypographyDisplay {
  INLINE = 'inline-block',
  BLOCK = 'block'
}

export enum TypographyAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}
