import React from 'react'
import ContentLoader from 'react-content-loader'

export const FollowUpContentLoader: React.FC = () => {
  return (
    <div className='w-full'>
      <ContentLoader height={800} width={'100%'}>
        <rect x='0' y='0' rx='5' ry='5' width='40%' height='20' />
        <rect x='0' y='42' rx='5' ry='5' width='100%' height='200' />
        <rect x='0' y='265' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='285' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='305' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='350' rx='5' ry='5' width='40%' height='20' />
        <rect x='0' y='392' rx='5' ry='5' width='100%' height='200' />
        <rect x='0' y='615' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='635' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='655' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='675' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='695' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='715' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='735' rx='5' ry='5' width='100%' height='20' />
        <rect x='0' y='755' rx='5' ry='5' width='100%' height='10' />
        <rect x='0' y='775' rx='5' ry='5' width='100%' height='10' />
      </ContentLoader>
    </div>
  )
}

export default FollowUpContentLoader
