import * as React from 'react'
import styled, { css } from 'styled-components'
import { space, SpaceProps, fontSize, FontSizeProps, color, ColorProps } from 'styled-system'

interface IconButtonProps extends IconBtnContainerProps, ContainerProps {
  children?: React.ReactNode | React.ReactElement
  color?: string
  fontSize?: string
  isPromoted?: boolean
  isLabel?: boolean
  bgSecondary?: boolean
  onClick?(): void
}

export const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>(
  function IconButtonComponent(
    {
      children,
      color,
      isClicked,
      onClick,
      withBackground,
      isProduct,
      inline,
      float,
      small,
      fontSize,
      width,
      height,
      isLabel,
      isMirrored,
      visible,
      zIndex,
      isPromoted,
      margin,
      bgSecondary,
      hasLeftPadding
    },
    ref
  ) {
    return (
      <Container
        ref={ref}
        onClick={onClick}
        inline={inline}
        float={float}
        className='icon-btn'
        fontSize={fontSize}
        visible={visible}
        zIndex={zIndex}
        margin={margin}
        hasLeftPadding={hasLeftPadding}>
        {isLabel ? (
          <LableBtnContainer color={color} isMirrored={isMirrored}>
            {children}
          </LableBtnContainer>
        ) : (
          <IconBtnContainer
            backgroundColor={
              withBackground
                ? isProduct
                  ? isPromoted === true
                    ? 'green'
                    : 'primary'
                  : bgSecondary === true
                  ? 'secondary'
                  : 'primary'
                : 'none'
            }
            fontSize={fontSize}
            color={color}
            isClicked={isClicked}
            withBackground={withBackground}
            isProduct={isProduct}
            isPromoted={isPromoted}
            small={small}
            width={width}
            height={height}>
            {children}
            {/*§setColor*/}
          </IconBtnContainer>
        )}
      </Container>
    )
  }
)

IconButton.defaultProps = {
  isClicked: false,
  withBackground: false,
  isProduct: false,
  isPromoted: false,
  inline: false,
  small: false,
  visible: true,
  hasLeftPadding: false
  // shouldUseFading: false
}

export default IconButton

interface IconBtnContainerProps {
  width?: string
  small?: boolean
  height?: string
  isClicked?: boolean
  isProduct?: boolean
  isPromoted?: boolean
  withBackground?: boolean // add Teardrop shape for icon
  isMirrored?: boolean // if product-content card is expanded
  // shouldUseFading?: boolean
  backgroundColor?: string
  fontSize?: string
}

const IconBtnContainer = styled.div<IconBtnContainerProps & SpaceProps & ColorProps>`
  ${space}
  ${color}

  margin: 0;
  width: ${(props) => (props.width ? props.width : '42px')};
  height: ${(props) => (props.small ? '15px' : props.height ? props.height : '42px')};
  font-size: ${(props) => (props.small ? '1em' : props.fontSize ? props.fontSize : '1.5em')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${(props) => (props.isClicked ? 'rotate(180deg)' : 'undefied')};
  transition: transform 0.25s ease;

  border-radius: ${(props) => props.withBackground && '100%'};
  border-top-left-radius: ${(props) => props.withBackground && !props.isProduct && '10px'};
  border-bottom-left-radius: ${(props) => props.withBackground && props.isProduct && '10px'};
`

const LableBtnContainer = styled.div<SpaceProps & FontSizeProps & IconBtnContainerProps>`
  ${color}

  width: 50px;
  height: 50px;

  font-size: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  box-shadow: 0px 2px rgba(0, 0, 0, 0.1), -2px 0px rgba(0, 0, 0, 0.1), 0px -2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  z-index: 1000;

  ${(props) => props.isMirrored && MirroredShape}
`

const MirroredShape = css`
  left: 30vw;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.1), 2px 0px rgba(0, 0, 0, 0.1), 0px -2px rgba(0, 0, 0, 0.1);
  margin-left: -2px;

  .appIsInModal &  {
    left: calc(30vw - 20px);
  }
`

interface ContainerProps {
  inline?: boolean
  float?: string
  visible?: boolean
  zIndex?: number
  margin?: string
  hasLeftPadding?: boolean
}

const Container = styled.div<ContainerProps & FontSizeProps>`
  ${fontSize}
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  float: ${(props) => props.float};
  cursor: pointer;
  position: relative;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: visibility 0.3s ease;
  z-index: ${(props) => props.zIndex};
  margin: ${(props) => props.margin};
  padding-left: ${(props) => (props.hasLeftPadding ? '2px' : '0px')};
  padding-top: ${(props) => (props.hasLeftPadding ? '2px' : '0px')};
  pointer-events: auto;
`
