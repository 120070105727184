import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { color, ColorProps } from 'styled-system'
import { IconButton } from '../../../atoms'
import { readableColor, rgba } from 'polished'
import { AppTheme as theme } from '../../../../theme'
import { observer } from 'mobx-react-lite'
import { useDevice, useStore } from '../../../../context'

interface TooltipButtonProps extends ButtonContainerProps {
  className?: string
  children?: React.ReactElement | any
  tooltip?: string
  isPromoted?: boolean
  id: string
  onClick?(): void
}

export const TooltipButton: React.FunctionComponent<TooltipButtonProps> = observer(
  ({
    children,
    className,
    expanded,
    noneExpanded,
    onClick,
    tooltip,
    isPromoted
  }: TooltipButtonProps) => {
    const extraMarginLeft = -35 // extra margin left for background color under icon
    const extraPadding = 50 // extra Padding To Avoid Text Under Icon
    const normalPaddingForText = 15

    const iconColor =
      theme.colors.primary === '#008855'
        ? theme.colors.white
        : readableColor(theme.colors.primary, theme.colors.black, theme.colors.white)

    const [expand, setExpand] = React.useState(expanded)
    const [tooltippMarginLeft, setTooltippMarginLeft] = React.useState(extraMarginLeft)
    const [tooltippPaddingLeft, setTooltippPaddingLeft] = React.useState(extraPadding)
    const [tooltippPaddingRight, setTooltippPaddingRight] = React.useState(normalPaddingForText)
    const [shouldSlideToLeft, setShouldSlideToLeft] = React.useState(false)

    const iconBtnRef = React.useRef<HTMLDivElement>(null)
    const tooltipRef = React.useRef<HTMLDivElement>(null)

    const { isMobile } = useDevice()
    const { uiStore } = useStore()

    React.useEffect(() => {
      if (iconBtnRef.current) {
        const btnLeft = Math.round(iconBtnRef.current.getBoundingClientRect().left)
        if (btnLeft < 90) {
          setShouldSlideToLeft(false)
        }

        if (isMobile && btnLeft < Math.round(theme.WindowInnerWidth * 0.6)) {
          setShouldSlideToLeft(false)
        }

        if (!isMobile && !uiStore.onlyLandingVisible) {
          if (Math.round(theme.WindowInnerWidth * 0.7) - btnLeft < 250) {
            setShouldSlideToLeft(true)
          }
        } else {
          if (theme.WindowInnerWidth - btnLeft < 250) {
            setShouldSlideToLeft(true)
          }
        }
      }
    })

    const calculateTippPos = () => {
      //check if tooltipp would reach out of cnavas on let side.
      //if so, place it left from icon
      let tooltipWidth: number = 0

      if (document.getElementById('housegraphicCanvasOuterDiv') && tooltipRef.current) {
        tooltipWidth = tooltipRef.current.clientWidth + 42 //42 is width of icon
      }

      if (shouldSlideToLeft) {
        setTooltippMarginLeft(tooltipWidth * -1 + extraMarginLeft * -1)
        setTooltippPaddingLeft(normalPaddingForText)
        setTooltippPaddingRight(extraPadding)
      } else {
        setTooltippMarginLeft(extraMarginLeft)
        setTooltippPaddingLeft(extraPadding)
        setTooltippPaddingRight(normalPaddingForText)
      }
    }

    const expandTipp = () => {
      calculateTippPos()
      setExpand(true)
    }

    const handleMouseLeave = () => {
      setExpand(!expand)
    }

    return (
      <div style={{ display: 'block' }}>
        <ButtonContainer
          className={className}
          onClick={onClick}
          onMouseEnter={expandTipp}
          onMouseLeave={handleMouseLeave}>
          <IconButton
            ref={iconBtnRef}
            zIndex={10}
            isProduct
            isPromoted={isPromoted}
            withBackground
            inline
            color={iconColor}>
            {children}
          </IconButton>

          <TooltipContainer
            className='tooltip'
            ref={tooltipRef}
            backgroundColor={isPromoted ? theme.colors.green : theme.colors.primary}
            marginLeft={tooltippMarginLeft}
            paddingLeft={tooltippPaddingLeft}
            paddingRight={tooltippPaddingRight}
            slideToLeft={shouldSlideToLeft}
            color={
              theme.colors.primary === '#008855'
                ? theme.colors.white
                : readableColor(
                    isPromoted ? theme.colors.green : theme.colors.primary,
                    '#000',
                    '#fff'
                  )
            }
            expanded={noneExpanded ? (expanded ? true : expand) : expanded ? true : expand}>
            {tooltip}
          </TooltipContainer>
        </ButtonContainer>
      </div>
    )
  }
)

TooltipButton.displayName = 'TooltipButton'

export default TooltipButton

interface ButtonContainerProps {
  expanded?: boolean
  noneExpanded?: boolean
  backgroundColor?: string
  marginLeft?: number
  paddingLeft?: number
  paddingRight?: number
  slideToLeft?: boolean
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  display: inline-flex;
  border-radius: 25px;
  height: 42px;
  min-width: 42px;
  width: auto;
`
/* tooptip container animations  */
const fadeout = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const TooltipContainer = styled.div<ColorProps & ButtonContainerProps>`
  ${color}
  display: inline-flex;
  align-items: center;
  height: 42px;
  padding: 10px ${(props) => props.paddingRight}px 10px ${(props) => props.paddingLeft}px;
  margin-left: ${(props) => props.marginLeft}px;
  font-family: ${(props) => props.theme.fontFamily};
  white-space: nowrap;
  border-radius: 25px;
  background-color: ${(props) => rgba(props.backgroundColor, props.expanded ? 0.7 : 0)};

  animation: ${(props) => (props.expanded ? undefined : fadeout)} 0.3s ease;
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  z-index: 5;
  transition: visibility 0.1s ease;
`
