import * as React from 'react'
import styled from 'styled-components'
import GraphicCanvas from './lib/GraphicCanvas'
import { IconButton, SVGIcon, IconType } from '../../atoms'
import { AppTheme as theme } from '../../../theme'
import { IShowInGraphicOptionalLayer } from '../../statics/interfaces'
import { useStore } from '../../../context'

interface HouseGraphicParentProps {
  svgPath: string
  contentsData: any
  selectedProductId: string
  modelToggle(): void
  showInGraphic?: {
    [key: string]: 'y' | 'n'
  }
  showInGraphicAllOptionalLayers?: IShowInGraphicOptionalLayer[]
}

export const HouseGraphicParent: React.FunctionComponent<HouseGraphicParentProps> = ({
  modelToggle,
  svgPath,
  contentsData,
  showInGraphic,
  showInGraphicAllOptionalLayers
}: HouseGraphicParentProps) => {
  const { contentStore } = useStore()

  const clickOnCanvasFunction = () =>
    // event: any,
    // canvasWidth: number,
    // canvasHeight: number,
    // zoom: number,
    // heightCorrection: number,
    // marginLeftMinus: number,
    // marginTopMinus: number,
    // viewportWidth: number,
    // viewportHeight: number
    {
      // if (cancelProductFromMenu) {
      //   cancelProductFromMenu()
      // }
      //const offsetTopCanvas = 40
      //const percentX = (event.layerX / viewportWidth) * 100
      //const percentY = (event.layerY / viewportHeight) * 100
    }

  return (
    <div data-ref='GraphicCanvasParent'>
      <GraphicCanvas
        svgPath={svgPath}
        contentsData={contentsData}
        showInGraphic={showInGraphic}
        showInGraphicAllOptionalLayers={showInGraphicAllOptionalLayers}
        clickOnCanvasEnabled={false}
        clickOnCanvasFunction={clickOnCanvasFunction}
        forceUpdateFromParent
        openAllHoverAreas={contentStore.ContentMeta?.showHoverAreaContents}
        colorPrimary='#9FC3C9'
        // selectedProductIdFromMenu={selectedProductId}
        // cancelProductSelectionOnCanvas={cancelProductSelectionOnCanvas}
      />
      <StyledIconButtonCanvas>
        <IconButton withBackground={false} onClick={() => modelToggle()}>
          <SVGIcon
            iconName={IconType.IconBurger}
            viewBox='0 0 45 45'
            color={theme.colors.secondary}
            width='45px'
            height='45px'
            margin='0px 0px 0px 0px '
          />
        </IconButton>
      </StyledIconButtonCanvas>
    </div>
  )
}

export default HouseGraphicParent

const StyledIconButtonCanvas = styled.div`
  pointer-events: auto;
  position: absolute;
  margin-top: 16px;
  margin-left: 16px;
`
