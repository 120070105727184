import * as React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { ListItem, ContactLink, Image, Space } from '../../atoms'
import { AppTheme, AppTheme as theme } from '../../../theme'
import { IProductForBtn } from '../../statics/interfaces'
import { useDevice, useStore } from '../../../context'
import { observer } from 'mobx-react-lite'

interface MenuListProps {
  items: IProductForBtn[]
  onSelectItem?(item: string | IProductForBtn): void
  onFlipCard?(): void
}

export const MenuList: React.FunctionComponent<MenuListProps> = observer(
  ({ items, onSelectItem, onFlipCard }: MenuListProps) => {
    const { appStore, uiStore } = useStore()
    const { isMobile } = useDevice()

    const handleSelectItem = (element: any) => {
      if (!!element.customerPage && element.customerPage !== '') {
        console.log('open product in new tab')
      } else {
        if (onSelectItem) {
          onSelectItem(element)
        }
        if (onFlipCard) {
          onFlipCard()
        }
      }
    }

    const handleOpenContactCard = () => {
      if (isMobile) {
        uiStore.handleFlipCard(false, 'contact')
        uiStore.updateContactCardTrigger('menu')
      } else {
        uiStore.updateShowContactCard(true)
      }
    }

    return (
      <StyledMenuContainer className='menu-list'>
        {appStore.logo && (
          <Space py={AppTheme.space.L} height='auto'>
            <Image url={appStore.logo} />
          </Space>
        )}
        <ListItem isFirst={isMobile} is='contact' color={theme.colors.primary} key='-1'>
          <ContactLink onClick={handleOpenContactCard} isMobile={isMobile} context='menu' />
        </ListItem>

        {/* contentStore.Contents.sort((a: any, b: any) => a.title.localeCompare(b.title)) */}
        {items?.map((element: IProductForBtn, index: number) => {
          return (
            <React.Fragment key={element.id}>
              {!!element.customerPage && element.customerPage !== '' ? (
                <a
                  href={element.customerPage}
                  target='_blank'
                  style={{ textDecoration: 'none' }}
                  key={index}
                  rel='noreferrer'>
                  <ListItem
                    key={element.id}
                    isMobile={isMobile}
                    onClick={() => handleSelectItem(element)}
                    contentId={element.id}
                    isFirst={false}
                    is='content'
                    contentIndex={index}>
                    <div>{element.title}</div>
                  </ListItem>
                </a>
              ) : (
                <ListItem
                  key={element.id}
                  isMobile={isMobile}
                  onClick={() => handleSelectItem(element)}
                  contentId={element.id}
                  isFirst={false}
                  is='content'
                  contentIndex={index}>
                  <div>{element.title}</div>
                </ListItem>
              )}
            </React.Fragment>
          )
        })}
      </StyledMenuContainer>
    )
  }
)

export default MenuList

const StyledMenuContainer = styled.div`
  text-align: left;
  height: 100%;
  /* overflow-y: scroll;
  height: ${(props) => props.theme.WindowInnerHeight && `${props.theme.WindowInnerHeight * 0.4}px`};
  ${space}; */
`
