import React from 'react'
import styled from 'styled-components'
import { SpaceProps, PositionProps, position, space } from 'styled-system'
import { Card, FollowUpContentLoader, ProductCardHead } from '../../molecules'
import { IconButton, SVGIcon, IconType } from '../../atoms'
import Module from '../EchoContent/module'
import {
  FlexScrollContainer,
  FlexScrollContainerHead,
  FlexScrollContainerBody
} from '../../../theme/globalStyledElements'
import { AppTheme as theme } from '../../../theme'

interface ContentCardProps {
  isOn?: boolean
  content: any
  onClose?(): void
  loaded: boolean
}

export const ContentCard: React.FunctionComponent<ContentCardProps> = ({
  isOn,
  content,
  onClose,
  loaded
}: ContentCardProps) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <ContentContainer>
      <Card padding={`${theme.space.M}px ${theme.space.XXL}px ${theme.space.L}px`}>
        {loaded ? (
          <FlexScrollContainer data-ref='FlexScrollContainerHead'>
            <FlexScrollContainerHead data-ref='FlexScrollContainerHead'>
              <ProductCardHead noToggle='y' title={content.meta.nameIntern} lineColor='primary' />
              <TimesButtonContainer
                position='absolute'
                right={theme.space.S}
                top={`calc(40px + ${theme.space.S}px)`}>
                <IconButton onClick={handleClose} color='secondary'>
                  <SVGIcon
                    iconName={IconType.IconClose}
                    viewBox='0 0 42 42'
                    color={theme.colors.secondary}
                    width='42px'
                    height='42px'
                    margin='10px 0px 0px 10px'
                  />
                </IconButton>
              </TimesButtonContainer>
            </FlexScrollContainerHead>
            <FlexScrollContainerBody pr={theme.space.XXL} data-ref='FlexScrollContainerBody2'>
              <Module kind='followUpContent' modules={content.modules} isParentOn={isOn} />
            </FlexScrollContainerBody>
          </FlexScrollContainer>
        ) : (
          <FollowUpContentLoader />
        )}
      </Card>
    </ContentContainer>
  )
}

export default ContentCard

const ContentContainer = styled.div`
  margin-top: 40px;
  height: calc(100% - 40px);
`
const TimesButtonContainer = styled.div<SpaceProps & PositionProps>`
  ${position}
  ${space}
`
