import * as React from 'react'
import styled, { css } from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { AppTheme as theme } from '../../../theme'

interface ModalProps {
  isOn: boolean
  children?: React.ReactElement
  onClose?(): void
}

export const Modal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  return (
    <ModalContainer isOn={props.isOn} className='modal'>
      <ModalContentContainer m={theme.space.S} className='modal-content'>
        {props.children}
      </ModalContentContainer>
    </ModalContainer>
  )
}

export default Modal

Modal.defaultProps = {
  isOn: false
}

const StyleFadeOut = css`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
`

const StyleFadeIn = css`
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
`

const ModalContainer = styled.div<Partial<ModalProps>>`
  position: absolute;
  height: 100%;
  max-width: 100%;
  width: 100%;
  top: 0;
  /* left: ${(props) => (props.isOn ? '0' : '-100%')};
  transition: left 0.5s ease; */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  ${(props) => (props.isOn ? StyleFadeIn : StyleFadeOut)}
`

const ModalContentContainer = styled.div<SpaceProps>`
  height: calc(100% - 1em);
  float: left;
  ${space}
`
