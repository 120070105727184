import * as React from 'react'
import styled from 'styled-components'

interface TeaserButtonProps extends TeaserButtonStyledProps {
  children: string | React.ReactElement
  onClick?(): void
}

export const TeaserButton: React.FunctionComponent<TeaserButtonProps> = ({
  children,
  bgColor,
  onClick,
  textColor,
  isDisabled,
  height
}) => {
  return (
    <OuterContainer
      onClick={onClick}
      isDisabled={isDisabled}
      height={height}
      className='eh_teaser_btn_outer'>
      <InnerContainer
        bgColor={bgColor}
        textColor={textColor}
        isDisabled={isDisabled}
        className='eh_teaser_btn_inner'>
        {children}
      </InnerContainer>
    </OuterContainer>
  )
}

export default TeaserButton

interface TeaserButtonStyledProps {
  bgColor?: string
  textColor?: string
  isDisabled?: boolean
  height?: string
}

const OuterContainer = styled.div<TeaserButtonStyledProps>`
  display: block;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 6px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : undefined)};
  height: ${(props) => props.height};
`

const InnerContainer = styled.div<TeaserButtonStyledProps>`
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 16px 30px;
  background-color: ${(props) => props.bgColor};
  box-shadow: ${(props) => (props.isDisabled ? 'none' : `2px 2px 4px rgba(0, 0, 0, 0.3)`)};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.isDisabled ? '#90a4ae' : props.textColor)};
  transition: all 0.5s ease;
  font-family: ${(props) => props.theme.fontFamily};

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    outline: none;
    box-shadow: none;
  }
`
