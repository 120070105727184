import ApplicationStore from './ApplicationStore'
import ContentStore from './ContentStore'
import UIStore from './UIStore'

class RootStore {
  public readonly uiStore: UIStore
  public readonly contentStore: ContentStore
  public readonly appStore: ApplicationStore

  public constructor() {
    this.appStore = new ApplicationStore(this)
    this.contentStore = new ContentStore(this)
    this.uiStore = new UIStore(this)
  }
}

export default RootStore
