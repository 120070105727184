import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export const BlockContainer = styled.div<SpaceProps>`
  ${space}
  display: table;
  width: 100%;
  white-space: normal;
  word-break: break-word;
`
