import React from 'react'
import ContentLoader from 'react-content-loader'

interface IModuleLoader {
  // hasTitle?: boolean
  hasImage?: boolean
}

export const ModuleLoader: React.FC<IModuleLoader> = ({ hasImage }: IModuleLoader) => {
  return (
    <div className='w-full'>
      {hasImage ? (
        <ContentLoader height={350} width={'100%'}>
          <rect x='0' y='0' rx='5' ry='5' width='40%' height='20' />
          <rect x='0' y='42' rx='5' ry='5' width='100%' height='200' />
          <rect x='0' y='265' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='285' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='305' rx='5' ry='5' width='100%' height='10' />
        </ContentLoader>
      ) : (
        <ContentLoader height={190} width={'100%'}>
          <rect x='0' y='0' rx='5' ry='5' width='40%' height='20' />
          <rect x='0' y='45' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='65' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='85' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='105' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='125' rx='5' ry='5' width='100%' height='10' />
          <rect x='0' y='145' rx='5' ry='5' width='100%' height='10' />
        </ContentLoader>
      )}
    </div>
  )
}

export default ModuleLoader
