import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuestionCircle,
  faSpa,
  faPlusCircle,
  faCircle,
  faInfoCircle,
  faEuroSign
} from '@fortawesome/free-solid-svg-icons'

export enum IconType {
  Times = 'Times',
  Check = 'Check',
  Test = 'Test',

  IconBath = 'Bad',
  IconCarsharing = 'Carsharing',
  IconColdContracting = 'Klima-Contracting',
  IconDistanceHeating = 'Fernwärme',
  IconDrinkingWater = 'Trinkwasser',
  IconDrinkingWaterAnalysis = 'Trinkwasseranalyse',
  IconElectric = 'Strom',
  IconEmobility = 'E-Mobilität',
  IconEnergyConsulting = 'Energieberatung',
  IconEnergySavingTip = 'Energiespartipps',
  IconGas = 'Gas',
  IconHeat = 'Heizung',
  IconHouseConnection = 'Hausanschluß',
  IconLight = 'Beleuchtung',
  IconMoveHouse = 'Umzug',
  IconPublicTransport = 'ÖPNV',
  IconRentersElectricity = 'Mieterstrom',
  IconSmartHome = 'Smart Home',
  IconSolar = 'Solar',
  IconStart = 'Start',
  IconTelecommunication = 'Telekomunikation',
  IconThermography = 'Thermographie_1',
  IconThermography02 = 'Thermographie_2',
  IconThermography03 = 'Thermographie_3',
  IconTrash = 'Abfall',
  IconUsedWater = 'Wasser',

  IconBikes = 'Bikes',
  IconBikesharing = 'Bikesharing',
  IconEBikes = 'E-Bikes',
  IconEnergieausweis = 'Energieausweis',
  IconEnergiesparverordnung = 'Gesetzliche Anforderungen',
  IconFoerderung = 'Förderung',
  IconOekostrom = 'Ökostrom',
  IconOnlineKundensupport = 'Online Kundensupport',
  IconErklaervideos = 'Erklaervideos',
  IconRegionalstrom = 'Regionalstrom',

  IconAbwasser = 'Abwasser',
  IconAlarmanlage = 'Alarmanlage',
  IconGlasfaser = 'Glasfaser',
  IconLadesaeule = 'Ladesaeule',
  IconPV = 'PV',
  IconRasenmaeher = 'Rasenmäher',
  IconTreppenlift = 'Treppenlift',
  IconWaerme = 'Wärme',
  IconWallbox = 'Wallbox',
  IconWanderwege = 'Wanderwege',

  IconContact = 'IconContact',
  IconMore = 'IconMore',
  IconClose = 'IconClose',
  IconBack = 'IconBack',
  IconBurger = 'IconBurger',
  IconPlus = 'IconPlus',
  IconMinus = 'IconMinus',
  IconUp = 'IconUp',
  IconRememberOn = 'IconRememberOn',
  IconRememberOff = 'IconRememberOff',

  Default1 = 'Default1',
  Default2 = 'Default2',
  Default3 = 'Default3',
  Default4 = 'Default4',
  Default5 = 'Default5',
  Default6 = 'Default6',
  IconDefault7 = 'Default7',
  IconDefault8 = 'Default8',
  IconDefault9 = 'Default9',
  IconDefault10 = 'Default10',
  IconDefault11 = 'Default11',
  IconDefault12 = 'Default12'
}

export const Times = (props: any) => {
  return (
    <path
      {...props}
      d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'
    />
  )
}

export const Check = (props: any) => {
  return (
    <path
      {...props}
      d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'
    />
  )
}

export const Test = (props: any) => {
  return (
    <path
      {...props}
      d='M28.505,11.494c0,0-13.847,2.532-16.971,5.657c-2.761,2.76-3.07,7.031-0.95,10.145
      c-0.99,1.043-1.891,2.148-2.585,3.29L9.413,32c0,0,0.457-1.411,2.032-3.634h0.001c2.855-3.812,6.821-7.032,8.13-8.048
      c0.15-0.114,0.288-0.226,0.443-0.339c-3.965,3.669-6.246,6.925-7.543,9.28c3.129,2.292,7.544,2.032,10.371-0.795
      C25.972,25.341,25.677,14.322,28.505,11.494z'
    />
  )
}

export const IconBath = (props: any) => {
  return (
    <g>
      <g>
        <g>
          <path
            {...props}
            d='M28.994,24.364c-1.153,0-1.78-0.601-2.194-0.998c-0.351-0.335-0.478-0.441-0.811-0.441
        c-0.332,0-0.458,0.106-0.808,0.441c-0.414,0.397-1.04,0.998-2.192,0.998s-1.778-0.601-2.192-0.998
        c-0.35-0.335-0.476-0.441-0.807-0.441s-0.457,0.106-0.807,0.441c-0.414,0.397-1.039,0.998-2.191,0.998
        c-1.151,0-1.776-0.601-2.19-0.999c-0.348-0.335-0.474-0.44-0.805-0.44c-0.332,0-0.458,0.106-0.808,0.441
        c-0.414,0.397-1.04,0.998-2.191,0.998s-1.777-0.601-2.191-0.998c-0.35-0.335-0.476-0.441-0.808-0.441c-0.553,0-1-0.447-1-1
        s0.447-1,1-1c1.152,0,1.778,0.601,2.192,0.998c0.35,0.335,0.476,0.441,0.807,0.441s0.457-0.106,0.807-0.441
        c0.414-0.397,1.04-0.998,2.192-0.998c1.151,0,1.777,0.602,2.191,0.999c0.348,0.335,0.474,0.44,0.804,0.44
        c0.331,0,0.457-0.106,0.807-0.441c0.414-0.397,1.039-0.998,2.191-0.998c1.151,0,1.777,0.601,2.191,0.998
        c0.35,0.335,0.476,0.441,0.808,0.441s0.458-0.106,0.808-0.441c0.414-0.397,1.04-0.998,2.192-0.998s1.779,0.601,2.193,0.997
        c0.351,0.336,0.478,0.442,0.812,0.442s0.461-0.106,0.812-0.442c0.414-0.396,1.041-0.997,2.193-0.997c0.553,0,1,0.447,1,1
        s-0.447,1-1,1c-0.333,0-0.46,0.106-0.811,0.441C30.774,23.764,30.147,24.364,28.994,24.364z'
          />
        </g>
        <g>
          <path
            {...props}
            d='M28.994,28.364c-1.153,0-1.78-0.601-2.194-0.998c-0.351-0.335-0.478-0.441-0.811-0.441
        c-0.332,0-0.458,0.106-0.808,0.441c-0.414,0.397-1.04,0.998-2.192,0.998s-1.778-0.601-2.192-0.998
        c-0.35-0.335-0.476-0.441-0.807-0.441s-0.457,0.106-0.807,0.441c-0.414,0.397-1.039,0.998-2.191,0.998
        c-1.151,0-1.776-0.601-2.19-0.999c-0.348-0.335-0.474-0.44-0.805-0.44c-0.332,0-0.458,0.106-0.808,0.441
        c-0.414,0.397-1.04,0.998-2.191,0.998s-1.777-0.601-2.191-0.998c-0.35-0.335-0.476-0.441-0.808-0.441c-0.553,0-1-0.447-1-1
        s0.447-1,1-1c1.152,0,1.778,0.601,2.192,0.998c0.35,0.335,0.476,0.441,0.807,0.441s0.457-0.106,0.807-0.441
        c0.414-0.397,1.04-0.998,2.192-0.998c1.151,0,1.777,0.602,2.191,0.999c0.348,0.335,0.474,0.44,0.804,0.44
        c0.331,0,0.457-0.106,0.807-0.441c0.414-0.397,1.039-0.998,2.191-0.998c1.151,0,1.777,0.601,2.191,0.998
        c0.35,0.335,0.476,0.441,0.808,0.441s0.458-0.106,0.808-0.441c0.414-0.397,1.04-0.998,2.192-0.998s1.779,0.601,2.193,0.997
        c0.351,0.336,0.478,0.442,0.812,0.442s0.461-0.106,0.812-0.442c0.414-0.396,1.041-0.997,2.193-0.997c0.553,0,1,0.447,1,1
        s-0.447,1-1,1c-0.333,0-0.46,0.106-0.811,0.441C30.774,27.764,30.147,28.364,28.994,28.364z'
          />
        </g>
        <g>
          <path
            {...props}
            d='M28.994,32.364c-1.153,0-1.78-0.601-2.194-0.998c-0.351-0.335-0.478-0.441-0.811-0.441
        c-0.332,0-0.458,0.106-0.808,0.441c-0.414,0.397-1.04,0.998-2.192,0.998s-1.778-0.601-2.192-0.998
        c-0.35-0.335-0.476-0.441-0.807-0.441s-0.457,0.106-0.807,0.441c-0.414,0.397-1.039,0.998-2.191,0.998
        c-1.151,0-1.776-0.601-2.19-0.999c-0.348-0.335-0.474-0.44-0.805-0.44c-0.332,0-0.458,0.106-0.808,0.441
        c-0.414,0.397-1.04,0.998-2.191,0.998s-1.777-0.601-2.191-0.998c-0.35-0.335-0.476-0.441-0.808-0.441c-0.553,0-1-0.447-1-1
        s0.447-1,1-1c1.152,0,1.778,0.601,2.192,0.998c0.35,0.335,0.476,0.441,0.807,0.441s0.457-0.106,0.807-0.441
        c0.414-0.397,1.04-0.998,2.192-0.998c1.151,0,1.777,0.602,2.191,0.999c0.348,0.335,0.474,0.44,0.804,0.44
        c0.331,0,0.457-0.106,0.807-0.441c0.414-0.397,1.039-0.998,2.191-0.998c1.151,0,1.777,0.601,2.191,0.998
        c0.35,0.335,0.476,0.441,0.808,0.441s0.458-0.106,0.808-0.441c0.414-0.397,1.04-0.998,2.192-0.998s1.779,0.601,2.193,0.997
        c0.351,0.336,0.478,0.442,0.812,0.442s0.461-0.106,0.812-0.442c0.414-0.396,1.041-0.997,2.193-0.997c0.553,0,1,0.447,1,1
        s-0.447,1-1,1c-0.333,0-0.46,0.106-0.811,0.441C30.774,31.764,30.147,32.364,28.994,32.364z'
          />
        </g>
      </g>
      <g>
        <path
          {...props}
          d='M12.628,15.138h5.377l2.004,2.004l-3.8,3.8c0.33,0.317,0.461,0.422,0.783,0.422
          c0.331,0,0.457-0.106,0.807-0.441c0.414-0.397,1.039-0.998,2.191-0.998c1.151,0,1.777,0.601,2.191,0.998
          c0.35,0.335,0.476,0.441,0.808,0.441s0.458-0.106,0.808-0.441c0.414-0.397,1.04-0.998,2.192-0.998
          c0.66,0,1.135,0.201,1.509,0.444l-5.357-5.357l-0.011,0.01l-2.443-2.443c-0.281-0.281-0.663-0.439-1.061-0.439h-5.998
          c-0.828,0-1.5,0.672-1.5,1.5S11.8,15.138,12.628,15.138z'
        />
      </g>
      <circle {...props} cx='27.626' cy='14.638' r='3' />
    </g>
  )
}

export const IconCarsharing = (props: any) => {
  return (
    <g id='Icons'>
      <path
        {...props}
        d='M26.2,28.2c0.661,0,1.198-0.538,1.198-1.204v-1.2H28.2c0.442,0,0.8-0.358,0.8-0.8V20.2
		c0-0.444-0.357-0.8-0.8-0.8h-0.302l-1.707-4.562c-0.236-0.625-0.833-1.038-1.498-1.038h-0.552h-6.283h-0.55
		c-0.667,0-1.263,0.413-1.5,1.038L14.102,19.4h-0.303C13.356,19.4,13,19.756,13,20.2v4.796c0,0.441,0.356,0.8,0.799,0.8h0.803v1.2
		c0,0.666,0.536,1.204,1.199,1.204S17,27.662,17,26.996v-1.2h8.004v1.2C25.004,27.662,25.536,28.2,26.2,28.2z M27.398,22.2
		c0,0.662-0.537,1.2-1.198,1.2c-0.664,0-1.196-0.538-1.196-1.2c0-0.665,0.532-1.2,1.196-1.2C26.861,21,27.398,21.535,27.398,22.2z
		 M15.801,23.4c-0.663,0-1.199-0.538-1.199-1.2c0-0.665,0.536-1.2,1.199-1.2S17,21.535,17,22.2C17,22.862,16.464,23.4,15.801,23.4z
		 M14.955,19.4l1.499-4h9.092l1.498,4H14.955z'
      />
      <g>
        <path
          {...props}
          d='M7.904,23.107l0.943-1.884c0.077-0.155,0.069-0.339-0.021-0.486C8.734,20.59,8.573,20.5,8.4,20.5H7.982
			C8.249,13.564,13.973,8,20.99,8c5.769,0,10.917,3.854,12.52,9.374c0.077,0.265,0.352,0.419,0.62,0.341
			c0.265-0.077,0.418-0.354,0.341-0.62C32.744,11.151,27.2,7,20.99,7C13.421,7,7.249,13.013,6.982,20.5H6.512
			c-0.174,0-0.334,0.09-0.426,0.237c-0.091,0.147-0.099,0.332-0.021,0.487l0.945,1.884c0.085,0.169,0.258,0.275,0.447,0.275
			S7.819,23.277,7.904,23.107z'
        />
        <path
          {...props}
          d='M35.86,20.66l-0.89-1.773c-0.085-0.17-0.258-0.276-0.447-0.276s-0.362,0.107-0.447,0.276l-0.943,1.885
			c-0.077,0.154-0.069,0.339,0.021,0.486c0.092,0.147,0.252,0.237,0.426,0.237h0.418C33.734,28.433,28.009,34,20.99,34
			c-5.757,0-10.901-3.844-12.513-9.349c-0.077-0.264-0.353-0.417-0.62-0.339c-0.265,0.077-0.417,0.354-0.339,0.62
			C9.253,30.86,14.793,35,20.99,35c7.57,0,13.743-6.016,14.008-13.505h0.471c0.008,0,0.016,0,0.02,0c0.276,0,0.5-0.224,0.5-0.5
			C35.988,20.866,35.94,20.749,35.86,20.66z'
        />
      </g>
    </g>
  )
}

export const IconColdContracting = (props: any) => {
  return (
    <path
      {...props}
      d='M33.092,27.058l-1.146-0.662l0.346-0.091c0.428-0.112,0.683-0.55,0.57-0.977
		c-0.113-0.428-0.555-0.688-0.977-0.57l-1.908,0.502l-1.324-0.765l1.822-0.479c0.428-0.112,0.683-0.55,0.57-0.977
		c-0.111-0.428-0.555-0.688-0.977-0.57l-3.384,0.891L22.6,21l4.073-2.352l3.403,0.893c0.068,0.019,0.137,0.026,0.204,0.026
		c0.354,0,0.679-0.237,0.772-0.597c0.112-0.428-0.143-0.865-0.57-0.977l-1.841-0.482l1.336-0.771l1.909,0.502
		c0.068,0.019,0.137,0.026,0.204,0.026c0.354,0,0.679-0.237,0.772-0.597c0.112-0.427-0.143-0.864-0.57-0.977l-0.347-0.091
		l1.146-0.662c0.382-0.221,0.514-0.71,0.292-1.093c-0.219-0.381-0.709-0.514-1.093-0.292l-1.146,0.661l0.095-0.347
		c0.116-0.426-0.135-0.865-0.562-0.981c-0.427-0.117-0.865,0.136-0.981,0.562l-0.519,1.902l-1.324,0.765l0.495-1.817
		c0.116-0.427-0.135-0.866-0.561-0.982c-0.434-0.118-0.866,0.136-0.982,0.561l-0.921,3.376L21.8,19.614v-4.789l2.469-2.494
		c0.311-0.313,0.309-0.82-0.006-1.131c-0.312-0.31-0.82-0.308-1.131,0.006L21.8,12.552V11.05l1.389-1.403
		c0.311-0.313,0.309-0.82-0.006-1.131c-0.312-0.311-0.82-0.308-1.131,0.006L21.8,8.776V7.5c0-0.441-0.358-0.8-0.8-0.8
		s-0.8,0.358-0.8,0.8v1.276l-0.252-0.255c-0.311-0.315-0.818-0.317-1.131-0.006c-0.314,0.311-0.316,0.817-0.006,1.131L20.2,11.05
		v1.502l-1.332-1.346c-0.311-0.314-0.818-0.316-1.131-0.006c-0.314,0.311-0.316,0.817-0.006,1.131l2.469,2.494v4.789l-4.148-2.395
		l-0.925-3.385c-0.116-0.426-0.555-0.681-0.982-0.561c-0.426,0.116-0.677,0.557-0.561,0.982l0.499,1.826l-1.301-0.751l-0.521-1.904
		c-0.116-0.426-0.557-0.68-0.982-0.561c-0.426,0.116-0.678,0.557-0.561,0.982l0.095,0.346l-1.104-0.638
		c-0.384-0.224-0.873-0.09-1.093,0.292c-0.222,0.383-0.09,0.872,0.292,1.093l1.105,0.639l-0.347,0.091
		c-0.428,0.112-0.683,0.549-0.57,0.977c0.094,0.359,0.418,0.597,0.772,0.597c0.067,0,0.136-0.008,0.204-0.026l1.909-0.501
		l1.301,0.751l-1.831,0.48c-0.428,0.112-0.683,0.549-0.57,0.977c0.094,0.359,0.418,0.597,0.772,0.597
		c0.067,0,0.136-0.008,0.204-0.026l3.394-0.891L19.4,21l-4.148,2.395l-3.394-0.891c-0.429-0.117-0.865,0.143-0.977,0.57
		c-0.112,0.428,0.143,0.864,0.57,0.977l1.831,0.48l-1.301,0.751l-1.909-0.501c-0.429-0.117-0.865,0.143-0.977,0.57
		c-0.112,0.428,0.143,0.864,0.57,0.977l0.347,0.091l-1.105,0.639c-0.382,0.221-0.514,0.71-0.292,1.093
		c0.147,0.256,0.417,0.399,0.693,0.399c0.136,0,0.273-0.034,0.399-0.107l1.104-0.638l-0.095,0.346
		c-0.117,0.426,0.135,0.866,0.561,0.982c0.07,0.02,0.142,0.028,0.211,0.028c0.353,0,0.675-0.233,0.771-0.589l0.521-1.904
		l1.301-0.751l-0.499,1.826c-0.116,0.426,0.135,0.866,0.561,0.982c0.07,0.02,0.142,0.028,0.211,0.028
		c0.353,0,0.675-0.233,0.771-0.589l0.925-3.385l4.148-2.395v4.703l-2.476,2.5c-0.311,0.313-0.309,0.82,0.006,1.131
		c0.155,0.154,0.359,0.231,0.562,0.231c0.206,0,0.412-0.079,0.568-0.237l1.339-1.352v1.542l-1.389,1.402
		c-0.311,0.313-0.309,0.82,0.006,1.131c0.313,0.312,0.821,0.309,1.131-0.006l0.252-0.254V34.5c0,0.441,0.358,0.8,0.8,0.8
		s0.8-0.358,0.8-0.8v-1.322l0.252,0.254c0.156,0.158,0.362,0.237,0.568,0.237c0.203,0,0.407-0.077,0.562-0.231
		c0.314-0.311,0.316-0.817,0.006-1.131L21.8,30.904v-1.528l1.326,1.339c0.31,0.312,0.816,0.314,1.132,0.005
		c0.313-0.311,0.315-0.817,0.005-1.132L21.8,27.102v-4.716l4.073,2.352l0.928,3.394c0.097,0.355,0.419,0.589,0.771,0.589
		c0.069,0,0.141-0.009,0.211-0.028c0.426-0.116,0.678-0.557,0.561-0.982l-0.502-1.835l1.335,0.771l0.521,1.904
		c0.097,0.355,0.419,0.589,0.771,0.589c0.069,0,0.141-0.009,0.211-0.028c0.426-0.116,0.677-0.556,0.561-0.982l-0.095-0.346
		l1.146,0.661c0.126,0.073,0.264,0.107,0.399,0.107c0.276,0,0.546-0.144,0.693-0.399C33.605,27.768,33.474,27.278,33.092,27.058z'
    />
  )
}

export const IconDistanceHeating = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M17.999,27.689c1.04,0,1.894-0.787,1.987-1.812C19.996,25.836,20,25.791,20,25.746V13.715
			c0-0.004,0-0.008,0-0.014c0-0.004,0-0.006,0-0.01c0-1.105-0.896-2.002-2.001-2.002c-1.062,0-1.934,0.834-1.992,1.939
			C16.002,13.656,16,13.688,16,13.715v12.031c0,0.037,0.004,0.074,0.011,0.111C16.098,26.893,16.955,27.689,17.999,27.689z'
      />
      <path
        {...props}
        d='M22.999,11.689c-1.062,0-1.934,0.834-1.992,1.939C21.002,13.656,21,13.688,21,13.715v12.031
			c0,0.037,0.004,0.074,0.011,0.111c0.087,1.035,0.944,1.832,1.988,1.832c1.04,0,1.894-0.787,1.987-1.812
			C24.996,25.836,25,25.791,25,25.746V13.715c0-0.004,0-0.008,0-0.014c0-0.004,0-0.006,0-0.01
			C25,12.586,24.104,11.689,22.999,11.689z'
      />
      <path
        {...props}
        d='M27.999,27.689c1.04,0,1.894-0.787,1.987-1.812C29.996,25.836,30,25.791,30,25.746V13.715
			c0-0.004,0-0.008,0-0.014c0-0.004,0-0.006,0-0.01c0-1.105-0.896-2.002-2.001-2.002c-1.062,0-1.934,0.834-1.992,1.939
			C26.002,13.656,26,13.688,26,13.715v12.031c0,0.037,0.004,0.074,0.011,0.111C26.098,26.893,26.955,27.689,27.999,27.689z'
      />
      <circle {...props} cx='32' cy='13.189' r='1.5' />
      <path
        {...props}
        d='M24.25,31.189H7.5v-7H11v1.557c0,0.037,0.004,0.074,0.011,0.111c0.087,1.035,0.944,1.832,1.988,1.832
			c1.04,0,1.894-0.787,1.987-1.812C14.996,25.836,15,25.791,15,25.746V13.715c0-0.004,0-0.008,0-0.014c0-0.004,0-0.006,0-0.01
			c0-1.105-0.896-2.002-2.001-2.002c-1.062,0-1.934,0.834-1.992,1.939C11.002,13.656,11,13.688,11,13.715v9.475H7
			c-0.276,0-0.5,0.224-0.5,0.5v8c0,0.276,0.224,0.5,0.5,0.5h17.25c0.276,0,0.5-0.224,0.5-0.5S24.526,31.189,24.25,31.189z'
      />
      <path
        {...props}
        d='M33,31.189h-5.793l1.268-1.268c0.195-0.195,0.195-0.512,0-0.707s-0.512-0.195-0.707,0l-2.12,2.12
			c-0.047,0.047-0.084,0.102-0.109,0.164c-0.05,0.122-0.05,0.259,0,0.381c0.025,0.062,0.062,0.117,0.109,0.164l2.12,2.12
			c0.098,0.098,0.226,0.146,0.354,0.146s0.256-0.049,0.354-0.146c0.195-0.195,0.195-0.512,0-0.707l-1.268-1.268H33
			c0.276,0,0.5-0.224,0.5-0.5S33.276,31.189,33,31.189z'
      />
    </g>
  )
}

export const IconDrinkingWater = (props: any) => {
  return (
    <path
      {...props}
      d='M26.903,12.553c0.015-0.142-0.03-0.282-0.125-0.388C26.683,12.061,26.548,12,26.406,12H13.261
	c-0.142,0-0.276,0.061-0.372,0.165c-0.095,0.105-0.14,0.246-0.125,0.388l0.857,8.141l1.144,11.433
	c0.025,0.256,0.24,0.45,0.497,0.45h9.145c0.257,0,0.472-0.194,0.497-0.45l1.124-11.251L26.903,12.553z M25.851,13l-0.522,4.968
	c-1.479,0.301-3.35,0.19-5.358-0.375c-2.114-0.595-4.091-0.712-5.713-0.407L13.816,13H25.851z'
    />
  )
}
export const IconDrinkingWaterAnalysis = (props: any) => {
  return (
    <g>
      <circle {...props} cx='22.041' cy='8.694' r='1' />
      <circle {...props} cx='18.349' cy='10.012' r='1' />
      <circle {...props} cx='20.115' cy='5.647' r='1' />
      <path
        {...props}
        d='M24.115,11.938h-8.23c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h0.134v6.958v12.011
		c0,2.223,1.786,4.031,3.981,4.031s3.981-1.809,3.981-4.031V20.448v-7.511h0.134c0.276,0,0.5-0.224,0.5-0.5
		S24.392,11.938,24.115,11.938z M22.981,19.973c-0.18,0.01-0.348,0.026-0.542,0.026c-0.824,0-1.598-0.104-2.3-0.308
		c-0.793-0.229-1.662-0.347-2.582-0.347c-0.193,0-0.358,0.017-0.539,0.026v-6.434h5.963V19.973z'
      />
    </g>
  )
}
export const IconElectric = (props: any) => {
  return (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      {...props}
      d='M34,23h-3v-4h3c0.553,0,1-0.447,1-1s-0.447-1-1-1h-3v-2h-6
	c-2.973,0-5.435,2.165-5.91,5.002V20H8c-0.553,0-1,0.447-1,1s0.447,1,1,1h11.09v-0.002C19.565,24.835,22.027,27,25,27h6v-2h3
	c0.553,0,1-0.447,1-1S34.553,23,34,23z'
    />
  )
}
export const IconEmobility = (props: any) => {
  return (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      {...props}
      d='M35.501,24.225h-1.5v-2h1.5c0.276,0,0.499-0.223,0.499-0.5
	s-0.223-0.5-0.499-0.5h-1.5v-1h-3c-1.487,0-2.718,1.083-2.957,2.501v-0.001H26V20c0-0.554-0.448-1-1-1h-0.375l-2.138-5.701
	c-0.293-0.782-1.039-1.298-1.872-1.298h-0.688h-7.855h-0.686c-0.835,0-1.58,0.516-1.874,1.298L7.378,19H7c-0.552,0-1,0.446-1,1v6
	c0,0.551,0.448,1,1,1h1.002v1.5c0,0.827,0.67,1.499,1.5,1.499c0.829,0,1.498-0.672,1.498-1.499V27h10.001v1.5
	c0,0.827,0.671,1.499,1.499,1.499s1.501-0.672,1.501-1.499V27H25c0.552,0,1-0.449,1-1v-2.275h2.044c0-0.001,0-0.001,0-0.001
	c0.239,1.418,1.47,2.501,2.957,2.501h3v-1h1.5c0.276,0,0.499-0.223,0.499-0.5S35.777,24.225,35.501,24.225z M9.502,23.998
	c-0.83,0-1.5-0.669-1.5-1.499c0-0.827,0.67-1.498,1.5-1.498c0.829,0,1.498,0.671,1.498,1.498C11,23.329,10.331,23.998,9.502,23.998
	z M8.443,19l1.874-5h11.365l1.874,5H8.443z M22.5,23.998c-0.828,0-1.499-0.669-1.499-1.499c0-0.827,0.671-1.498,1.499-1.498
	s1.501,0.671,1.501,1.498C24.001,23.329,23.328,23.998,22.5,23.998z'
    />
  )
}
export const IconEnergyConsulting = (props: any) => {
  return (
    <path
      {...props}
      d='M20.528,10.872c-6.617,0-12,4.203-12,9.369c0,1.96,0.765,3.825,2.217,5.418l-2.132,3.176
	c-0.119,0.178-0.112,0.411,0.018,0.582c0.097,0.126,0.244,0.196,0.397,0.196c0.054,0,0.107-0.009,0.159-0.026l4.658-1.566
	c1.986,1.043,4.292,1.593,6.683,1.593c6.617,0,12-4.204,12-9.372C32.528,15.075,27.146,10.872,20.528,10.872z M27.028,21.243
	c0.276,0,0.5,0.225,0.5,0.501s-0.224,0.5-0.5,0.5h-1.5v0.999h-3.002c-1.483,0-2.714-1.083-2.953-2.501v0.001h-5.544
	c-0.277,0-0.501-0.223-0.501-0.5c0-0.275,0.224-0.499,0.501-0.499h5.544v0.001c0.239-1.419,1.47-2.502,2.953-2.502h3.002v1h1.5
	c0.276,0,0.5,0.224,0.5,0.501c0,0.275-0.224,0.499-0.5,0.499h-1.5v2H27.028z'
    />
  )
}
export const IconEnergySavingTip = (props: any) => {
  return (
    <path
      {...props}
      d='M28.505,11.494c0,0-13.847,2.532-16.971,5.657c-2.761,2.76-3.07,7.031-0.95,10.145
	c-0.99,1.043-1.891,2.148-2.585,3.29L9.413,32c0,0,0.457-1.411,2.032-3.634h0.001c2.855-3.812,6.821-7.032,8.13-8.048
	c0.15-0.114,0.288-0.226,0.443-0.339c-3.965,3.669-6.246,6.925-7.543,9.28c3.129,2.292,7.544,2.032,10.371-0.795
	C25.972,25.341,25.677,14.322,28.505,11.494z'
    />
  )
}
export const IconGas = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M26.583,31.235H13.162c-0.276,0-0.5,0.224-0.5,0.5v1.748c0,0.276,0.224,0.5,0.5,0.5h13.421
		c0.276,0,0.5-0.224,0.5-0.5v-1.748C27.083,31.459,26.859,31.235,26.583,31.235z'
      />
      <path
        {...props}
        d='M19.292,29.323c0.013,0.001,0.025,0.001,0.037,0.001c0.001,0,0.002,0.001,0.003,0.001h1.081l0,0
		c0.014,0,0.026-0.001,0.04-0.002c3.718-0.301,6.63-3.497,6.63-7.277c0-3.868-6.52-13.488-6.797-13.896
		c-0.093-0.137-0.248-0.219-0.413-0.219s-0.32,0.082-0.413,0.219c-0.277,0.407-6.798,10.027-6.798,13.896
		C12.662,25.826,15.574,29.022,19.292,29.323z M19.357,28.325c-2.251-0.23-3.945-1.923-3.945-3.944
		c0-1.723,2.924-6.036,4.461-8.116c1.536,2.08,4.459,6.393,4.459,8.116c0,2.025-1.701,3.719-3.957,3.944H19.357z'
      />
    </g>
  )
}
export const IconHeat = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M11.749,13c-1.062,0-1.934,0.833-1.992,1.938c-0.005,0.028-0.007,0.059-0.007,0.087v12.03
		c0,0.038,0.004,0.075,0.011,0.111C9.848,28.202,10.705,29,11.749,29c1.04,0,1.894-0.788,1.987-1.813
		c0.01-0.041,0.014-0.086,0.014-0.131v-12.03c0-0.005,0-0.009,0-0.014c0-0.004,0-0.007,0-0.011C13.75,13.896,12.854,13,11.749,13z'
      />
      <path
        {...props}
        d='M16.749,13c-1.062,0-1.934,0.833-1.992,1.938c-0.005,0.028-0.007,0.059-0.007,0.087v12.03
		c0,0.038,0.004,0.075,0.011,0.111C14.848,28.202,15.705,29,16.749,29c1.04,0,1.894-0.788,1.987-1.813
		c0.01-0.041,0.014-0.086,0.014-0.131v-12.03c0-0.005,0-0.009,0-0.014c0-0.004,0-0.007,0-0.011C18.75,13.896,17.854,13,16.749,13z'
      />
      <path
        {...props}
        d='M21.749,13c-1.062,0-1.934,0.833-1.992,1.938c-0.005,0.028-0.007,0.059-0.007,0.087v12.03
		c0,0.038,0.004,0.075,0.011,0.111C19.848,28.202,20.705,29,21.749,29c1.04,0,1.894-0.788,1.987-1.813
		c0.01-0.041,0.014-0.086,0.014-0.131v-12.03c0-0.005,0-0.009,0-0.014c0-0.004,0-0.007,0-0.011C23.75,13.896,22.854,13,21.749,13z'
      />
      <path
        {...props}
        d='M26.749,13c-1.062,0-1.934,0.833-1.992,1.938c-0.005,0.028-0.007,0.059-0.007,0.087v12.03
		c0,0.038,0.004,0.075,0.011,0.111C24.848,28.202,25.705,29,26.749,29c1.04,0,1.894-0.788,1.987-1.813
		c0.01-0.041,0.014-0.086,0.014-0.131v-12.03c0-0.005,0-0.009,0-0.014c0-0.004,0-0.007,0-0.011C28.75,13.896,27.854,13,26.749,13z'
      />
      <circle {...props} cx='30.75' cy='14.5' r='1.5' />
    </g>
  )
}
export const IconHouseConnection = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M8.248,17.99c0.235,0.139,0.544,0.059,0.684-0.18L12.913,11h10.433l3.984,6.812
		c0.093,0.158,0.261,0.247,0.432,0.247c0.086,0,0.173-0.022,0.253-0.068c0.238-0.14,0.318-0.445,0.179-0.685l-4.129-7.059
		C23.975,10.094,23.811,10,23.633,10H12.626c-0.178,0-0.342,0.095-0.432,0.248l-4.126,7.059C7.929,17.545,8.01,17.851,8.248,17.99z
		'
      />
      <path
        {...props}
        d='M35.501,28h-1.5v-2h1.5C35.777,26,36,25.777,36,25.5S35.777,25,35.501,25h-1.5v-1h-3
		c-1.487,0-2.718,1.083-2.957,2.501V26.5h-1.913v-9.98c0-0.287-0.225-0.52-0.502-0.52H10.634c-0.277,0-0.503,0.232-0.503,0.52
		V29.48c0,0.287,0.226,0.52,0.503,0.52H11.5v1.5H4c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h8c0.276,0,0.5-0.224,0.5-0.5v-2h1
		v4c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-4h11.129c0.277,0,0.502-0.232,0.502-0.52V27.5h1.913v-0.001
		C28.283,28.917,29.514,30,31.001,30h3v-1h1.5C35.777,29,36,28.777,36,28.5S35.777,28,35.501,28z M17.131,22h-1h-4v-4h4h1V22z
		 M19.131,22v-4h1h4v4h-4H19.131z'
      />
    </g>
  )
}
export const IconLight = (props: any) => {
  return (
    <path
      {...props}
      d='M28.999,16.989c0-0.262-0.014-0.52-0.039-0.774c-0.001-0.017-0.002-0.034-0.004-0.051
	c-0.022-0.218-0.059-0.431-0.099-0.644c-0.018-0.091-0.035-0.182-0.056-0.272c-0.03-0.133-0.065-0.264-0.102-0.395
	c-0.856-3.076-3.499-5.403-6.744-5.791c-0.021-0.003-0.04-0.007-0.061-0.01C21.602,9.019,21.303,9,21,9s-0.602,0.019-0.896,0.053
	c-0.021,0.003-0.04,0.007-0.061,0.01c-3.245,0.388-5.888,2.715-6.744,5.791c-0.036,0.131-0.071,0.262-0.102,0.395
	c-0.021,0.091-0.038,0.182-0.056,0.272c-0.04,0.213-0.076,0.426-0.099,0.644c-0.002,0.017-0.003,0.034-0.004,0.051
	c-0.025,0.255-0.039,0.513-0.039,0.774C13.001,16.993,13,16.996,13,17c0,0.009,0.002,0.017,0.002,0.026
	c0.001,0.31,0.026,0.599,0.061,0.88c0.016,0.122,0.036,0.244,0.058,0.366c0.018,0.099,0.025,0.197,0.047,0.296
	c0.015,0.064,0.04,0.123,0.057,0.186c0.056,0.216,0.114,0.43,0.192,0.634c0.003,0.009,0.006,0.017,0.01,0.025
	c0.647,1.669,1.948,2.994,2.629,4.622c0.08,0.191,0.158,0.383,0.219,0.584c0.008,0.025,0.016,0.05,0.023,0.076
	c0.061,0.21,0.117,0.439,0.17,0.683c0.09,0.384,0.152,0.774,0.217,1.164c0.062,0.383,0.116,0.757,0.16,1.096
	c0.039,0.314,0.082,0.629,0.113,0.942C16.971,28.72,16.986,28.86,17,29v2.5c0,0.276,0.224,0.5,0.5,0.5H20c0,0.553,0.447,1,1,1
	s1-0.447,1-1h2.5c0.276,0,0.5-0.224,0.5-0.5V29c0.014-0.14,0.029-0.28,0.043-0.42c0.031-0.313,0.074-0.627,0.113-0.941
	c0.044-0.339,0.098-0.714,0.16-1.098c0.064-0.39,0.127-0.779,0.217-1.163c0.053-0.243,0.109-0.473,0.17-0.683
	c0.008-0.026,0.016-0.051,0.023-0.076c0.061-0.201,0.139-0.393,0.219-0.584c0.681-1.628,1.981-2.953,2.629-4.622
	c0.004-0.009,0.007-0.017,0.01-0.025c0.078-0.204,0.137-0.418,0.192-0.634c0.017-0.062,0.042-0.121,0.057-0.186
	c0.021-0.099,0.029-0.197,0.047-0.296c0.021-0.12,0.042-0.241,0.058-0.366c0.034-0.281,0.06-0.57,0.061-0.88
	C28.998,17.017,29,17.009,29,17C29,16.996,28.999,16.993,28.999,16.989z M22,28h-2v-9c0-0.552,0.448-1,1-1s1,0.448,1,1V28z
	 M27.998,16.968v0.056c-0.001,0.229-0.018,0.465-0.053,0.759c-0.014,0.107-0.031,0.211-0.05,0.314
	c-0.01,0.055-0.018,0.109-0.025,0.163c-0.004,0.032-0.007,0.064-0.016,0.093c-0.017,0.048-0.031,0.096-0.047,0.151
	c-0.054,0.209-0.101,0.378-0.166,0.548c-0.296,0.764-0.763,1.469-1.256,2.215c-0.491,0.743-0.998,1.511-1.363,2.383
	c-0.087,0.209-0.179,0.435-0.259,0.697l-0.021,0.071c-0.066,0.23-0.129,0.481-0.183,0.732c-0.092,0.391-0.155,0.778-0.218,1.152
	l-0.013,0.077c-0.064,0.396-0.119,0.781-0.165,1.136l-0.03,0.238c-0.011,0.082-0.021,0.164-0.031,0.246H23.5H23v-9
	c0-1.104-0.896-2-2-2s-2,0.896-2,2v9h-0.5h-0.603c-0.011-0.084-0.021-0.167-0.031-0.251l-0.03-0.24
	c-0.046-0.349-0.102-0.733-0.166-1.13l-0.013-0.08c-0.062-0.373-0.126-0.759-0.213-1.133c-0.058-0.267-0.12-0.518-0.188-0.755
	l-0.024-0.08c-0.075-0.247-0.167-0.473-0.254-0.682c-0.365-0.872-0.872-1.64-1.363-2.383c-0.493-0.746-0.96-1.451-1.265-2.236
	c-0.057-0.148-0.104-0.317-0.159-0.533c-0.014-0.049-0.028-0.097-0.046-0.142c-0.008-0.031-0.011-0.063-0.015-0.096
	c-0.008-0.054-0.016-0.108-0.024-0.161c-0.02-0.106-0.037-0.213-0.051-0.313c-0.035-0.297-0.052-0.532-0.053-0.759l-0.001-0.061
	c0.001-0.214,0.013-0.435,0.038-0.699c0.02-0.189,0.052-0.376,0.086-0.557c0.015-0.081,0.03-0.161,0.048-0.239
	c0.027-0.118,0.059-0.234,0.091-0.349c0.763-2.741,3.078-4.729,5.922-5.069l0.034-0.007C20.482,10.016,20.745,10,21,10
	s0.518,0.016,0.758,0.043l0.055,0.01l0.025,0.003c2.82,0.337,5.136,2.325,5.898,5.064c0.032,0.116,0.063,0.232,0.09,0.349
	c0.019,0.08,0.034,0.16,0.049,0.236c0.034,0.186,0.066,0.372,0.086,0.556l0.002,0.038l0.002,0.015
	c0.021,0.218,0.033,0.436,0.034,0.647L27.998,16.968z'
    />
  )
}
export const IconMoveHouse = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M21.215,10.997l3.984,6.812c0.093,0.158,0.261,0.247,0.432,0.247c0.086,0,0.173-0.022,0.253-0.068
		c0.238-0.14,0.318-0.445,0.179-0.685l-4.129-7.059c-0.09-0.153-0.254-0.247-0.432-0.247H10.495c-0.178,0-0.342,0.095-0.432,0.248
		l-4.126,7.059c-0.14,0.238-0.059,0.544,0.18,0.684c0.235,0.139,0.544,0.059,0.684-0.18l3.981-6.811H21.215z'
      />
      <path
        {...props}
        d='M30,19.997c-0.112,0-3.5,0-3.5,0c-0.276,0-0.5,0.225-0.5,0.5v9.501h-2V16.517
		c0-0.287-0.225-0.52-0.502-0.52H8.503C8.226,15.997,8,16.229,8,16.517v13.481v0.5v0.499v0.501c0,0.276,0.224,0.5,0.5,0.5H11v0.001
		c0,1.104,0.896,2,2,2s2-0.896,2-2v-0.001h10.5c0.002,0,0.003-0.001,0.005-0.001H27v0.002c0,1.104,0.896,2,2,2s2-0.896,2-2v-0.002
		h2.5c0.276,0,0.5-0.223,0.5-0.5v-7.332C34,21.866,32.206,19.997,30,19.997z M17,17.997h1h4v4h-4h-1V17.997z M10,17.997h4h1v4h-1
		h-4V17.997z M33,25.497h-3c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5h2.539C32.842,23.005,33,23.576,33,24.165V25.497z'
      />
    </g>
  )
}
export const IconPublicTransport = (props: any) => {
  return (
    <g id='Icons'>
      <path
        {...props}
        d='M28,9.002H12c-1.104,0-2,0.896-2,2v2v10v2v5c0,0.553,0.447,1,1,1h1v1.5c0,0.828,0.672,1.5,1.5,1.5
		s1.5-0.672,1.5-1.5v-1.5h10v1.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-1.5h1c0.553,0,1-0.447,1-1v-5v-2v-10v-2
		C30,9.897,29.104,9.002,28,9.002z M13.5,28.002c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5
		S14.328,28.002,13.5,28.002z M26.5,28.002c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5
		S27.328,28.002,26.5,28.002z M28,23.002H12v-10h16V23.002z'
      />
      <path {...props} d='M30,11.002' />
    </g>
  )
}
export const IconRentersElectricity = (props: any) => {
  return (
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        {...props}
        d='M11.182,17.008c0.154,0,0.309-0.074,0.406-0.211l4.848-6.789
		h12.809l4.848,6.791c0.162,0.223,0.473,0.275,0.699,0.115c0.225-0.16,0.275-0.473,0.115-0.697l-4.998-7
		c-0.094-0.131-0.246-0.209-0.406-0.209H16.178c-0.162,0-0.314,0.078-0.408,0.209l-4.996,7c-0.16,0.225-0.107,0.537,0.117,0.697
		C10.979,16.977,11.08,17.008,11.182,17.008z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        {...props}
        d='M34.34,18.992h-1.5v-3.5c0-0.275-0.223-0.5-0.5-0.5h-19
		c-0.275,0-0.5,0.225-0.5,0.5v7v6.264h-1.884v0.002c-0.239-1.418-1.47-2.502-2.957-2.502h-3v1h-1.5c-0.276,0-0.499,0.223-0.499,0.5
		s0.223,0.5,0.499,0.5h1.5v2h-1.5c-0.276,0-0.499,0.223-0.499,0.5s0.223,0.5,0.499,0.5h1.5v1h3c1.487,0,2.718-1.082,2.957-2.5
		h1.884v2.736c0,0.277,0.225,0.5,0.5,0.5h19c0.277,0,0.5-0.223,0.5-0.5v-9.5h1.5c0.277,0,0.5-0.223,0.5-0.5v-3
		C34.84,19.217,34.617,18.992,34.34,18.992z M29.84,16.992v3h-5v-3H29.84z M20.84,16.992v3h-5v-3H20.84z M13.84,31.992v-9h17.5h0.5
		v9H13.84z M33.84,21.992h-1v-2h1V21.992z'
      />
    </g>
  )
}
export const IconSmartHome = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M33.091,17.793l-4.998-8.546C28.003,9.094,27.839,9,27.661,9H14.336c-0.178,0-0.342,0.095-0.432,0.248
		l-4.995,8.545c-0.14,0.238-0.06,0.544,0.18,0.684c0.079,0.047,0.166,0.068,0.252,0.068c0.171,0,0.339-0.089,0.432-0.248L14.623,10
		h12.751l4.854,8.298c0.14,0.237,0.447,0.315,0.684,0.179C33.15,18.337,33.23,18.031,33.091,17.793z'
      />
      <path
        {...props}
        d='M29.996,16.883h-18.05c-0.276,0-0.5,0.224-0.5,0.5V32.5c0,0.276,0.224,0.5,0.5,0.5h18.05
		c0.276,0,0.5-0.224,0.5-0.5V17.383C30.496,17.106,30.272,16.883,29.996,16.883z M14.489,31.528h-1.044c-0.276,0-0.5-0.224-0.5-0.5
		v-1.043c0-0.276,0.224-0.5,0.5-0.5c0.852,0,1.544,0.692,1.544,1.543C14.989,31.305,14.766,31.528,14.489,31.528z M17.178,31.528
		c-0.276,0-0.5-0.224-0.5-0.5c0-1.834-1.45-3.325-3.232-3.325c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5
		c2.334,0,4.232,1.94,4.232,4.325C17.678,31.305,17.454,31.528,17.178,31.528z M20.053,31.528c-0.276,0-0.5-0.224-0.5-0.5
		c0-3.334-2.74-6.047-6.107-6.047c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5c3.919,0,7.107,3.161,7.107,7.047
		C20.553,31.305,20.329,31.528,20.053,31.528z M22.835,31.528c-0.276,0-0.5-0.224-0.5-0.5c0-4.877-3.988-8.845-8.89-8.845
		c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5c5.453,0,9.89,4.416,9.89,9.845C23.335,31.305,23.111,31.528,22.835,31.528z
		 M25.617,31.528c-0.276,0-0.5-0.224-0.5-0.5c0-6.417-5.236-11.637-11.672-11.637c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5
		c6.987,0,12.672,5.669,12.672,12.637C26.117,31.305,25.894,31.528,25.617,31.528z'
      />
    </g>
  )
}
export const IconSolar = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M20.999,16.017c-2.747,0-4.982,2.235-4.982,4.982c0,2.748,2.235,4.983,4.982,4.983
		c2.748,0,4.983-2.235,4.983-4.983C25.982,18.252,23.747,16.017,20.999,16.017z'
      />
      <path
        {...props}
        d='M20.999,15.073c0.553,0,1-0.447,1-1V9.997c0-0.553-0.447-1-1-1s-1,0.447-1,1v4.076
		C19.999,14.626,20.446,15.073,20.999,15.073z'
      />
      <path
        {...props}
        d='M15.395,16.809c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
		c0.391-0.391,0.391-1.023,0-1.414l-2.883-2.882c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L15.395,16.809z'
      />
      <path
        {...props}
        d='M15.072,20.999c0-0.553-0.447-1-1-1H9.997c-0.553,0-1,0.447-1,1s0.447,1,1,1h4.075
		C14.625,21.999,15.072,21.552,15.072,20.999z'
      />
      <path
        {...props}
        d='M15.395,25.19l-2.883,2.882c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293
		s0.512-0.098,0.707-0.293l2.883-2.882c0.391-0.391,0.391-1.023,0-1.414S15.785,24.8,15.395,25.19z'
      />
      <path
        {...props}
        d='M20.999,26.926c-0.553,0-1,0.447-1,1v4.077c0,0.553,0.447,1,1,1s1-0.447,1-1v-4.077
		C21.999,27.373,21.552,26.926,20.999,26.926z'
      />
      <path
        {...props}
        d='M26.604,25.19c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2.882,2.882
		c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L26.604,25.19z'
      />
      <path
        {...props}
        d='M32.003,19.999h-4.078c-0.553,0-1,0.447-1,1s0.447,1,1,1h4.078c0.553,0,1-0.447,1-1
		S32.556,19.999,32.003,19.999z'
      />
      <path
        {...props}
        d='M25.897,17.102c0.256,0,0.512-0.098,0.707-0.293l2.882-2.882c0.391-0.391,0.391-1.023,0-1.414
		s-1.023-0.391-1.414,0l-2.882,2.882c-0.391,0.391-0.391,1.023,0,1.414C25.386,17.004,25.642,17.102,25.897,17.102z'
      />
    </g>
  )
}
export const IconStart = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M7.754,17.801V33.5h26.492V17.801H7.754z M17.882,24.699h-5.399v-3.07h5.399V24.699z M29.519,24.699
		h-5.399v-3.07h5.399V24.699z'
      />
      <path
        {...props}
        d='M36.5,18.885c-0.16,0-0.318-0.078-0.415-0.221L29.591,9H12.409l-6.494,9.664
		c-0.155,0.23-0.466,0.289-0.694,0.135s-0.29-0.465-0.136-0.693l6.643-9.885C11.82,8.082,11.976,8,12.143,8h17.715
		c0.167,0,0.322,0.082,0.415,0.221l6.643,9.885c0.154,0.229,0.093,0.539-0.136,0.693C36.693,18.857,36.597,18.885,36.5,18.885z'
      />
      <path
        {...props}
        d='M34.246,34H7.754c-0.276,0-0.5-0.223-0.5-0.5V17.801c0-0.275,0.224-0.5,0.5-0.5h26.492
		c0.276,0,0.5,0.225,0.5,0.5V33.5C34.746,33.777,34.522,34,34.246,34z M8.254,33h25.492V18.301H8.254V33z'
      />
      <g>
        <g>
          <path
            {...props}
            d='M18.382,25.699h-6.399c-0.276,0-0.5-0.223-0.5-0.5v-4.07c0-0.275,0.224-0.5,0.5-0.5h6.399
				c0.276,0,0.5,0.225,0.5,0.5v4.07C18.882,25.477,18.658,25.699,18.382,25.699z M12.482,24.699h5.399v-3.07h-5.399V24.699z'
          />
        </g>
        <g>
          <path
            {...props}
            d='M30.019,25.699h-6.399c-0.276,0-0.5-0.223-0.5-0.5v-4.07c0-0.275,0.224-0.5,0.5-0.5h6.399
				c0.276,0,0.5,0.225,0.5,0.5v4.07C30.519,25.477,30.295,25.699,30.019,25.699z M24.119,24.699h5.399v-3.07h-5.399V24.699z'
          />
        </g>
      </g>
    </g>
  )
}
export const IconTelecommunication = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M29.75,30.725c-0.553,0-1-0.447-1-1c0-9.07-7.402-16.449-16.5-16.449c-0.553,0-1-0.447-1-1s0.447-1,1-1
		c10.201,0,18.5,8.276,18.5,18.449C30.75,30.277,30.303,30.725,29.75,30.725z'
      />
      <path
        {...props}
        d='M25.75,30.725c-0.553,0-1-0.447-1-1c0-6.857-5.607-12.436-12.5-12.436c-0.553,0-1-0.447-1-1s0.447-1,1-1
		c7.995,0,14.5,6.476,14.5,14.436C26.75,30.277,26.303,30.725,25.75,30.725z'
      />
      <path
        {...props}
        d='M21.75,30.725c-0.553,0-1-0.447-1-1c0-4.639-3.813-8.413-8.5-8.413c-0.553,0-1-0.447-1-1s0.447-1,1-1
		c5.79,0,10.5,4.671,10.5,10.413C22.75,30.277,22.303,30.725,21.75,30.725z'
      />
      <path
        {...props}
        d='M17.616,30.725c-0.553,0-1-0.447-1-1c0-2.481-1.959-4.5-4.366-4.5c-0.553,0-1-0.447-1-1s0.447-1,1-1
		c3.511,0,6.366,2.916,6.366,6.5C18.616,30.277,18.169,30.725,17.616,30.725z'
      />
      <path
        {...props}
        d='M13.75,30.725h-1.5c-0.553,0-1-0.447-1-1v-1.5c0-0.553,0.447-1,1-1c1.379,0,2.5,1.121,2.5,2.5
		C14.75,30.277,14.303,30.725,13.75,30.725z'
      />
    </g>
  )
}
export const IconThermography = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M31.499,15.021H27v-1.516c0-0.277-0.223-0.5-0.5-0.5h-15c-0.275,0-0.5,0.223-0.5,0.5v11
			c0,0.275,0.225,0.5,0.5,0.5h5.691l1.111,2.223c0.086,0.17,0.258,0.277,0.447,0.277h1.693l2.611,5.219
			c0.086,0.17,0.258,0.277,0.447,0.277H26.5c0.008,0,0.016,0,0.02,0c0.275,0,0.5-0.225,0.5-0.5c0-0.129-0.049-0.246-0.129-0.334
			l-2.691-5.379c0-0.002,0-0.006,0-0.008l-0.889-1.775H26.5c0.277,0,0.5-0.225,0.5-0.5v-1.484h4.499c0.277,0,0.5-0.225,0.5-0.5v-7
			C31.999,15.244,31.776,15.021,31.499,15.021z M27,22.021v-6h3.999v6H27z'
      />
      <path
        {...props}
        d='M8.499,25.002c-0.277,0-0.5-0.225-0.5-0.5v-11c0-0.277,0.223-0.5,0.5-0.5c0.275,0,0.5,0.223,0.5,0.5v11
			C8.999,24.777,8.774,25.002,8.499,25.002z'
      />
    </g>
  )
}
export const IconTrash = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M26.499,16.5c0-0.55,0-1,0-1h-12c0,0,0,0.45,0,1v14c0,0.55,0.45,1,1,1h10c0.55,0,1-0.45,1-1V16.5z'
      />
      <path
        {...props}
        d='M26.499,12.5h-4v-1c0-0.55-0.45-1-1-1h-2c-0.55,0-1,0.45-1,1v1h-4c-0.55,0-1,0.45-1,1s0,1,0,1h6h2h6
		c0,0,0-0.45,0-1S27.049,12.5,26.499,12.5z M19.499,11.5h2v1h-2V11.5z'
      />
    </g>
  )
}
export const IconUsedWater = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M25.999,22v-8c0-1.657-1.343-3-3-3h-8c0-0.55-0.45-1-1-1s-1,0-1,0v8c0,0,0.45,0,1,0s1-0.45,1-1h5v5
		c-0.55,0-1,0.45-1,1s0,1,0,1h8c0,0,0-0.45,0-1S26.549,22,25.999,22z'
      />
      <path
        {...props}
        d='M22.999,26c0,0-2.5,3.861-2.5,5.333c0,1.474,1.119,2.667,2.5,2.667s2.5-1.193,2.5-2.667
		C25.499,29.861,22.999,26,22.999,26z M24.124,32.406c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5
		S24.4,32.406,24.124,32.406z'
      />
    </g>
  )
}
export const IconMore = (props: any) => {
  return (
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M6.83528137,5.65 L14,5.65 C15.1045695,5.65 16,6.5454305 16,7.65 C16,8.7545695 15.1045695,9.65 14,9.65 L6.82157288,9.65 L9.07106781,11.8994949 C9.8521164,12.6805435 9.8521164,13.9468735 9.07106781,14.7279221 C8.29001923,15.5089706 7.02368927,15.5089706 6.24264069,14.7279221 L0.585786438,9.07106781 C0.194393875,8.67967525 -0.000867306006,8.16642227 2.89570317e-06,7.65344023 C9.6549052e-07,7.65229371 1.40463008e-19,7.65114697 0,7.65 C-7.37875221e-17,7.04747916 0.26643418,6.50718735 0.687912669,6.14051446 L6.24264069,0.585786438 C7.02368927,-0.195262146 8.29001923,-0.195262146 9.07106781,0.585786438 C9.8521164,1.36683502 9.8521164,2.63316498 9.07106781,3.41421356 L6.83528137,5.65 Z'
        id='Pfeil_extraContent_ohneSchatten'
        {...props}
        transform='translate(8.000000, 7.656854) scale(-1, 1) translate(-8.000000, -7.656854) '
      />
    </g>
  )
}

export const IconBack = (props: any) => {
  return (
    <g id='Buttons' transform='translate(-254.000000, -357.000000)'>
      <g id='Button_Zurück_ohneSchatten' transform='translate(254.000000, 357.000000)'>
        <path
          id='Combined-Shape'
          {...props}
          d='M12.835,11.65H20c1.104,0,2,0.896,2,2c0,1.104-0.896,2-2,2h-7.178l2.25,2.249
				c0.78,0.781,0.78,2.048,0,2.829c-0.781,0.78-2.047,0.78-2.829,0l-5.657-5.657C6.194,14.68,5.999,14.167,6,13.653
				c0-0.001,0-0.002,0-0.003c0-0.603,0.267-1.143,0.688-1.509l5.555-5.555c0.781-0.781,2.047-0.781,2.829,0
				c0.78,0.781,0.78,2.047,0,2.828L12.835,11.65z'
        />
      </g>
    </g>
  )
}

export const IconClose = (props: any) => {
  return (
    <g id='Page-1'>
      <g id='Buttons' transform='translate(-212.000000, -291.000000)'>
        <g id='Button_Close_ohneSchatten' transform='translate(212.000000, 291.000000)'>
          <path
            id='Combined-Shape'
            {...props}
            d='M11.555,14.5L8.61,11.555c-0.813-0.813-0.813-2.132,0-2.945
				c0.813-0.813,2.132-0.813,2.945,0l2.945,2.945l2.945-2.945c0.812-0.813,2.131-0.813,2.945,0c0.812,0.813,0.812,2.132,0,2.945
				L17.445,14.5l2.945,2.945c0.812,0.812,0.812,2.131,0,2.945c-0.814,0.812-2.133,0.812-2.945,0L14.5,17.445l-2.945,2.945
				c-0.813,0.812-2.132,0.812-2.945,0c-0.813-0.814-0.813-2.133,0-2.945L11.555,14.5z'
          />
        </g>
      </g>
    </g>
  )
}

export const IconContact = (props: any) => {
  return (
    <g id='Buttons' transform='translate(-246.000000, -194.000000)'>
      <g id='Reiter-Kontakt' transform='translate(246.000000, 194.000000)'>
        <path
          d='M13.8269,12.9998 L32.1709,12.9998 L22.9989,22.1728 L13.8269,12.9998 Z M34.999,13 L35,13 L35,27 C35,28.104 34.104,29 33,29 L13,29 C11.895,29 11,28.104 11,27 L11,13.001 L21.667,23.669 C22.035,24.036 22.517,24.221 22.999,24.221 C23.481,24.221 23.964,24.036 24.332,23.668 L34.999,13 Z'
          id='Combined-Shape'
          {...props}
        />
      </g>
    </g>
  )
}

export const IconBurger = (props: any) => {
  return (
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Button_Übersicht_ohneSchatten'>
        <path
          d='M5,0 L21,0 C32.5979797,-2.13051432e-15 42,9.40202025 42,21 L42,21 C42,32.5979797 32.5979797,42 21,42 L21,42 C9.40202025,42 1.42034288e-15,32.5979797 0,21 L-8.8817842e-16,5 C-1.2263553e-15,2.23857625 2.23857625,-3.80913106e-16 5,-8.8817842e-16 Z'
          id='Combined-Shape'
          {...props}
        />
        <path
          d='M11,11 L31,11 C32.1045695,11 33,11.8954305 33,13 C33,14.1045695 32.1045695,15 31,15 L11,15 C9.8954305,15 9,14.1045695 9,13 C9,11.8954305 9.8954305,11 11,11 Z M11,19 L31,19 C32.1045695,19 33,19.8954305 33,21 C33,22.1045695 32.1045695,23 31,23 L11,23 C9.8954305,23 9,22.1045695 9,21 C9,19.8954305 9.8954305,19 11,19 Z M11,27 L31,27 C32.1045695,27 33,27.8954305 33,29 C33,30.1045695 32.1045695,31 31,31 L11,31 C9.8954305,31 9,30.1045695 9,29 C9,27.8954305 9.8954305,27 11,27 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
        />
      </g>
    </g>
  )
}

export const IconPlus = () => {
  return (
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Button_Mehr_ohneSchatten'>
        <rect id='Rectangle-4' x='0' y='0' width='16' height='16' rx='1' />
        <path
          d='M7,7 L7,5 C7,4.44771525 7.44771525,4 8,4 C8.55228475,4 9,4.44771525 9,5 L9,7 L11,7 C11.5522847,7 12,7.44771525 12,8 C12,8.55228475 11.5522847,9 11,9 L9,9 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,9 L5,9 C4.44771525,9 4,8.55228475 4,8 C4,7.44771525 4.44771525,7 5,7 L7,7 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
        />
      </g>
    </g>
  )
}

export const IconMinus = () => {
  return (
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Button_Weniger_ohneSchatten'>
        <rect id='Rectangle-4' x='0' y='0' width='16' height='16' rx='1' />
        <rect id='Combined-Shape' fill='#FFFFFF' x='4' y='7' width='8' height='2' rx='1' />
      </g>
    </g>
  )
}

export const IconUp = (props: any) => {
  return (
    <g id='Buttons' transform='translate(-263.000000, -263.000000)'>
      <g
        id='Button_UpDown'
        transform='translate(277.000000, 277.000000) scale(1, -1) translate(-277.000000, -277.000000) translate(263.000000, 263.000000)'>
        <path
          id='Combined-Shape'
          {...props}
          d='M12.586,19.071l-5.657-5.657c-0.781-0.781-0.781-2.047,0-2.828
				c0.781-0.781,2.047-0.781,2.829,0L14,14.829l4.242-4.243c0.781-0.781,2.048-0.781,2.829,0c0.78,0.781,0.78,2.047,0,2.828
				l-5.657,5.657C14.633,19.852,13.367,19.852,12.586,19.071z'
        />
      </g>
    </g>
  )
}

export const IconRememberOn = (props: any) => {
  return (
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Merkbutton-ON'>
        <g id='Oval-3'>
          <circle stroke='#EEEEEE' fill='#FFFFFF' cx='8' cy='8' r='7.5' />
          <circle {...props} cx='8' cy='8' r='5' />
        </g>
      </g>
    </g>
  )
}

export const IconRememberOff = (props: any) => {
  return (
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Merkbutton-OFF' stroke='#EEEEEE'>
        <g id='Oval-3'>
          <circle fill='#FFFFFF' cx='8' cy='8' r='7.5' />
          <circle {...props} cx='8' cy='8' r='4.5' />
        </g>
      </g>
    </g>
  )
}

export const IconBikes = (props: any) => {
  return (
    <g id='Icons'>
      <path
        {...props}
        d='M29.971,18.762c-0.834,0-1.615,0.217-2.305,0.576l-3.955-5.936c-0.105-0.154-0.285-0.25-0.471-0.25H21
		c-0.311,0-0.559,0.25-0.559,0.562c0,0.309,0.248,0.559,0.559,0.559h1.941l1.494,2.234h-7.617l-1.5-2.234h0.635
		c0.312,0,0.559-0.25,0.559-0.559c0-0.312-0.246-0.562-0.559-0.562h-3.365c-0.305,0-0.557,0.25-0.557,0.562
		c0,0.309,0.252,0.559,0.557,0.559h1.381l1.871,2.799l-1.504,2.266c-0.701-0.359-1.473-0.576-2.305-0.576
		c-2.787,0-5.045,2.258-5.045,5.041c0,2.785,2.258,5.045,5.045,5.045c2.59,0,4.709-1.971,4.982-4.484h3.975c0.004,0,0.004,0,0.012,0
		c0.02,0,0.041,0,0.061-0.008c0.039-0.002,0.076-0.012,0.107-0.02l0,0c0.109-0.037,0.209-0.102,0.277-0.199c0.004,0,0.004,0,0.004,0
		c0.008-0.01,0.01-0.018,0.01-0.023l4.023-6.029l1.25,1.881c-1.098,0.926-1.812,2.291-1.812,3.838c0,2.785,2.268,5.045,5.051,5.045
		c2.779,0,5.043-2.26,5.043-5.045C35.014,21.02,32.75,18.762,29.971,18.762z M12.031,27.73c-2.162,0-3.924-1.766-3.924-3.928
		c0-2.17,1.762-3.922,3.924-3.922c0.602,0,1.168,0.145,1.68,0.391l-2.145,3.223c-0.113,0.17-0.129,0.391-0.029,0.568
		c0.098,0.184,0.283,0.301,0.494,0.301H15.9C15.627,26.258,14.002,27.73,12.031,27.73z M15.9,23.244h-2.82l1.562-2.348
		C15.305,21.494,15.76,22.314,15.9,23.244z M17.014,23.244c-0.141-1.32-0.789-2.473-1.746-3.279l1.244-1.881l3.441,5.16H17.014z
		 M21,22.791l-3.438-5.156h6.873L21,22.791z M29.971,27.73c-2.164,0-3.926-1.766-3.926-3.928c0-1.156,0.508-2.189,1.309-2.906
		l2.15,3.217c0.107,0.164,0.281,0.25,0.467,0.25c0.102,0,0.213-0.033,0.309-0.096c0.254-0.168,0.326-0.521,0.152-0.773l-2.145-3.223
		c0.512-0.246,1.078-0.391,1.684-0.391c2.162,0,3.92,1.752,3.92,3.922C33.891,25.965,32.133,27.73,29.971,27.73z'
      />
    </g>
  )
}

export const IconBikesharing = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <path
          {...props}
          d='M27.4,19.398c-0.596,0-1.15,0.158-1.644,0.415l-2.823-4.235c-0.076-0.11-0.203-0.179-0.334-0.179H21
			c-0.223,0-0.4,0.179-0.4,0.4c0,0.22,0.178,0.4,0.4,0.4h1.388l1.063,1.597h-5.436L16.947,16.2H17.4c0.221,0,0.4-0.181,0.4-0.4
			c0-0.222-0.18-0.4-0.4-0.4h-2.402c-0.219,0-0.398,0.179-0.398,0.4c0,0.22,0.18,0.4,0.398,0.4h0.986l1.334,1.999l-1.075,1.614
			c-0.497-0.257-1.048-0.415-1.644-0.415c-1.986,0-3.6,1.616-3.6,3.604c0,1.982,1.613,3.599,3.6,3.599
			c1.85,0,3.359-1.406,3.559-3.198h2.834c0.004,0,0.004,0,0.008,0c0.014,0,0.029,0,0.045-0.005c0.027-0.005,0.053-0.013,0.076-0.017
			l0,0c0.078-0.025,0.15-0.074,0.197-0.143c0.004,0,0.004,0,0.004,0c0.002-0.007,0.006-0.014,0.01-0.017l2.867-4.302l0.893,1.338
			c-0.785,0.662-1.293,1.639-1.293,2.744c0,1.982,1.615,3.599,3.602,3.599s3.6-1.616,3.6-3.599C31,21.015,29.387,19.398,27.4,19.398
			z M14.6,25.8c-1.543,0-2.799-1.257-2.799-2.798c0-1.551,1.256-2.803,2.799-2.803c0.43,0,0.833,0.105,1.199,0.281l-1.531,2.297
			c-0.082,0.122-0.09,0.281-0.018,0.408c0.068,0.134,0.201,0.217,0.35,0.217h2.762C17.164,24.752,16.006,25.8,14.6,25.8z
			 M17.361,22.6H15.35l1.113-1.676C16.938,21.352,17.264,21.938,17.361,22.6z M18.158,22.6c-0.105-0.94-0.565-1.761-1.249-2.342
			l0.892-1.338l2.451,3.68H18.158z M21,22.277l-2.453-3.68h4.904L21,22.277z M27.4,25.8c-1.543,0-2.801-1.257-2.801-2.798
			c0-0.825,0.365-1.564,0.934-2.078l1.533,2.298c0.08,0.117,0.202,0.181,0.334,0.181c0.074,0,0.151-0.024,0.221-0.07
			c0.184-0.12,0.234-0.371,0.111-0.555l-1.531-2.297c0.366-0.176,0.77-0.281,1.199-0.281c1.543,0,2.799,1.252,2.799,2.803
			C30.199,24.543,28.943,25.8,27.4,25.8z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M7.904,23.107l0.943-1.884c0.077-0.155,0.069-0.339-0.021-0.486C8.734,20.59,8.573,20.5,8.4,20.5H7.982
			C8.249,13.564,13.973,8,20.99,8c5.769,0,10.917,3.854,12.52,9.374c0.077,0.265,0.352,0.419,0.62,0.341
			c0.265-0.077,0.418-0.354,0.341-0.62C32.744,11.151,27.2,7,20.99,7C13.421,7,7.249,13.013,6.982,20.5H6.512
			c-0.174,0-0.334,0.09-0.426,0.237c-0.091,0.147-0.099,0.332-0.021,0.487l0.945,1.884c0.085,0.169,0.258,0.275,0.447,0.275
			S7.819,23.277,7.904,23.107z'
        />
        <path
          {...props}
          d='M35.86,20.66l-0.89-1.773c-0.085-0.17-0.258-0.276-0.447-0.276s-0.362,0.107-0.447,0.276l-0.943,1.885
			c-0.077,0.154-0.069,0.339,0.021,0.486c0.092,0.147,0.252,0.237,0.426,0.237h0.418C33.734,28.433,28.009,34,20.99,34
			c-5.757,0-10.901-3.844-12.513-9.349c-0.077-0.264-0.353-0.417-0.62-0.339c-0.265,0.077-0.417,0.354-0.339,0.62
			C9.253,30.86,14.793,35,20.99,35c7.57,0,13.743-6.016,14.008-13.505h0.471c0.008,0,0.016,0,0.02,0c0.276,0,0.5-0.224,0.5-0.5
			C35.988,20.866,35.94,20.749,35.86,20.66z'
        />
      </g>
    </g>
  )
}

export const IconEBikes = (props: any) => {
  return (
    <g id='Icons'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        {...props}
        d='M31.437,19.197c-0.671,0-1.301,0.175-1.856,0.464l-3.186-4.781
      c-0.085-0.124-0.229-0.201-0.379-0.201h-1.805c-0.25,0-0.45,0.201-0.45,0.453c0,0.249,0.2,0.45,0.45,0.45h1.563l1.204,1.8h-6.136
      l-1.208-1.8h0.511c0.252,0,0.45-0.201,0.45-0.45c0-0.252-0.198-0.453-0.45-0.453h-2.71c-0.246,0-0.449,0.201-0.449,0.453
      c0,0.249,0.203,0.45,0.449,0.45h1.111l1.203,1.799h-6.046v0.002c-0.212-1.284-1.33-2.267-2.675-2.267H8.312v0.906H6.954
      c-0.249,0-0.453,0.203-0.453,0.453c0,0.249,0.204,0.451,0.453,0.451h1.357v1.812H6.954c-0.249,0-0.453,0.202-0.453,0.452
      c0,0.251,0.204,0.453,0.453,0.453h1.357v0.905h2.718c1.345,0,2.463-0.979,2.675-2.265h6.052l-0.913,1.376
      c-0.564-0.289-1.186-0.464-1.856-0.464c-2.245,0-4.063,1.818-4.063,4.061c0,2.243,1.818,4.063,4.063,4.063
      c2.086,0,3.793-1.587,4.014-3.612h3.201c0.004,0,0.004,0,0.01,0c0.016,0,0.033,0,0.049-0.006c0.031-0.002,0.062-0.01,0.087-0.016
      c0.088-0.03,0.168-0.082,0.223-0.161c0.003,0,0.003,0,0.003,0c0.007-0.008,0.009-0.014,0.009-0.019l3.24-4.856l1.007,1.515
      c-0.884,0.746-1.46,1.846-1.46,3.092c0,2.243,1.827,4.063,4.068,4.063c2.239,0,4.062-1.82,4.062-4.063
      C35.499,21.016,33.676,19.197,31.437,19.197z M16.986,26.422c-1.741,0-3.16-1.423-3.16-3.164c0-1.748,1.419-3.159,3.16-3.159
      c0.484,0,0.941,0.116,1.354,0.314l-1.728,2.596c-0.091,0.137-0.104,0.314-0.023,0.458c0.078,0.148,0.228,0.242,0.397,0.242h3.117
      C19.883,25.235,18.574,26.422,16.986,26.422z M17.831,22.808l1.259-1.891c0.533,0.481,0.9,1.142,1.014,1.891H17.831z M21,22.808
      c-0.113-1.063-0.636-1.991-1.406-2.642l1.002-1.515l2.771,4.156H21z M24.211,22.442l-2.769-4.153h5.536L24.211,22.442z
      M31.437,26.422c-1.743,0-3.162-1.423-3.162-3.164c0-0.932,0.409-1.764,1.055-2.341l1.731,2.591
      c0.087,0.132,0.227,0.201,0.376,0.201c0.082,0,0.172-0.026,0.249-0.077c0.204-0.135,0.263-0.42,0.123-0.623l-1.728-2.596
      c0.412-0.198,0.868-0.314,1.355-0.314c1.742,0,3.158,1.411,3.158,3.159C34.595,24.999,33.179,26.422,31.437,26.422z'
      />
    </g>
  )
}

export const IconEnergieausweis = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <path
          {...props}
          d='M25.89,13.657l-3.5-2.572C22.315,11.03,22.223,11,22.128,11h-2.625c-0.242,0-0.438,0.191-0.438,0.429v5.143
			c0,0.237,0.195,0.429,0.438,0.429h2.625c0.095,0,0.188-0.03,0.262-0.085l3.5-2.572c0.111-0.081,0.176-0.208,0.176-0.343
			S26.001,13.738,25.89,13.657z'
        />
        <path
          {...props}
          d='M29.882,20.657l-3.667-2.572C26.137,18.03,26.04,18,25.94,18h-6.417c-0.253,0-0.458,0.191-0.458,0.429v5.143
			c0,0.237,0.205,0.429,0.458,0.429h6.417c0.1,0,0.196-0.03,0.274-0.085l3.667-2.572c0.115-0.081,0.184-0.208,0.184-0.343
			S29.997,20.738,29.882,20.657z'
        />
        <path
          {...props}
          d='M33.878,27.657l-3.75-2.572C30.047,25.03,29.948,25,29.847,25H19.534c-0.259,0-0.469,0.191-0.469,0.429v5.143
			c0,0.237,0.21,0.429,0.469,0.429h10.312c0.102,0,0.2-0.03,0.281-0.085l3.75-2.572c0.118-0.081,0.188-0.208,0.188-0.343
			S33.996,27.738,33.878,27.657z'
        />
        <path
          {...props}
          d='M12.561,11c-0.178,0-0.342,0.095-0.432,0.248l-4.126,7.059c-0.14,0.238-0.059,0.544,0.18,0.684
			c0.235,0.139,0.544,0.059,0.684-0.18L12.848,12h4.218v5h-6.497c-0.277,0-0.503,0.232-0.503,0.52V30.48
			c0,0.287,0.226,0.52,0.503,0.52h7.497V11H12.561z M17.065,30h-6V18h6V30z'
        />
      </g>
    </g>
  )
}

export const IconEnergiesparverordnung = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <path
          {...props}
          d='M15.504,21c0-1.379-1.121-2.5-2.5-2.5c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5
			c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5c0-1.379-1.121-2.5-2.5-2.5s-2.5,1.121-2.5,2.5c0,0.821,0.402,1.544,1.016,2
			c-0.613,0.456-1.016,1.179-1.016,2c0,1.379,1.121,2.5,2.5,2.5c0.827,0,1.5,0.673,1.5,1.5s-0.673,1.5-1.5,1.5s-1.5-0.673-1.5-1.5
			c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5c0,1.379,1.121,2.5,2.5,2.5s2.5-1.121,2.5-2.5c0-0.821-0.402-1.544-1.016-2
			C15.102,22.544,15.504,21.821,15.504,21z M13.004,22.5c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5
			S13.831,22.5,13.004,22.5z'
        />
        <g>
          <path
            {...props}
            d='M26.824,13.657l-3.5-2.572C23.25,11.03,23.157,11,23.062,11h-2.616c-0.242,0-0.438,0.191-0.438,0.429v5.143
				c0,0.237,0.195,0.429,0.438,0.429h2.616c0.095,0,0.188-0.03,0.262-0.085l3.5-2.572C26.936,14.262,27,14.135,27,14
				S26.936,13.738,26.824,13.657z'
          />
          <path
            {...props}
            d='M30.816,20.657l-3.667-2.572C27.071,18.03,26.975,18,26.875,18h-6.408c-0.253,0-0.458,0.191-0.458,0.429v5.143
				c0,0.237,0.205,0.429,0.458,0.429h6.408c0.1,0,0.196-0.03,0.274-0.085l3.667-2.572C30.932,21.262,31,21.135,31,21
				S30.932,20.738,30.816,20.657z'
          />
          <path
            {...props}
            d='M34.812,27.657l-3.75-2.572C30.981,25.03,30.883,25,30.781,25H20.478c-0.259,0-0.469,0.191-0.469,0.429v5.143
				c0,0.237,0.21,0.429,0.469,0.429h10.304c0.102,0,0.2-0.03,0.281-0.085l3.75-2.572C34.931,28.262,35,28.135,35,28
				S34.931,27.738,34.812,27.657z'
          />
        </g>
        <path
          {...props}
          d='M18.508,31H7.5C7.224,31,7,30.776,7,30.5v-19C7,11.224,7.224,11,7.5,11h11.008c0.276,0,0.5,0.224,0.5,0.5v19
			C19.008,30.776,18.784,31,18.508,31z M8,30h10.008V12H8V30z'
        />
      </g>
    </g>
  )
}

export const IconFoerderung = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <path
          {...props}
          d='M24.517,8.571c-3.867,0-7,3.133-7,7s3.133,7,7,7s7-3.133,7-7S28.384,8.571,24.517,8.571z M25.515,14.073
			c0.277,0,0.5,0.225,0.5,0.5c0,0.277-0.223,0.5-0.5,0.5h-3.451c-0.025,0.162-0.049,0.328-0.049,0.498
			c0,0.172,0.023,0.336,0.049,0.5h3.451c0.277,0,0.5,0.225,0.5,0.5c0,0.277-0.223,0.5-0.5,0.5h-3.096
			c0.504,0.893,1.428,1.5,2.496,1.5h1.602c0.275,0,0.5,0.223,0.5,0.5c0,0.275-0.225,0.5-0.5,0.5h-1.602
			c-1.633,0-3.029-1.037-3.609-2.5h-0.791c-0.277,0-0.5-0.223-0.5-0.5c0-0.275,0.223-0.5,0.5-0.5h0.549
			c-0.02-0.166-0.049-0.328-0.049-0.5c0-0.17,0.029-0.332,0.049-0.498h-0.549c-0.277,0-0.5-0.223-0.5-0.5c0-0.275,0.223-0.5,0.5-0.5
			h0.789c0.58-1.465,1.977-2.502,3.611-2.502h1.602c0.275,0,0.5,0.223,0.5,0.5c0,0.275-0.225,0.5-0.5,0.5h-1.602
			c-1.07,0-1.996,0.609-2.498,1.502H25.515z'
        />
        <path
          {...props}
          d='M32.419,26.745c-0.004,0.001-0.008,0.001-0.014,0.001c-0.016,0.002-0.031,0.003-0.047,0.005
			c-0.027,0.003-0.053,0.008-0.078,0.013l-8.568,1.633l-2.508-1.148l3.832-0.74c0.5-0.097,0.848-0.553,0.809-1.06
			s-0.453-0.904-0.963-0.923L14.259,24.15c-0.037-0.001-0.074,0-0.111,0.003l-1.646,0.128c-0.033-0.521-0.457-0.938-0.986-0.938
			H9.517c-0.553,0-1,0.448-1,1v6.002c0,0.552,0.447,1,1,1h1.998c0.551,0,1-0.448,1-1v-0.048l1.887-0.146l9.082,3.22
			c0.135,0.048,0.273,0.065,0.412,0.055s0.273-0.05,0.398-0.117l8.639-4.672c0.354-0.178,0.58-0.554,0.549-0.971
			C33.438,27.119,32.966,26.709,32.419,26.745z M9.517,30.346v-6.002h1.998v0.014v5.988H9.517z M23.817,32.429l-9.283-3.291
			l-2.02,0.157v-4.012l1.711-0.133l10.621,0.375l-8.408,1.626c-0.271,0.052-0.447,0.313-0.396,0.585
			c0.053,0.271,0.314,0.449,0.584,0.396l2.9-0.554l4.059,1.859l8.896-1.695L23.817,32.429z'
        />
      </g>
    </g>
  )
}

export const IconOekostrom = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          {...props}
          d='M34,27.427h-3v-4h3c0.553,0,1-0.447,1-1s-0.447-1-1-1h-3v-2h-6
			c-2.973,0-5.436,2.164-5.91,5.002v-0.002H8c-0.553,0-1,0.447-1,1s0.447,1,1,1h11.09v-0.002c0.475,2.836,2.938,5.002,5.91,5.002h6
			v-2h3c0.553,0,1-0.447,1-1S34.553,27.427,34,27.427z'
        />
        <path
          {...props}
          d='M20.925,10.573c0,0-8.675,2.742-10.429,4.998c-1.547,1.992-1.4,4.758,0.21,6.584
			c-0.551,0.748-1.039,1.531-1.393,2.318l1.022,0.793c0,0,0.179-0.941,1.008-2.494l0.003-0.002c1.523-2.674,3.808-5.059,4.565-5.816
			c0.086-0.086,0.166-0.168,0.256-0.254c-2.249,2.674-3.448,4.947-4.09,6.561c2.193,1.217,5.004,0.695,6.588-1.346
			C20.417,19.661,19.34,12.616,20.925,10.573z'
        />
      </g>
    </g>
  )
}

export const IconOnlineKundensupport = (props: any) => {
  return (
    <g id='Icons'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        {...props}
        d='M33,28.336V15.334c0-0.553-0.447-1-1-1H10c-0.553,0-1,0.447-1,1
		v13.002H7v0.998c0,0.553,0.447,1,1,1h2h7h8h7h2c0.553,0,1-0.447,1-1v-0.998H33z M10,28.336V15.334h22v13.002H10z'
      />
      <g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          {...props}
          d='M14,22.832c0,1.105,0.895,2,2,2v-1.998c0-2.757,2.243-5,5-5
			s5,2.243,5,5v1.998c1.105,0,2-0.895,2-2c0-0.857-0.543-1.584-1.303-1.868c-0.787-2.395-3.042-4.13-5.697-4.13
			s-4.91,1.735-5.697,4.13C14.543,21.248,14,21.975,14,22.832'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          {...props}
          d='M23.406,24.832c-0.17,0.296-0.473,0.5-0.84,0.5
			c-0.553,0-1-0.447-1-1s0.447-1,1-1c0.367,0,0.67,0.204,0.84,0.5h1.44C24.935,23.512,25,23.183,25,22.834c0-2.209-1.791-4-4-4
			s-4,1.791-4,4s1.791,4,4,4c1.477,0,2.746-0.812,3.438-2.002H23.406z'
        />
      </g>
    </g>
  )
}

export const IconErklaervideos = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <path
          {...props}
          d='M32.5,11h-23C9.225,11,9,11.224,9,11.5v19C9,30.776,9.225,31,9.5,31h23c0.277,0,0.5-0.224,0.5-0.5v-19
			C33,11.224,32.777,11,32.5,11z M29.498,12.5c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5v1c0,0.276-0.223,0.5-0.5,0.5
			h-1c-0.275,0-0.5-0.224-0.5-0.5V12.5z M25.699,12.5c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5v1
			c0,0.276-0.223,0.5-0.5,0.5h-1c-0.275,0-0.5-0.224-0.5-0.5V12.5z M21.9,12.5c0-0.276,0.223-0.5,0.5-0.5h1
			c0.275,0,0.5,0.224,0.5,0.5v1c0,0.276-0.225,0.5-0.5,0.5h-1c-0.277,0-0.5-0.224-0.5-0.5V12.5z M18.102,12.5
			c0-0.276,0.223-0.5,0.5-0.5h1c0.275,0,0.5,0.224,0.5,0.5v1c0,0.276-0.225,0.5-0.5,0.5h-1c-0.277,0-0.5-0.224-0.5-0.5V12.5z
			 M14.301,12.5c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5v1c0,0.276-0.223,0.5-0.5,0.5h-1c-0.275,0-0.5-0.224-0.5-0.5
			V12.5z M10.502,12.5c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5v1c0,0.276-0.223,0.5-0.5,0.5h-1
			c-0.275,0-0.5-0.224-0.5-0.5V12.5z M12.502,29.5c0,0.276-0.223,0.5-0.5,0.5h-1c-0.275,0-0.5-0.224-0.5-0.5v-1
			c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5V29.5z M16.301,29.5c0,0.276-0.223,0.5-0.5,0.5h-1
			c-0.275,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5V29.5z M20.102,29.5
			c0,0.276-0.225,0.5-0.5,0.5h-1c-0.277,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.223-0.5,0.5-0.5h1c0.275,0,0.5,0.224,0.5,0.5V29.5z
			 M23.9,29.5c0,0.276-0.225,0.5-0.5,0.5h-1c-0.277,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.223-0.5,0.5-0.5h1c0.275,0,0.5,0.224,0.5,0.5
			V29.5z M27.699,29.5c0,0.276-0.223,0.5-0.5,0.5h-1c-0.275,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.225-0.5,0.5-0.5h1
			c0.277,0,0.5,0.224,0.5,0.5V29.5z M31.498,29.5c0,0.276-0.223,0.5-0.5,0.5h-1c-0.275,0-0.5-0.224-0.5-0.5v-1
			c0-0.276,0.225-0.5,0.5-0.5h1c0.277,0,0.5,0.224,0.5,0.5V29.5z M32,27H10V15h22V27z'
        />
        <path
          {...props}
          d='M23.266,20.576l-4-2.5c-0.152-0.097-0.348-0.102-0.508-0.014C18.6,18.15,18.5,18.318,18.5,18.5v5
			c0,0.182,0.1,0.35,0.258,0.438C18.834,23.979,18.918,24,19,24c0.092,0,0.186-0.025,0.266-0.076l4-2.5
			C23.412,21.333,23.5,21.173,23.5,21S23.412,20.667,23.266,20.576z'
        />
      </g>
    </g>
  )
}

export const IconRegionalstrom = (props: any) => {
  return (
    <g id='Icons'>
      <g>
        <g>
          <circle {...props} cx='17.688' cy='19' r='1' />
          <path
            {...props}
            d='M19.208,17c-0.006,0-0.014,0.001-0.02,0h-3c-0.145,0-0.281-0.062-0.377-0.171
				c-0.096-0.109-0.139-0.254-0.119-0.396l1.5-11C17.226,5.185,17.438,5,17.688,5s0.461,0.185,0.494,0.433l1.482,10.864
				c0.027,0.062,0.043,0.131,0.043,0.203C19.708,16.776,19.483,17,19.208,17z M16.761,16h1.854l-0.926-6.8L16.761,16z'
          />
          <path
            {...props}
            d='M5.997,26.25c-0.174,0-0.342-0.091-0.434-0.25c-0.125-0.217-0.07-0.492,0.127-0.646l8.775-6.8
				c0.115-0.089,0.26-0.126,0.404-0.095c0.141,0.027,0.264,0.115,0.336,0.24l1.5,2.599c0.072,0.125,0.086,0.275,0.039,0.412
				c-0.045,0.137-0.148,0.246-0.283,0.301L6.187,26.213C6.124,26.238,6.06,26.25,5.997,26.25z M14.626,19.696L9.2,23.899
				l6.354-2.597L14.626,19.696z'
          />
          <path
            {...props}
            d='M29.38,26.25c-0.064,0-0.129-0.012-0.189-0.037l-10.277-4.201c-0.135-0.055-0.238-0.164-0.283-0.301
				c-0.047-0.137-0.033-0.287,0.039-0.412l1.5-2.599c0.072-0.125,0.195-0.213,0.336-0.24c0.145-0.029,0.289,0.007,0.404,0.095
				l8.701,6.742c0.172,0.079,0.289,0.253,0.289,0.453c0,0.276-0.225,0.5-0.5,0.5C29.394,26.249,29.386,26.249,29.38,26.25z
				 M19.821,21.303l6.354,2.597l-5.426-4.203L19.821,21.303z'
          />
          <path
            {...props}
            d='M17.688,37c-0.277,0-0.5-0.224-0.5-0.5V23.502c0-0.276,0.223-0.5,0.5-0.5c0.275,0,0.5,0.224,0.5,0.5V36.5
				C18.188,36.776,17.964,37,17.688,37z'
          />
        </g>
        <g>
          <g>
            <path
              {...props}
              d='M28.521,8.542c-1.367,0-2.48,1.113-2.48,2.48c0,1.368,1.113,2.481,2.48,2.481
					c1.369,0,2.482-1.113,2.482-2.481C31.003,9.655,29.89,8.542,28.521,8.542z'
            />
            <path
              {...props}
              d='M28.521,8.071c0.275,0,0.498-0.224,0.498-0.499V5.545c0-0.277-0.223-0.499-0.498-0.499
					c-0.273,0-0.498,0.222-0.498,0.499v2.027C28.022,7.848,28.247,8.071,28.521,8.071z'
            />
            <path
              {...props}
              d='M25.729,8.934c0.098,0.097,0.227,0.146,0.354,0.146s0.254-0.049,0.35-0.146
					c0.195-0.191,0.195-0.507,0-0.702l-1.434-1.435c-0.195-0.194-0.508-0.194-0.705,0c-0.195,0.195-0.195,0.508,0,0.705
					L25.729,8.934z'
            />
            <path
              {...props}
              d='M25.569,11.022c0-0.273-0.223-0.497-0.5-0.497h-2.025c-0.277,0-0.5,0.224-0.5,0.497
					c0,0.274,0.223,0.498,0.5,0.498h2.025C25.347,11.521,25.569,11.297,25.569,11.022z'
            />
            <path
              {...props}
              d='M25.729,13.111l-1.436,1.433c-0.195,0.196-0.195,0.508,0,0.706c0.096,0.099,0.225,0.145,0.354,0.145
					c0.127,0,0.254-0.046,0.352-0.145l1.434-1.435c0.195-0.196,0.195-0.511,0-0.704C26.241,12.916,25.925,12.916,25.729,13.111z'
            />
            <path
              {...props}
              d='M28.521,13.975c-0.273,0-0.498,0.223-0.498,0.498V16.5c0,0.278,0.225,0.5,0.498,0.5
					c0.275,0,0.498-0.222,0.498-0.5v-2.027C29.019,14.197,28.796,13.975,28.521,13.975z'
            />
            <path
              {...props}
              d='M31.313,13.111c-0.195-0.195-0.51-0.195-0.705,0c-0.195,0.193-0.195,0.508,0,0.704l1.436,1.435
					c0.1,0.099,0.223,0.145,0.35,0.145c0.129,0,0.258-0.046,0.354-0.145c0.193-0.198,0.193-0.51,0-0.706L31.313,13.111z'
            />
            <path
              {...props}
              d='M33.997,10.525h-2.025c-0.279,0-0.5,0.224-0.5,0.497c0,0.274,0.221,0.498,0.5,0.498h2.025
					c0.279,0,0.502-0.224,0.502-0.498C34.499,10.749,34.276,10.525,33.997,10.525z'
            />
            <path
              {...props}
              d='M30.96,9.079c0.127,0,0.254-0.049,0.354-0.146l1.434-1.432c0.193-0.197,0.193-0.51,0-0.705
					c-0.197-0.194-0.508-0.194-0.703,0l-1.436,1.435c-0.195,0.195-0.195,0.511,0,0.702C30.706,9.03,30.833,9.079,30.96,9.079z'
            />
          </g>
        </g>
      </g>
    </g>
  )
}

export const IconAbwasser = (props: any) => {
  return (
    <g>
      <rect x='5.049' y='11.186' {...props} width='12.251' height='8.723' />
      <g>
        <path
          {...props}
          d='M19.496,21.096c0,0.55-0.45,1-1,1H18.3c-0.55,0-1-0.45-1-1V10c0-0.55,0.45-1,1-1h0.197
			c0.55,0,1,0.45,1,1V21.096z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M24.462,21.959c-0.155,0-0.281-0.126-0.281-0.281v-1.605c0-1.542-1.358-1.662-1.775-1.662h-1.718
			c-0.155,0-0.281-0.126-0.281-0.281s0.126-0.281,0.281-0.281h1.718c1.078,0,2.337,0.583,2.337,2.224v1.605
			C24.743,21.833,24.617,21.959,24.462,21.959z'
        />
        <path
          {...props}
          d='M24.462,25.226c-0.155,0-0.281-0.126-0.281-0.281v-1.746c0-0.155,0.126-0.281,0.281-0.281
			c0.155,0,0.281,0.126,0.281,0.281v1.746C24.743,25.1,24.617,25.226,24.462,25.226z'
        />
        <path
          {...props}
          d='M26.63,26.606c-0.155,0-0.281-0.126-0.281-0.281v-5.909c0-0.155,0.126-0.281,0.281-0.281
			c0.155,0,0.281,0.126,0.281,0.281v5.909C26.912,26.48,26.786,26.606,26.63,26.606z'
        />
        <path
          {...props}
          d='M28.714,25.535c-0.155,0-0.281-0.126-0.281-0.281v-4.14c0-0.155,0.126-0.281,0.281-0.281
			s0.281,0.126,0.281,0.281v4.14C28.996,25.41,28.87,25.535,28.714,25.535z'
        />
        <path
          {...props}
          d='M22.434,13.848h-1.746c-0.155,0-0.281-0.126-0.281-0.281s0.126-0.281,0.281-0.281h1.746
			c0.155,0,0.281,0.126,0.281,0.281S22.589,13.848,22.434,13.848z'
        />
        <path
          {...props}
          d='M22.11,16.016h-1.422c-0.155,0-0.281-0.126-0.281-0.281s0.126-0.281,0.281-0.281h1.422
			c0.155,0,0.281,0.126,0.281,0.281S22.265,16.016,22.11,16.016z'
        />
        <path
          {...props}
          d='M26.631,19.621c-0.006,0-0.011,0-0.017-0.001c-0.155-0.009-0.273-0.143-0.264-0.298
			c0.064-1.072-0.19-1.9-0.756-2.462c-0.944-0.937-2.45-0.846-2.465-0.845c-0.154,0.012-0.289-0.107-0.3-0.262
			c-0.01-0.155,0.107-0.289,0.262-0.3c0.072-0.005,1.777-0.106,2.899,1.007c0.686,0.681,0.996,1.655,0.921,2.895
			C26.902,19.506,26.778,19.621,26.631,19.621z'
        />
        <path
          {...props}
          d='M28.714,19.044c-0.155,0-0.281-0.126-0.281-0.281c0-3.628-2.28-4.915-4.232-4.915H23.56
			c-0.155,0-0.281-0.126-0.281-0.281s0.126-0.281,0.281-0.281h0.641c2.386,0,4.794,1.694,4.794,5.477
			C28.996,18.918,28.87,19.044,28.714,19.044z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M20.052,30.867c-0.071,0-0.141-0.002-0.212-0.006c-1.964-0.108-3.239-1.673-3.293-1.739
			c-0.097-0.121-0.078-0.298,0.044-0.395c0.121-0.097,0.298-0.078,0.395,0.043c0.014,0.017,1.181,1.439,2.89,1.53
			c1.124,0.06,2.248-0.462,3.338-1.553c0.107-0.107,0.281-0.11,0.391-0.006c0.133,0.124,3.305,3.017,6.573-0.002
			c0.107-0.099,0.272-0.1,0.38-0.002c0.128,0.115,3.176,2.801,6.21,0.002c0.114-0.105,0.292-0.098,0.397,0.016
			c0.105,0.114,0.098,0.292-0.016,0.397c-1.785,1.647-3.592,1.558-4.793,1.192c-0.942-0.286-1.654-0.775-1.981-1.029
			c-1.848,1.594-3.667,1.492-4.884,1.113c-0.987-0.307-1.727-0.831-2.067-1.103C22.338,30.349,21.205,30.867,20.052,30.867z'
        />
        <path
          {...props}
          d='M20.052,34c-0.071,0-0.141-0.002-0.212-0.006c-1.964-0.108-3.239-1.673-3.293-1.739
			c-0.097-0.121-0.078-0.298,0.044-0.395c0.121-0.097,0.298-0.078,0.395,0.043c0.014,0.017,1.181,1.439,2.89,1.53
			c1.124,0.061,2.248-0.462,3.338-1.553c0.107-0.108,0.281-0.11,0.391-0.006c0.133,0.124,3.305,3.017,6.573-0.002
			c0.107-0.099,0.272-0.1,0.38-0.002c0.128,0.115,3.176,2.801,6.21,0.002c0.114-0.105,0.292-0.098,0.397,0.016
			c0.105,0.114,0.098,0.292-0.016,0.397c-1.785,1.647-3.592,1.558-4.793,1.192c-0.942-0.286-1.654-0.775-1.981-1.029
			c-1.848,1.594-3.667,1.492-4.884,1.113c-0.987-0.307-1.727-0.831-2.067-1.103C22.338,33.482,21.205,34,20.052,34z'
        />
      </g>
    </g>
  )
}

export const IconAlarmanlage = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M21.2,3.4c-2,0-5.5,1.4-5.5,5.3c0,3.4,0,3.6,0,3.6h5.5h5.5c0,0,0-0.2,0-3.6
	C26.7,4.8,23.3,3.4,21.2,3.4z'
      />
      <g>
        <g>
          <path
            {...props}
            d='M14.2,14.6c0,0,0-0.3,0-0.7s0.6-0.7,1.4-0.7h11.2c0.8,0,1.4,0.3,1.4,0.7s0,0.7,0,0.7'
          />
        </g>
        <rect x='14.2' y='14.1' {...props} width='14.1' height='1.3' />
      </g>
      <g>
        <g>
          <path
            {...props}
            d='M13.5,16.3c-1.6,0-2.8,1.3-2.8,2.8v15.8c0,1.6,1.3,2.8,2.8,2.8h15.3c1.6,0,2.8-1.3,2.8-2.8V19.1
			c0-1.6-1.3-2.8-2.8-2.8H13.5z M29.5,30.9c0,0.4-1.3,0.7-2.8,0.7H15.8c-1.6,0-2.8-0.3-2.8-0.7c0-0.4,1.3-0.7,2.8-0.7h10.8
			C28.2,30.1,29.5,30.5,29.5,30.9z M29.5,27.2c0,0.4-1.3,0.7-2.8,0.7H15.8c-1.6,0-2.8-0.3-2.8-0.7c0-0.4,1.3-0.7,2.8-0.7h10.8
			C28.2,26.5,29.5,26.8,29.5,27.2z M29.5,23.5c0,0.4-1.3,0.7-2.8,0.7H15.8c-1.6,0-2.8-0.3-2.8-0.7c0-0.4,1.3-0.7,2.8-0.7h10.8
			C28.2,22.8,29.5,23.1,29.5,23.5z'
          />
        </g>
      </g>
    </g>
  )
}

export const IconGlasfaser = (props: any) => {
  return (
    <g>
      <rect x='4.4' y='15.7' {...props} width='22.9' height='11.3' />
      <g>
        <g>
          <path
            {...props}
            d='M28.9,25.9c0,0,0.3,0,0.7,0s0.7-1.3,0.7-2.8v-3.2c0-1.6-0.3-2.8-0.7-2.8s-0.7,0-0.7,0'
          />
        </g>
        <polyline {...props} points='29.4,17 27.7,17 27.7,25.9 29.4,25.9 		' />
      </g>
      <g>
        <path
          {...props}
          d='M35.7,18.6c0-0.3-0.3-0.6-0.6-0.6v0h-4.4v1.2h4.4v0C35.4,19.2,35.7,19,35.7,18.6z'
        />
        <path
          {...props}
          d='M38.1,20.6c0-0.3-0.3-0.6-0.6-0.6v0h-6.9v1.2h6.9v0C37.8,21.2,38.1,20.9,38.1,20.6z'
        />
        <path
          {...props}
          d='M36.9,22.5c0-0.3-0.3-0.6-0.6-0.6v0h-5.6v1.2h5.6v0C36.6,23.1,36.9,22.8,36.9,22.5z'
        />
        <path
          {...props}
          d='M35.3,24.4c0-0.3-0.3-0.6-0.6-0.6v0h-4V25h4v0C35,25,35.3,24.8,35.3,24.4z'
        />
      </g>
    </g>
  )
}

export const IconLadesaeule = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M27.5,8.4H28c0,0,0.9,0.1,0.9,0.8c0,0.8,0,12.1,0,12.1s0,0.8-1,0.8c-0.6,0-0.5,0-0.5,0V8.4z'
      />
      <path
        {...props}
        d='M33.6,32.8c-1.9,0-3.1-1.5-4-2.7c-0.7-0.9-1.4-1.7-2-1.5l-0.2-0.7c1.1-0.3,1.9,0.7,2.8,1.8c1.2,1.5,2.4,3.1,4.8,2.1
		c2.7-1.1,2.2-3.1,1.3-6.3c-0.8-2.7-1.7-5.8,0.4-8.4c1.5-1.8,2-3.6,1.6-5.1c-0.4-1.5-1.6-2.4-2.6-2.7c-2.9-1-5.5,0.5-5.5,0.5
		l-0.4-0.6c0.1-0.1,3-1.7,6.2-0.6c1.1,0.4,2.5,1.5,3,3.2c0.3,1.2,0.3,3.2-1.8,5.8c-1.9,2.3-1.1,5.1-0.3,7.8c0.8,2.8,1.7,5.7-1.7,7.1
		C34.6,32.7,34.1,32.8,33.6,32.8z'
      />
      <g>
        <path
          {...props}
          d='M26.4,7.8c0-3.5-2.7-3.7-2.7-3.7s-13.5,0-16.7,0c-3.1,0-3.6,2.6-3.6,2.6V39h23C26.5,39,26.4,11.3,26.4,7.8z M15.1,37.3
			c-1.5,0-3-0.4-4.2-1.1c-0.1,0-0.2-0.1-0.3-0.2c-2.6-1.5-4.3-4.3-4.3-7.5c0-0.5,0-1,0.1-1.4c0.1-0.3,0.1-0.6,0.2-0.9
			c0.3-1,0.7-1.9,1.3-2.7c1-1.4,2.4-2.5,4-3.1c1-0.4,2-0.6,3.1-0.6c4.8,0,8.8,3.9,8.8,8.8C23.8,33.4,19.9,37.3,15.1,37.3z M5.4,18.3
			V8.5c0,0,0.2-2,2.2-2c2,0,14.9,0,14.9,0s1.8,0.1,1.8,2.6c0,2.1,0,9.2,0,9.2H5.4z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M15.1,20.8c-1,0-1.9,0.2-2.8,0.5c-1.4,0.6-2.7,1.5-3.6,2.8c-0.5,0.7-0.9,1.5-1.1,2.4c-0.1,0.3-0.1,0.5-0.2,0.8
			c-0.1,0.4-0.1,0.8-0.1,1.3c0,2.8,1.5,5.3,3.8,6.7c0.1,0,0.2,0.1,0.2,0.1c1.1,0.6,2.4,1,3.7,1c4.3,0,7.8-3.5,7.8-7.8
			C22.8,24.3,19.4,20.8,15.1,20.8z M13.7,34.1l0.6-4.5h-2.9l2.4-5.7h3l-1.5,3.5l2.7,0L13.7,34.1z'
        />
      </g>
    </g>
  )
}

export const IconPV = (props: any) => {
  return (
    <g>
      <g>
        <path
          {...props}
          d='M24.6,24l-13-12.7l-7.7,8v9.6l12.4,4.3V23l5.5,1.3v10.9L24,36l14.7-9.2V16.4L24.6,24z M6.6,25.8v-5.1l6.4,1.6l0.1,5.4
		L6.6,25.8z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M26.1,4L11.2,8.4L0.6,19.8h0.9L11,9.9l0.2,0.2l-9.3,9.7h0.6h0.3h0.6v-0.7l8.2-8.5l13.1,12.8l14.6-7.8L26.1,4z M33.6,11.9
		L30,13.6l-4.1-4l3.6-1.3L33.6,11.9z M29.7,13.7l-4.1,1.9l-4.4-4.3l4.4-1.6L29.7,13.7z M26,5.2l3.3,2.9l-3.6,1.3l-3.2-3.1L26,5.2z
		 M22.1,6.4l3.2,3.1l-4.4,1.6l-3.4-3.3L22.1,6.4z M13,9.2l4.2-1.3l3.5,3.3l-4,1.5L13,9.2z M16.8,12.9l4-1.5l4.4,4.3l-3.7,1.7
		L16.8,12.9z M25.6,21.4l-3.8-3.7l3.7-1.7l3.7,3.6L25.6,21.4z M29.5,19.4l-3.7-3.6l4.1-1.9l3.5,3.4L29.5,19.4z M30.2,13.8l3.7-1.7
		l3.6,3.2l-3.7,2L30.2,13.8z'
        />
      </g>
      <polygon {...props} points='15.7,33.1 22.3,35.4 22.2,34.7 15.8,32.5 ' />
    </g>
  )
}

export const IconRasenmaeher = (props: any) => {
  return (
    <g>
      <g>
        <path
          {...props}
          d='M23.9,28.5c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2
			C27.1,30,25.7,28.5,23.9,28.5z M23.9,33.7c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9s1.9,0.9,1.9,1.9
			C25.8,32.8,25,33.7,23.9,33.7z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M5.8,30c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S7.1,30,5.8,30z M5.8,33.5
			c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C6.9,33,6.4,33.5,5.8,33.5z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M23.4,28l-0.2-0.7L22,24L3.4,28.8c0,0-1.3,0.3-1.5,1.5c-0.1,1.2-0.2,1.7-0.2,1.7S1.7,33,2.1,33
			c0.1,0,0.4,0,0.8,0.1c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3c0,0.5-0.1,1-0.4,1.4c0.2,0,0.4,0,0.4,0h10.7
			l1.4,0c-0.4-0.6-0.7-1.3-0.7-2.1C20.2,29.8,21.6,28.3,23.4,28z'
        />
      </g>
      <g>
        <path
          {...props}
          d='M37.3,21.3c-1.6-1.3-3.6-0.6-3.6-0.6l-10.8,3l1.3,4.2c1.9,0.2,3.3,1.8,3.3,3.7
			c0,0.8-0.3,1.6-0.7,2.2h7.9c0,0,0.9,0.1,2.4-0.9c1.5-1,2-3,2-3v-5.8C39.2,24.2,38.9,22.6,37.3,21.3z'
        />
      </g>
      <g>
        <rect
          x='5.6'
          y='24.5'
          transform='matrix(0.9661 -0.2581 0.2581 0.9661 -6.0714 4.1127)'
          {...props}
          width='14'
          height='1.3'
        />
        <g>
          <path
            {...props}
            d='M5.7,26.4c0,0,0-0.1-0.1-0.3c0-0.2,1.1-0.6,2.7-1l8.1-2.2c1.5-0.4,2.8-0.6,2.8-0.4
				c0,0.2,0.1,0.3,0.1,0.3'
          />
        </g>
      </g>
      <path
        {...props}
        d='M22,23.3L38.7,6c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.3-0.6-0.3-0.9,0l-18,18.7L22,23.3z'
      />
    </g>
  )
}

export const IconTreppenlift = (props: any) => {
  return (
    <g>
      <g>
        <path
          {...props}
          d='M15.9,22.8c0,0.5-0.4,0.9-1,0.9H4.5c-0.6,0-1-0.4-1-0.9s0.4-0.9,1-0.9h10.4
		C15.5,21.9,15.9,22.3,15.9,22.8z'
        />
      </g>
      <path
        {...props}
        d='M4.1,23.5c-0.2,0-0.4-0.1-0.5-0.4l-1.2-3.6c-0.1-0.3,0.1-0.6,0.3-0.7c0.3-0.1,0.6,0.1,0.7,0.3
	l1.2,3.6c0.1,0.3-0.1,0.6-0.3,0.7C4.2,23.4,4.2,23.5,4.1,23.5z'
      />
      <path
        {...props}
        d='M15.3,23.5c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.4-0.3-0.7l1.2-3.6c0.1-0.3,0.4-0.4,0.7-0.3
	c0.3,0.1,0.4,0.4,0.3,0.7l-1.2,3.6C15.7,23.3,15.5,23.5,15.3,23.5z'
      />
      <path
        {...props}
        d='M10.6,31.3c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.1-0.4,0-0.6l22.8-22c0.2-0.2,0.4-0.1,0.6,0
	c0.2,0.2,0.1,0.4,0,0.6l-22.8,22C10.8,31.3,10.7,31.3,10.6,31.3z'
      />
      <path
        {...props}
        d='M16.5,38.1H4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h12.1v-5.7c0-0.2,0.2-0.4,0.4-0.4h5.1V26
	c0-0.2,0.2-0.4,0.4-0.4h5.2v-5.4c0-0.2,0.2-0.4,0.4-0.4H33v-5.2c0-0.2,0.2-0.4,0.4-0.4H40c0.2,0,0.4,0.2,0.4,0.4
	c0,0.2-0.2,0.4-0.4,0.4h-6.2v5.2c0,0.2-0.2,0.4-0.4,0.4H28V26c0,0.2-0.2,0.4-0.4,0.4h-5.2v5.2c0,0.2-0.2,0.4-0.4,0.4h-5.1v5.7
	C16.9,38,16.7,38.1,16.5,38.1z'
      />
    </g>
  )
}

export const IconWaerme = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M38.5,16.5c-0.1,0-0.3,0-0.4-0.1L20.5,7L3.1,16.4c-0.4,0.2-1,0.1-1.2-0.4c-0.2-0.4-0.1-1,0.4-1.2l17.8-9.7
	C20.3,5,20.7,5,21,5.1l17.9,9.7c0.4,0.2,0.6,0.8,0.4,1.2C39.1,16.3,38.8,16.5,38.5,16.5z'
      />
      <g>
        <path
          {...props}
          d='M35.7,33.8V14.4L20.5,5.9L5.5,14.4v19.4c0,0.3-0.2,2.2,2,2.2h26.2C35.8,36,35.7,34.1,35.7,33.8z M16.7,21.2
		c-0.4,3.1-0.7,2.7-1.1,5.7c-0.3,2.1,0.3,3.5,0.3,3.7c0.3,0.6-0.9,1-1.3,0.1c-0.7-1.9-0.3-4.4-0.1-5c0.2-0.7,0.9-4.2,1-5.2
		c0.1-0.8,0.1-2-0.4-3.4c-0.2-0.6,0.9-0.6,1.2,0C16.6,17.6,17.1,18.8,16.7,21.2z M21.7,21.2c-0.4,3.1-0.7,2.7-1.1,5.7
		c-0.3,2.1,0.3,3.5,0.3,3.7c0.3,0.6-0.9,1-1.3,0.1c-0.7-1.9-0.3-4.4-0.1-5c0.2-0.7,0.9-4.2,1-5.2c0.1-0.8,0.1-2-0.4-3.4
		c-0.2-0.6,0.9-0.6,1.2,0C21.6,17.6,22.1,18.8,21.7,21.2z M26.7,21.2c-0.4,3.1-0.7,2.7-1.1,5.7c-0.3,2.1,0.3,3.5,0.3,3.7
		c0.3,0.6-0.9,1-1.3,0.1c-0.7-1.9-0.3-4.4-0.1-5c0.2-0.7,0.9-4.2,1-5.2c0.1-0.8,0.1-2-0.4-3.4c-0.2-0.6,0.9-0.6,1.2,0
		C26.6,17.6,27.1,18.8,26.7,21.2z'
        />
      </g>
    </g>
  )
}

export const IconWallbox = (props: any) => {
  return (
    <g>
      <g>
        <path
          {...props}
          d='M26,3c-3.2,0-6.4,0-6.9,0c0,0,0,0-0.1,0c-0.5,0-3.6,0-6.9,0C8.7,3,7.6,5.1,7.6,8.2v2.4v9.7v2.4
          c0,3.1,1,5.1,4.5,5.1c3.2,0,6.4,0,6.9,0c0,0,0,0,0.1,0c0.5,0,3.6,0,6.9,0c3.5,0,4.5-2.1,4.5-5.1v-2.4v-9.7V8.2
          C30.5,5.1,29.5,3,26,3z M12,13h-1.7v-0.6H12V13z M23.9,15h-9.6v-4.5h9.6V15z M27.9,13h-0.7v0.7h-0.6V13H26v-0.6h0.7v-0.7h0.6v0.7
          h0.7V13z'
        />
      </g>
      <path
        {...props}
        d='M31.1,18v5.2l1.5,2.1l1.3,4c0,0,0,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3l0.1-4.9L31.1,18z'
      />
      <path
        {...props}
        d='M24.1,40c-5.8,0-10.5-4.7-10.5-10.5v-3.4H14v3.4c0,5.6,4.5,10.1,10.1,10.1s10.1-4.5,10.1-10.1h0.4
        C34.5,35.3,29.8,40,24.1,40z'
      />
    </g>
  )
}

export const IconWanderwege = (props: any) => {
  return (
    <g>
      <g>
        <path
          {...props}
          d='M11.6,19.4l2.3,3.2L13.5,25l-4.2,5.9c0,0-1.1,1.4-2.3,0.3c-1.2-1.1-0.1-2.2-0.1-2.2l3.9-5.3
			L11.6,19.4z'
        />
        <path
          {...props}
          d='M16.7,8.3l-2-0.3c0,0-1.5-0.6-2,1.1l-1.2,7.4c0,0-0.4,1.5,0.7,2.9c1.3,1.7,8,11.2,8,11.2
			s0.8,1.5,2.1,0.8c1.3-0.7,0.8-1.9,0.7-2.1c-0.1-0.2-6.8-9.5-6.8-9.5l1.1-6.8l1.3,1.7l4.4,2.6c0,0,1.4,0.8,2-0.5s-0.3-2.1-0.3-2.1
			l-4.1-2.3l-2.6-3.8L16.7,8.3z'
        />
        <path
          {...props}
          d='M23.2,16.5l-5,14.1c-0.1,0.2,0,0.5,0.3,0.5c0.2,0.1,0.5,0,0.5-0.3l5-14.1L23.2,16.5z'
        />
        <circle {...props} cx='16' cy='4.6' r='2.6' />
        <path
          {...props}
          d='M12.3,8.9L11,16.7l-3.7-0.7c0,0-0.8-0.3-0.4-1.4s2-5.8,2-5.8S9.1,8.1,10,8.4L12.3,8.9z'
        />
      </g>
      <path
        {...props}
        d='M39.9,39H1.3c0,0-0.2-1.5,2-3.1s2.6-0.4,4.4,0.3c2.5,1,4.4-1.9,6.8-1.9s3,1.3,4.6,0.7
		c1.6-0.6,7.1-3.5,8.7-2.4c1.6,1.2,2.8,1,4.9-0.6c2.7-2.2,7.3-1.5,7.3-1.5V39z'
      />
    </g>
  )
}

export const IconThermography02 = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M36.4,24.3c-0.1,0-0.2,0-0.3-0.1L22,16.6L8,24.2c-0.4,0.2-0.8,0.1-1-0.3c-0.2-0.4-0.1-0.8,0.3-1
	l14.3-7.8c0.2-0.1,0.5-0.1,0.7,0l14.4,7.8c0.4,0.2,0.5,0.6,0.3,1C36.9,24.2,36.7,24.3,36.4,24.3z'
      />
      <g>
        <path
          {...props}
          d='M34.2,38.2V22.6L22,15.8L9.9,22.6v15.6c0,0.2-0.1,1.8,1.6,1.8h21.1C34.3,40,34.2,38.5,34.2,38.2z
		 M19,28.1c-0.3,2.5-0.6,2.1-0.9,4.6c-0.2,1.7,0.2,2.9,0.3,3c0.3,0.5-0.7,0.8-1,0.1c-0.6-1.5-0.2-3.5-0.1-4c0.1-0.6,0.7-3.4,0.8-4.2
		c0-0.6,0.1-1.6-0.4-2.7c-0.2-0.5,0.8-0.5,1,0C18.8,25.2,19.2,26.2,19,28.1z M23,28.1c-0.3,2.5-0.6,2.1-0.9,4.6
		c-0.2,1.7,0.2,2.9,0.3,3c0.3,0.5-0.7,0.8-1,0.1c-0.6-1.5-0.2-3.5-0.1-4c0.1-0.6,0.7-3.4,0.8-4.2c0-0.6,0.1-1.6-0.4-2.7
		c-0.2-0.5,0.8-0.5,1,0C22.8,25.2,23.2,26.2,23,28.1z M27,28.1c-0.3,2.5-0.6,2.1-0.9,4.6c-0.2,1.7,0.2,2.9,0.3,3
		c0.3,0.5-0.7,0.8-1,0.1c-0.6-1.5-0.2-3.5-0.1-4c0.1-0.6,0.7-3.4,0.8-4.2c0-0.6,0.1-1.6-0.4-2.7c-0.2-0.5,0.8-0.5,1,0
		C26.9,25.2,27.3,26.2,27,28.1z'
        />
      </g>
      <path
        {...props}
        d='M24,5.1l-1.8-3C22.1,2.1,22,2,21.9,2s-0.2,0.1-0.3,0.2l-1.8,3c-0.1,0.1-0.1,0.3,0,0.4
	C20,5.5,20,5.6,20.1,5.6c0,0,0.1,0,0.1,0l1.3-0.4v7.5c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3V5.2l1.4,0.4c0.1,0,0.3,0,0.3-0.1
	C24,5.4,24,5.2,24,5.1z'
      />
      <path
        {...props}
        d='M34.8,7.1c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3,0l-3,1.7c-0.1,0.1-0.2,0.2-0.2,0.3
	c0,0.1,0.1,0.2,0.2,0.3l1.4,0.3L29,15.9c-0.1,0.2,0,0.3,0.1,0.4c0,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.2l3.8-6.5l1,1
	c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3L34.8,7.1z'
      />
      <path
        {...props}
        d='M14.9,16.2l-3.8-6.5l1.4-0.3c0.1,0,0.2-0.1,0.2-0.3c0-0.1,0-0.3-0.2-0.3l-3-1.7
	C9.5,7,9.3,7.1,9.2,7.1S9.1,7.3,9.1,7.4L9,10.8c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1l1-1l3.8,6.5
	c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0C14.9,16.5,15,16.3,14.9,16.2z'
      />
    </g>
  )
}

export const IconThermography03 = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M18.2,21.8c0,3,0,13.7,0,15.3c0,2,0.4,2.9,3.6,2.9c3.2,0,3.6-0.9,3.6-2.9c0-1.7,0-12.3,0-15.3
      c-1,0.3-2.2,0.5-3.6,0.5C20.4,22.3,19.2,22.1,18.2,21.8z'
      />
      <path
        {...props}
        d='M26.6,2h-4.7h-4.7c0,0-3.6-0.4-3.6,3.2c0,3.5,0,9.3,0,10.1c0,1,0.4,4.6,4.8,5.9
        c1,0.3,2.2,0.5,3.6,0.5c1.4,0,2.6-0.2,3.6-0.5c4.6-1.3,4.8-5.1,4.8-6c0-1,0-6.5,0-10.1C30.2,1.6,26.6,2,26.6,2z M16.6,5.2h10.5v8.1
        H16.6V5.2z M23.5,17c0,0,0,0.8,0,1.1c0,0.3-0.1,0.3-0.3,0.3c-0.2,0-1.3,0-1.4,0c0,0,0,0,0,0c-0.1,0-1.2,0-1.4,0
        c-0.2,0-0.3,0-0.3-0.3c0-0.3,0-1.1,0-1.1s0-0.8,0-1.1c0-0.3,0.1-0.3,0.3-0.3c0.2,0,1.3,0,1.4,0c0,0,0,0,0,0c0.1,0,1.2,0,1.4,0
        c0.2,0,0.3,0,0.3,0.3C23.5,16.2,23.5,17,23.5,17z M18,16.8c0,0-0.3,0-0.6,0c-0.2,0-0.8,0-0.8-0.6c0,0,0,0,0,0v0c0,0,0,0,0,0
        c0-0.6,0.6-0.6,0.8-0.6c0.4,0,0.6,0,0.6,0c0,0,0,0,0,0c0,0,0.3,0,0.6,0c0.2,0,0.8,0,0.8,0.6c0,0,0,0,0,0v0c0,0,0,0,0,0
        c0,0.6-0.6,0.6-0.8,0.6C18.2,16.8,18,16.8,18,16.8C18,16.8,18,16.8,18,16.8z M25.7,16.8C25.7,16.8,25.7,16.8,25.7,16.8
        c0,0-0.3,0-0.6,0c-0.2,0-0.8,0-0.8-0.6c0,0,0,0,0,0v0c0,0,0,0,0,0c0-0.6,0.6-0.6,0.8-0.6c0.4,0,0.6,0,0.6,0c0,0,0,0,0,0
        c0,0,0.3,0,0.6,0c0.2,0,0.8,0,0.8,0.6c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0.6-0.6,0.6-0.8,0.6'
      />
    </g>
  )
}

export const Default1 = (props: any) => {
  return <FontAwesomeIcon icon={faInfoCircle} {...props} />
}

export const Default2 = (props: any) => {
  return <FontAwesomeIcon icon={faQuestionCircle} {...props} />
}

export const Default3 = (props: any) => {
  return <FontAwesomeIcon icon={faSpa} {...props} />
}

export const Default4 = (props: any) => {
  return <FontAwesomeIcon icon={faPlusCircle} {...props} />
}

export const Default5 = (props: any) => {
  return <FontAwesomeIcon icon={faEuroSign} {...props} />
}

export const Default6 = (props: any) => {
  return <FontAwesomeIcon icon={faCircle} {...props} />
}

export const IconDefault7 = (props: any) => {
  return (
    <g>
      <polygon {...props} points='7.2,17.1 4.5,17.9 1.8,17 4.5,21.6 ' />
      <path
        {...props}
        d='M37.9,16.4c-1.1-3.5-3.3-6.6-6.3-8.8c-3-2.3-6.7-3.5-10.5-3.5C17,4.1,13,5.5,9.8,8.2
        C7,10.6,5,13.8,4.1,17.4L2,16.6c-0.2-0.1-0.4,0-0.5,0.1c-0.1,0.1-0.1,0.3,0,0.5l2.6,4.5C4.2,21.9,4.3,22,4.5,22c0,0,0,0,0,0
        c0.1,0,0.3-0.1,0.4-0.2l2.8-4.5c0.1-0.2,0.1-0.3,0-0.5c-0.1-0.1-0.3-0.2-0.5-0.1L5,17.3c1.9-7.3,8.5-12.4,16.1-12.4
        c7.3,0,13.7,4.7,15.9,11.7c0.1,0.2,0.3,0.4,0.5,0.3C37.8,16.8,38,16.6,37.9,16.4z'
      />
      <path
        {...props}
        d='M40.9,24.6L38.3,20c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2l-2.8,4.5
        c-0.1,0.2-0.1,0.3,0,0.5c0.1,0.1,0.3,0.2,0.5,0.1l2-0.6c-2,7.2-8.5,12.3-16.1,12.3c-7.3,0-13.7-4.7-15.9-11.7
        c-0.1-0.2-0.3-0.4-0.5-0.3c-0.2,0.1-0.4,0.3-0.3,0.5c1.1,3.5,3.3,6.6,6.3,8.8c3,2.3,6.7,3.5,10.5,3.5c4.2,0,8.2-1.5,11.3-4.2
        c2.8-2.4,4.7-5.5,5.6-9l2.2,0.8c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.2C41,24.9,41,24.7,40.9,24.6z'
      />
    </g>
  )
}

export const IconDefault8 = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M25.8,23.4c-3.2,0-5.7-2.6-5.7-5.7c0-3.2,2.6-5.7,5.7-5.7c3.2,0,5.7,2.6,5.7,5.7
		C31.5,20.8,28.9,23.4,25.8,23.4 M34.8,18.1c0-0.2,0-0.3,0-0.5c0-0.7-0.1-1.4-0.2-2l1.4-1.1c-0.2-0.7-0.5-1.3-0.8-2l-1.8,0.2
		c-0.5-0.7-1-1.3-1.7-1.9l0.4-1.7C31.6,8.7,31,8.3,30.3,8L29,9.2c-0.8-0.3-1.6-0.5-2.5-0.6L25.9,7c0,0-0.1,0-0.1,0
		c-0.7,0-1.4,0.1-2,0.2l-0.3,1.7c-0.8,0.2-1.6,0.6-2.3,1l-1.5-1C19,9.4,18.5,9.9,18,10.4l0.7,1.6c-0.5,0.7-1,1.4-1.3,2.2l-1.8,0.1
		c-0.2,0.7-0.4,1.4-0.5,2.1l1.5,0.9c0,0.2,0,0.3,0,0.5c0,0.7,0.1,1.4,0.2,2l-1.4,1.1c0.2,0.7,0.5,1.3,0.8,2l1.8-0.2
		c0.5,0.7,1,1.3,1.7,1.9l-0.4,1.7c0.6,0.4,1.2,0.8,1.8,1.1l1.3-1.2c0.8,0.3,1.6,0.5,2.5,0.6l0.6,1.6c0,0,0.1,0,0.1,0
		c0.7,0,1.4-0.1,2-0.2l0.3-1.7c0.8-0.2,1.6-0.6,2.3-1l1.5,1c0.6-0.4,1.1-0.9,1.6-1.4l-0.7-1.6c0.5-0.7,1-1.4,1.3-2.2l1.8-0.1
		c0.2-0.7,0.4-1.4,0.5-2.1L34.8,18.1z'
      />
      <path
        {...props}
        d='M16.3,30.2c-0.6,2-2.8,3.1-4.7,2.4c-2-0.6-3.1-2.8-2.4-4.7c0.6-2,2.8-3.1,4.7-2.4
		C15.9,26.1,17,28.2,16.3,30.2 M18.4,30.9c0.1-0.4,0.2-0.9,0.3-1.3l1.1-0.4c0-0.5,0-0.9-0.1-1.4l-1.1-0.2c-0.1-0.5-0.4-1-0.7-1.5
		l0.6-1c-0.3-0.4-0.6-0.7-0.9-1.1l-1.1,0.5c-0.4-0.3-0.9-0.6-1.4-0.9L15,22.4c0,0-0.1,0-0.1,0c-0.4-0.1-0.9-0.2-1.3-0.3l-0.6,1
		c-0.6,0-1.1,0-1.6,0.1l-0.7-0.9c-0.5,0.1-0.9,0.3-1.3,0.6l0.1,1.2c-0.5,0.3-0.9,0.7-1.3,1.1l-1.1-0.3c-0.3,0.4-0.5,0.8-0.7,1.2
		l0.8,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.2,0.9-0.3,1.3L5.7,29c0,0.5,0,0.9,0.1,1.4L7,30.6c0.1,0.5,0.4,1,0.7,1.5l-0.6,1
		c0.3,0.4,0.6,0.7,0.9,1.1L9,33.7c0.4,0.3,0.9,0.6,1.4,0.9l0.1,1.2c0,0,0.1,0,0.1,0c0.4,0.1,0.9,0.2,1.3,0.3l0.6-1
		c0.6,0,1.1,0,1.6-0.1l0.7,0.9c0.4-0.1,0.9-0.3,1.3-0.6L16,34c0.5-0.3,0.9-0.7,1.3-1.1l1.1,0.3c0.3-0.4,0.5-0.8,0.7-1.2l-0.8-0.9
		C18.3,31.1,18.4,31,18.4,30.9'
      />
    </g>
  )
}

export const IconDefault9 = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M37.6,36.4L24.3,23.1c0,0,0,0,0,0.1c-0.3,0.5-0.7,0.9-1.2,1.4c-0.3,0.3-0.7,0.7-1.1,1l13.3,13.3
		c0.5,0.5,1.5,0.5,2.1-0.2C38.1,37.9,38.2,37,37.6,36.4z'
      />
      <path
        {...props}
        d='M23.3,22.2C23.4,22.2,23.4,22.2,23.3,22.2c3.3-4.5,2.9-10.8-1.2-14.8C17.7,2.9,10.5,2.9,6,7.4
		c-4.5,4.5-4.5,11.7,0,16.2c4.1,4.1,10.6,4.4,15,1c0,0,0,0,0,0c0.4-0.3,0.7-0.6,1.1-1C22.6,23.1,23,22.7,23.3,22.2z M19.1,22.6
		c-3.4,2.4-8.1,2.1-11.2-1c-3.4-3.4-3.4-9,0-12.4c3.4-3.4,9-3.4,12.4,0c3,3,3.4,7.6,1.1,11c-0.3,0.5-0.7,1-1.1,1.4
		C19.9,22,19.5,22.3,19.1,22.6z'
      />
    </g>
  )
}

export const IconDefault10 = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M11.6,4.9c0-0.5-0.3-0.9-0.7-0.9c-0.4,0-0.7,0.4-0.7,0.9c0,0,0,0,0,0v34v0h1.4V39L11.6,4.9
	C11.6,4.9,111.6,4.9z'
      />
      <path
        {...props}
        d='M12.5,20.9c0,0,1.6-1.2,5.4-1.2c4.5,0,9,2.5,14.3,0.1V5.6c-6.6,2-9.7-0.1-14.3-0.1l0,0
	c-3.8,0-5.4,1.2-5.4,1.2V20.9z'
      />
    </g>
  )
}

export const IconDefault11 = (props: any) => {
  return (
    <g>
      <path
        {...props}
        d='M32.3,30.8c0,0-21.2,0-23.7,0c-3.8,0-8.2-3.9-6-9.3c1.3-3.3,4.9-5.5,9.9-3.9c0,0-1.9-7.4,6.2-9c6.7-1.4,9,5.8,9,5.8
	s8.5-3.1,11.7,5.8C41.2,25.7,38.1,30.4,32.3,30.8z'
      />
    </g>
  )
}

export const IconDefault12 = (props: any) => {
  return (
    <g>
      <polygon
        {...props}
        points='20.9,3.1 26.8,17 40.1,17 29.6,25.6 32.8,39.5 20.9,32.2 9.1,39.5 12.3,25.6 1.8,17 15.1,17 '
      />
    </g>
  )
}
