import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { AppTheme } from '../../../theme'
import { Space, Typography, TypographyType } from '../../atoms'

interface AlertProps {
  onClose: () => void
  title: string
  description?: string
}
export const Alert: React.FC<AlertProps> = ({ onClose, title }) => {
  return (
    <ThemeProvider theme={AppTheme}>
      <AlertContainer onClick={onClose}>
        <Space px={AppTheme.space.M}>
          <Space
            my={AppTheme.space.M}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography type={TypographyType.TEXT} color={AppTheme.colors.black}>
              {title}
            </Typography>
          </Space>
        </Space>
      </AlertContainer>
    </ThemeProvider>
  )
}

const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10 10 10 10;
`
