import * as React from 'react'
import styled from 'styled-components'
import {
  position,
  color,
  space,
  SpaceProps,
  ColorProps,
  PositionProps,
  layout,
  LayoutProps,
  display,
  DisplayProps
} from 'styled-system'
import { Default1, Default2, Default3, Default4, Default5, Default6, IconType } from './iconLib'

interface DefaultIconProps extends IconContainerProps {
  iconName: IconType | string
}

const renderPath = (name: string, props: any) => {
  switch (name) {
    case IconType.Default1:
      return <Default1 {...props} />
    case IconType.Default2:
      return <Default2 {...props} />
    case IconType.Default3:
      return <Default3 {...props} />
    case IconType.Default4:
      return <Default4 {...props} />
    case IconType.Default5:
      return <Default5 {...props} />
    case IconType.Default6:
      return <Default6 {...props} />

    default:
      return null
  }
}

export const DefaultIcon: React.FunctionComponent<DefaultIconProps> = ({
  color,
  width,
  height,
  margin,
  padding,
  position,
  display,
  iconName
}) => {
  return (
    <IconContainer
      iconName={iconName}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      position={position}
      display={display}>
      {renderPath(iconName, { color: `${color}` })}
    </IconContainer>
  )
}

export default DefaultIcon

interface IconContainerProps {
  position?: 'relative' | 'absolute' | 'fixed'
  color?: string
  hover?: boolean
  onClick?(e: React.MouseEvent<HTMLSpanElement>): void
  rotate?: number
  width: number | string
  height: number | string
  margin?: string
  padding?: string
  display?: 'inline' | 'inline-block' | 'block'
}

const IconContainer = styled.span<
  SpaceProps &
    ColorProps &
    PositionProps &
    LayoutProps &
    DisplayProps &
    IconContainerProps &
    DefaultIconProps
>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${position}
  ${color}
  ${space}
  ${layout}
  ${display}
`
