const bffURL = (): string => {
  const isProd = process.env.NODE_ENV === 'production'
  const HOST: string = process.env.HOST_URL as string
  const PORT: number = parseInt(process.env.app_port as string, 10)
  const bffURL = isProd ? `${HOST}/` : `${HOST}:${PORT}/`

  console.log('isProd: ', isProd, '\nbffURL:', bffURL)

  return bffURL
}

export const getBffURL = bffURL()
