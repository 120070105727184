import { readableColor } from 'polished'
import React from 'react'
import styled from 'styled-components'

export const SubmitButton: React.FC<SubmitButtonStyleProps> = ({ isDisabled, children }) => {
  return (
    <OuterContainer isDisabled={isDisabled} className='eh_submit_btn_outer'>
      <InnerContainer isDisabled={isDisabled} className='eh_submit_btn_inner'>
        {children}
      </InnerContainer>
    </OuterContainer>
  )
}

interface SubmitButtonStyleProps {
  bgColor?: string
  textColor?: string
  isDisabled?: boolean
  height?: string
}

const OuterContainer = styled.button.attrs({
  type: 'submit'
})<SubmitButtonStyleProps>`
  display: block;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : undefined)};
`

const InnerContainer = styled.div<SubmitButtonStyleProps>`
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 16px 30px;
  background-color: ${(props) => props.theme.colors.secondary};
  box-shadow: ${(props) => (props.isDisabled ? 'none' : `2px 2px 4px rgba(0, 0, 0, 0.3)`)};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  color: ${(props) =>
    props.isDisabled
      ? `${props.theme.colors.gray}`
      : `${readableColor(props.theme.colors.secondary, '#000', '#fff')}`};
  transition: all 0.5s ease;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 16px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    outline: none;
    box-shadow: none;
  }
`
